import http from "@/_helper/api-services.js";
import "./Auditoria.scss";

export default {
    name: "Auditoria",
    data() {
        return {
            nomeLogin: '',
            search: {
                login: String(),
                //dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                nomeProduto: String(),
                idProduto: 0,
                pago: null,
                quantidade: 50,
                page: 1
            },
            totalRows: 1,
            currentPage: 1,
            fields: [
                // { key: "selecionado", label: "", sortable: false },
                { key: "data", label: "Data", sortable: true },
                { key: "entrada",
                  label: "Entrada", 
                  sortable: true, 
                  formatter: (value) => {
                    if (!value) return "0.00";
                    return `$ ${value.toFixed(2)}`;
                }},
                { key: "criptoin", 
                  label: "Cripto In", 
                  sortable: true,
                  formatter: (value) => {
                    if (!value) return "0.00";
                    return `$ ${value.toFixed(2)}`;
                }},
                { key: "saida", 
                 label: "Saída", 
                 sortable: true, 
                 sortDirection: "desc",
                 formatter: (value) => {
                    if (!value) return "0.00";
                    return `$ ${value.toFixed(2)}`;
                }},
                { key: "saldoVirtual",
                  label: "Saldo Virtual",
                  formatter: (value) => {
                    if (!value) return "0.00";
                    return `$ ${value.toFixed(2)}`;
                }},
                { key: "residual", 
                  label: "Residual",
                  formatter: (value) => {
                    if (!value) return "0.00";
                    return `$ ${value.toFixed(2)}`;
                }},
                { key: "diario", 
                  label: "Diario",
                  formatter: (value) => {
                    if (!value) return "0.00";
                    return `$ ${value.toFixed(2)}`;
                }}
            ],
            list: [],
            perPage: 50,
            pageOptions: [50, 100, 150, {
                value: 99999,
                text: "show all"
            }],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            action: "",
            filterOn: [],
            
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez",],
            },
        };
    },
    components: {
    },

    beforeMount() {
        this.getAudit();
    },
    mounted() {
        //this.obterNomeLogado();
    },
    watch: {
        // perPage() {
        //     this.search.page = 1;
        //     this.buscarCompras();
        // },
        // "search.page"() {
        //     this.buscarCompras();
        // },
    },
    methods: {
        getAudit(){
            this.$loading(true);
            http.get("/admin/getAudit").then((success) => {
                if(success.data){
                    success.data.forEach(d => {
                        this.list.push({
                            data: d.data,
                            entrada: d.entrada,
                            criptoin: d.criptoin,
                            saida: d.saida,
                            saldoVirtual: d.saldoVirtual,
                            residual: d.residual,
                            diario: d.diario
                        });
                    })
                }
                this.totalRows = this.list.length;
            },
            error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            if(logado){
                this.dadoLogado = JSON.parse(logado);
                this.nomeLogin = this.dadoLogado.username.split(' ')[0];
            }
        },
        formatDate(data) {
            let dateTime = new Date(data).toLocaleDateString();
            return dateTime;
        },
        verificarUsuario() {
            http.get("/admin/checkUserAdmin").then((response) => {
                this.openModalPagamentoManual();
            },
                (error) => {
                    this.$snotify.error(error.response.data);
                });
        },
    },
};