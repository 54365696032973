import "./logout.scss";

export default {
    name: "logout",
    beforeMount() {
        this.$emit("hideHeaderFooter");
        this.$emit("logoutUser");
        const { dispatch } = this.$store;
        dispatch("autenticacao/logout").finally(() => this.$router.push("/index"));
    }
}