import http from "@/_helper/api-services.js";
import util from "@/_helper/util.js";
import "./RendimentoDiario.scss";

export default {
    name: "RendimentoDiario",
    data() {
        return {
            nomeLogin: '',
            listStatus: [],
            fields: [
                { key: "dia", label: "Dia", sortable: false, sortDirection: "asc" },
                { key: "rendimentoDia", label: "Percenutal de ganho", sortable: false, tdClass: "d-flex justify-content-center" },
                {
                    key: "ultimaAtualizacao",
                    label: "Ultima atualização",
                    sortable: false
                },
            ],
            list: [],
            selected: [],
            totalPages: 0,
            perPage: 50,
            pageOptions: [50, 100, 150],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            action: "",
            filterOn: [],
            todosSelecionados: false,
            usuarioConfiguracao: {
                idUsuarioConfiguracao: 0,
                nomeUsuario: String(),
                idUsuario: String(),
                taxaSaque: Number(),
            },
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ],
            },
            mediaDia: 0,
            totalMensal: 0,
            somatorio: 0,
            editarDia: {
                rendimentoDia: 0,
                idDia: 0
            }
        };
    },
    components: {
    },
    beforeMount() {
        this.buscarDias();
    },
    mounted() {
        //this.obterNomeLogado();
        this.$loading(false);
    },

    watch: {
        perPage() {
            this.buscarDias();
        },
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        buscarDias() {
            this.$loading(true);
            http.get("/income/searchDailyIncome", this.search).then(
                (success) => {
                    this.list = success.data;
                    var count = 0;
                    var soma = 0;
                    var rendimento = 100;
                    this.list.forEach(item => {
                        count += 1;
                        soma += item.rendimentoDia;
                        rendimento += rendimento * (item.rendimentoDia / 100)
                    });

                    this.totalMensal = rendimento - 100;
                    this.mediaDia = soma / count;
                    this.somatorio = soma;
                    this.$loading(false);
                },
                (error) => {
                    this.$loading(false);
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    )
                        this.$snotify.error(error.response.data.message);
                }
            );
        },
        fecharModal() {
            this.$bvModal.hide("modalEditarDia");
        },
        openModal(dia) {
            this.editarDia.idDia = dia;
            this.$bvModal.show("modalEditarDia");
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
        },
        formatDate(data) {
            return util.formatDate(data);
        },
        atualizarDia() {
            this.$loading(true);
            this.editarDia.rendimentoDia = +this.editarDia.rendimentoDia;
            http.post("/income/updateDailyIncome", this.editarDia)
                .then(
                    (success) => {
                        if ((success.status = 200)) {
                            this.editarDia.idDia = 0;
                            this.editarDia.rendimentoDia = 0;
                            this.$snotify.success("Rendimento atualizado com sucesso!");
                            this.$bvModal.hide("modalEditarDia");
                            this.buscarDias();
                        } else {
                            this.$snotify.error("Erro ao atualizar rendimento");
                        }
                    },
                    (error) => {
                        this.$snotify.error(error.response.data);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
    },
};