import DetalhesContato from '@/components/Suporte/Contato/DetalhesContato.vue';
import SideBar from "@/components/SideBar/SideBar.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';

export default {
    name: "Contato",
    data() {
        return {
            acessoRemoto:false,
            nomeLogin: ''
        }
    },
    components: { 
        DetalhesContato,
        SideBar,
        PremiosDashboard
    },
    beforeMount(){
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
    },
    mounted() {
        //this.obterNomeLogado();
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        }
    },
}