import "./formLogin.scss";
import usuarioService from "@/_helper/usuario-service.js";
import http from "@/_helper/api-services.js";
import Axios from 'axios';

export default {
    name: "formLogin",
    data() {
        return {
            user: '',
            password: '',
            code: '',
            remember: false,
            otpAtivo: false,
            captchaImg: '',
            chiper: '',
            useCaptcha: false,
            captchaText: '',
            recaptchaToken: ''
        }
    },
    mounted() {
        if (localStorage.getItem("remember")) {
            let dados = JSON.parse(localStorage.getItem("remember"));
            this.user = dados.user;
        }
        setTimeout(() => {
            const recaptcha = this.$recaptchaInstance
            recaptcha.showBadge()
        }, 500)
    },
    methods: {
        async recaptcha() {
            this.$loading(true);
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()
            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login')
            // Do stuff with the received token.
            this.recaptchaToken = token;
            await this.login();
        },
        async getCaptcha() {
            this.$loading(true);
            this.useCaptcha = true;
            await http.get("/userLogin/GetCaptcha").then(response => {
                this.$loading(false);
                this.captchaImg = response.data.captchaEncoded;
                this.chiper = response.data.captchaChiper;
                this.$bvModal.show('modal-Captcha');
                this.$loading(false);
            }, error => {
                if (error.response.data) {
                    this.$snotify.error(error.response.data, { position: "rightTop" });
                } 
                this.$loading(false);
            });
        },
        async checkLogin() {
            if (this.user && this.password) {
                this.$loading(true);
                // Verifica se o usuário está com o 2FA ativado
                await http.get("/User/verifyOtpLogin/" + this.user).then(response => {
                    this.otpAtivo = response.data;


                    // Se sim exibe o modal de confirmação do código
                    if (this.otpAtivo) {
                        this.$loading(false);
                        this.$bvModal.show('modal-2FA');
                    } else {
                        // Se não, exibe o captcha;
                        //this.getCaptcha();
                        this.recaptcha();
                    }
                }, error => {
                    if (error.response.data) {
                        this.$snotify.error(error.response.data, { position: "rightTop" });
                    } 
                    this.$loading(false);
                    return;
                });


            } else {
                this.$snotify.error(this.$t('erros.preencha_os_campos'), { position: "rightTop" });
            }
        },
        async login() {
            this.$loading(true);
            const { dispatch } = this.$store;
            //let ip = await Axios.get("https://ipinfo.io/ip");
            //let ipResult = await Axios.get("https://api.ipify.org/?format=json");
            var ip = { data: '10.0.0.0' };
            var origem = window.location.origin;
            dispatch("autenticacao/login", {
                user: this.user,
                senha: this.password,
                otpCode: this.code,
                ipAcesso: ip.data,
                origem: origem,
                captchaText: null, //this.captchaText,
                captchaChiper: null, //this.chiper,
                gToken: this.recaptchaToken
            })
                .then(success => {
                    if (this.remember)
                        localStorage.setItem("remember", JSON.stringify({ user: this.user }));

                    localStorage.setItem("showComunicado", "true");
                    window.location.href = "/";
                },
                    error => {
                        this.$loading(false);
                        if (error.response && error.response.data) {
                            if (typeof error.response.data.emailConfirmado == "boolean" && !error.response.data.emailConfirmado) {
                                var resend = this.$t('login.reenviar_email')

                                this.$snotify.html(
                                    `<div class="snotify-icon snotify-icon--error"></div>
                                    <div class="snotifyToast__body">${error.response.data.message}</div>`,
                                    {
                                        buttons: [
                                            { text: resend, action: this.resendEmailConfirmation, bold: true },
                                            { text: "Ok", action: null, bold: true }
                                        ],
                                        timeout: 0,
                                        type: "error",
                                        position: "rightTop"
                                    });
                            } else {
                                this.$snotify.error(error.response.data, { position: "rightTop" });
                                if (this.useCaptcha) {
                                    this.getCaptcha();
                                }
                            }
                        } 
                    });
        },
        resendEmailConfirmation() {
            this.$snotify.clear();
            usuarioService.resendEmailConfirmation(this.user).then(success => {
                this.$snotify.success(success.data.message, { position: "rightTop", timeout: 8000 });
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message, { position: "rightTop" });
            });
        },
        switchTo(action) {
            this.$emit("switchPanel", action);
        },
        fecharModal() {
            this.otpCode = '';
            this.$bvModal.hide('modal-2FA');
        },
        closeCaptcha() {
            this.captchaText = '';
            this.useCaptcha = false;
            this.$bvModal.hide('modal-Captcha');
        },
    }
}