import apiService from "@/_helper/api-services.js";
import "./InfoUserBar.scss";

export default {
    name: "InfoUserBar",
    data() {
        return {
            dadoLogado: {},
            nomeLogin: ''

        };
    },
    components: {
    },
    beforeMount() {
        this.obterNomeLogado();
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            if(logado){
                this.dadoLogado = JSON.parse(logado);
                this.nomeLogin = this.dadoLogado.username.split(' ')[0];
            }
        },
    }
}