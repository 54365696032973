import "./faq.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "faq",
    data() {
        return {
            perguntas: [],
            perguntaSelecionada: String(),
            categorias: [],
            lblPesquisa: String()
        }
    },
    beforeMount() {
        this.getFaq(0);
        this.getCategoriaFaq("FAQ");
    },
    methods: {
        removeSpecialCharacters(text) {
            return text.replace(/ /g, '').replace(/[^\w\s]/gi, '');
        },

        getFaq(idCategoria) {
            this.$loading(true);
            http.get("/faq/getFaq/" + idCategoria).then(
                success => {
                    if (success.data) {
                        this.perguntas = success.data
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },

        getCategoriaFaq(chavePai) {
            this.$loading(true);
            http.get("/faq/getCategoryFaq/" + chavePai).then(
                success => {
                    if (success.data) {
                        this.categorias = success.data
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },

        pesquisarTodos() {
            this.$loading(true);
            var pesquisa = "0";

            if (this.lblPesquisa) {
                pesquisa = this.lblPesquisa
            }

            http.get("/faq/searchFaq/" + pesquisa).then(
                success => {
                    if (success.data) {
                        this.perguntas = success.data
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },
    }
}