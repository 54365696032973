import { render, staticRenderFns } from "./LinkPatrocinador.vue?vue&type=template&id=dbf099fe&"
import script from "./LinkPatrocinador.js?vue&type=script&lang=js&"
export * from "./LinkPatrocinador.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports