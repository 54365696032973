import "./boxProduto.scss";
import http from "@/_helper/api-services.js";
import { Money } from "v-money";
import { decimal } from "vuelidate/lib/validators";

export default {
  name: "boxProduto",
  components: { Money },
  data() {
    return {
      id: String(),
      paymentForms: [
        //{ text: this.$i18n.t("produtos.selecione_pagamento"), value: null }
      ],
      paymentForm: null,
      paymentName: String(),
      paymentIcon: String(),
      tipoSaldo: "rede",
      password: String(),
      email: "",
      passwordCW: "",
      confirmPassword: String(),
      passwordMatch: true,
      blockButton: false,
      usarSaldoCompraCurso: false,
      valorHaUtilizar: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
      renovar: Boolean(),
      resumoValores: {},
      isValidBalance: false
    };
  },
  watch: {
    confirmPassword() {
      if (this.$data.password === this.$data.confirmPassword)
        this.$data.passwordMatch = true;
      else this.$data.passwordMatch = false;
    },
  },
  props: {
    product: Object(),
    krptprice: decimal,
  },
  computed: {
    nameState() {
      return this.valorHaUtilizar.length > 2 ? true : false;
    },
  },
  beforeMount() {
    if (!this.$props.paymentFormsAlreadyLoaded) this.getPaymentsForms(true);
    this.validaData();
  },
  mounted() {
    this.id = this._uid.toString();
    var dadosUser = this.$store.getters["autenticacao/getLogin"].user;
    this.email = dadosUser.email;
  },
  methods: {
    validaData(){
      let currentdate = new Date().getDate(); 
      if(currentdate == 5 || currentdate == 20)
        this.isValidBalance = true;
    },
    obterResumoValores() {
      this.$loading(true);
      http.get('/Dashboard/summariesValues/').then((responde) => {
          this.resumoValores = responde.data;
          this.$loading(false);
      });
   },
    formatPrice(valor) {
      if(!valor)
        return '$0,00';
      
      return valor.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    formatDecimal(value, places) {
      if (value) return value.toFixed(places);

      return "0.00";
    },
    openBuyConfirmation(renovar) {
      if (this.blockButton) {
        this.$snotify.error("Limite máximo de investimentos atingido");
      } 
      // else if(this.valorHaUtilizar < 20){
      //   this.$snotify.error(this.$i18n.t("produtos.valor_minimo"));
      // }
      else {
        this.renovar = renovar;
        // this.paymentForms = [
        //   { text: this.$i18n.t("produtos.selecione_pagamento"), value: null }
        // ];
        this.paymentForms = [];
        this.getPaymentsForms(renovar);
        this.$bvModal.show(this.id);
        this.obterResumoValores();
      }
    },
    getPaymentsForms(permitirSaldo) {
      http
        .get("/purchase/getPurchaseMethods?allowBalance=" + permitirSaldo)
        .then(
          (success) => {
            let data = success.data;
            // Object.keys(data).forEach((nameIndex) => {
            //   this.paymentForms.push({
            //     text: this.$i18n.t("meios_pagamento." + nameIndex),
            //     value: data[nameIndex],
            //   });
            // });
            Object.keys(data).forEach((nameIndex) => {
              this.paymentForms.push({
                text: nameIndex,
                value: data[nameIndex],
              });
            });
          },
          (error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            )
              this.$snotify.error(error.response.data.message);
           
          }
        );
    },
    paymentChoise(obj){
      this.paymentForm = obj.value;
      this.paymentName = this.$i18n.t("meios_pagamento." + obj.text);
      this.paymentIcon = `${"https://blobinsight.blob.core.windows.net/gobets/images/"+ obj.text +".svg"}`;
    },
    handleReset() {
      this.paymentForm = null;
      this.tipoSaldo = null;
      this.password = String();
      this.passwordCW = String();
      this.email = "";
      this.confirmPassword = String();
    },
    async handleSubmit() {
      if (this.paymentForm == 10) {
        this.blockButton = false;
        this.showModalCW();
      } else {
        await this.comprar();
      }
    },
    async handleSubmitCW() {
      if (this.email != "" && this.passwordCW != "") {
        await this.comprar();
      } else {
        //this.$snotify.error(this.$i18n.t("erros.erro_desconhecido"));
      }
    },
    showModalCW() {
      this.$bvModal.hide(this.id);
      this.$bvModal.show("CW_" + this.id);
    },
    async comprar() {
      if (this.blockButton == true) {
        return false;
      }

      this.blockButton = true;
      if (!this.passwordMatch) {
        this.blockButton = false;
        return false;
      }
      this.$loading(true);
      let filtro = {
        idProduto: this.$props.product.idProduto,
        senhaEletronica: this.password,
        metodoDePagamento: this.paymentForm,
        usarSaldoCompraCurso: this.chkUsarSaldo,
        valorHaUtilizar: this.$props.product.valor,
        renovacao: this.renovar,
        emailCW: this.email,
        senhaCW: this.passwordCW,
        tipoSaldo: this.tipoSaldo
      };

      await http.post("/purchase/purchase", filtro).then(
        (success) => {
          this.$snotify.success(this.$i18n.t("financeiro.compra_sucesso"));
          this.$router.push(`/my-purchases/${success.data.codigo}`);
          this.blockButton = false;
          this.$loading(false);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          )
            this.$snotify.error(error.response.data.message, {
              bodyMaxLength: 300,
            });
          
          this.blockButton = false;
          this.$loading(false);
        }
      );
    },
    closeModal(id) {
      this.$bvModal.hide(id);
      this.paymentForm = null;
    },
    closeModalCW(id) {
      this.$bvModal.hide("CW_" + id);
      this.$bvModal.show(id);
    },
  },
};
