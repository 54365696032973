import './marqueePrices.scss'

const SCRIPT_ID = 'tradingview-widget-script'
const CONTAINER_ID = 'tradingview-widget-container'
export default {
  name: 'marqueePrices',
  data() {
    return {
      container: CONTAINER_ID,
    }
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        symbols: [
          {
            proName: 'BITSTAMP:BTCUSD',
            title: 'BTC/USD',
          },
          {
            proName: 'BITSTAMP:ETHUSD',
            title: 'ETH/USD',
          },
          {
            description: 'LTC/USD',
            proName: 'BITSTAMP:LTCUSD',
          },
          {
            description: 'DOGE/USD',
            proName: 'BITTREX:DOGEUSD',
          },
          {
            description: 'BNB/USD',
            proName: 'BINANCE:BNBUSD',
          },
        ],
        showSymbolLogo: true,
        colorTheme: 'dark',
        isTransparent: true,
        displayMode: 'adaptive',
        locale: 'en',
      }),
    },
  },
  methods: {
    canUseDOM() {
      return (
        typeof window !== 'undefined' &&
        window.document &&
        window.document.createElement
      )
    },
    getScriptElement() {
      return document.getElementById(SCRIPT_ID)
    },
    updateOnloadListener(onload) {
      const script = this.getScriptElement()
      const oldOnload = script.onload
      return (script.onload = () => {
        oldOnload()
        onload()
      })
    },
    scriptExists() {
      return this.getScriptElement() !== null
    },
    appendScript(onload) {
      if (!this.canUseDOM()) {
        onload()
        return
      }
      if (this.scriptExists()) {
        if (typeof TradingView === 'undefined') {
          this.updateOnloadListener(onload)
          return
        }
        onload()
        return
      }
      const script = document.createElement('script')
      script.id = SCRIPT_ID
      script.type = 'text/javascript'
      script.async = true
      script.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
      script.onload = onload
        script.textContent = JSON.stringify(this.options)
        if (this.$refs.tradingview) {
            this.$refs.tradingview.appendChild(script)
        }
    },
    initWidget() {},
  },
  mounted() {
    setTimeout(() => {
      this.appendScript(this.initWidget)
    }, 10)
  },
}
