import http from "@/_helper/api-services.js";
import DatePicker from 'vue2-datepicker';
import util from "@/_helper/util.js";
import { Money } from "v-money";
import "./RelatorioUsuarioPedidos.scss";

export default {
    name: "RelatorioUsuarioPedidos",
    data(){
        return {
            filtro:{
                login: String(),
                dataInicio: null,
                dataFim: null
            },
            fields: [
                { key: "meioPagamento", label: "Forma de pagamento", headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Campeonato', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' },
                 formatter: val => {if (!val) return ""; return  this.$i18n.t("meios_pagamento_id." + val)} ,sortable: true },
                { key: "valorTotal", label: "Valor total", headerClass: 'text-center', class: 'text-center', width: '10%', thClass: 'position-relative', tdClass: 'align-middle', headerTitle: 'Home Team', headerTitleClass: 'text-primary', headerTitleStyle: { cursor: 'pointer' }, 
                formatter: val => {return val.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                })}, sortable: true },
            ],
            currentPage: 1,
            totalRows: 1,
            list:[],
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez",],
            }
        }
    },
    components: {
        Money,
        DatePicker
    },
    methods: {
        buscarUsuario(){
            this.$loading(true);
            http.post("/admin/relUsuarioPedido", this.filtro).then((response) => {
                this.list = [];
                response.data.forEach(item => {
                    this.list.push({
                        meioPagamento: item.meioPagamento,
                        valorTotal: item.valorTotal
                    })
                });
            },
            error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                    this.$loading(false);
                }
            )
        }
    }
}