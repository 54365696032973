import DatePicker from 'vue2-datepicker';
import api from "@/_helper/api-services.js";

export default {
    name: "gerenciarIngressos",
    data() {
        return {
            nomeLogin: '',
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            formSearch: {
                dataInicial: this.dataMenos30(),
                dataFinal: new Date(),
                quantidadePorPagina: 25,
                pagina: 1,
                pago: true,
                idEvento: 0
            },
            totalPaginas: 0,
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            fields: [
                { key: "codigo", label: "Código" },
                { key: "login", label: "Login", class: "text-center" },
                { key: "pago", label: "Pago?", class: "text-center" },
                { key: "moeda", label: "Criptomoeda", class: "text-center", formatter: val => { if (!val) return ""; return val.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) } },
                { key: "valorPedido", label: "Valor USD", class: "text-center", formatter: val => { if (!val) return ""; return val.toLocaleString('en-us', { style: 'currency', currency: 'USD' }) } },
                { key: "dataPedido", label: "Data", class: "text-center", formatter: val => { if (!val) return ""; return `${new Date(val).toLocaleDateString()} ${new Date(val).toLocaleTimeString()}` } },
                { key: "acoes", label: "" }
            ],
            items: [],
            showIconsToChangePage: false,
            idPedido: null,
            modalConfirm: false,
            modalConfirmDelete: false,
            eventos: [],
            ingressosCard: [],
        };
    },
    components: {
        DatePicker
    },
    watch: {
        async 'formSearch.pagina'() {
            await this.getPedidos();
        },
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPaginas <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPaginas;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPaginas - this.formSearch.pagina;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPaginas - 2; i <= this.totalPaginas; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPaginas - 1) return [1, 2, 3];
                else return [this.formSearch.pagina - 1, this.formSearch.pagina, this.formSearch.pagina + 1];
            }
        }
    },
    mounted() {
        //this.obterNomeLogado();
    },
    async mounted() {
        await this.getEventos();
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        async getEventos() {
            this.$loading(true);
            await api.post("/Admin/event-list", this.formSearch).then(
                success => {
                    success.data.eventosAtivos.forEach(c => {
                        this.eventos.push({
                            value: c.idEvento,
                            text: c.nome
                        });
                        if (this.eventos.length > 0) {
                            this.formSearch.idEvento = this.eventos[0].value;
                        }
                        this.getIngressosCard();
                        this.getPedidos();
                    })
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                }
            )
        },
        async getPedidos() {
            this.$loading(true);
            await api.post("/Admin/tickets-list", this.formSearch).then(
                success => {
                    this.totalPaginas = success.data.totalPages;
                    this.items = success.data.pedidosFiltrados;
                    this.$loading(false);
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");

                    this.$loading(false);
                }
            )
        },
        exportarExcel() {
            this.$loading(true);
            api.blob('/Admin/export-ticket-list?idEvento=' + this.formSearch.idEvento).then((response) => {
                var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var url = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.href = url;
                var fileName = 'event-report.xls';
                link.download = fileName;
                link.click();
                this.$loading(false);
            },
                error => {
                    // this.$snotify.error("An unexpected error has occurred");
                    this.$loading(false);
                });
        },
        async getIngressosCard() {
            await api.get("/Admin/tickets-sum/" + this.formSearch.idEvento, this.formSearch).then(
                success => {
                    this.ingressosCard = success.data.ingressos;
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                }
            )
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        formatBTC(val) {
            if (!val) return "0.00000000";
            return val.toFixed(8);
        },
        alterarPagina(pagina) {
            this.formSearch.pagina = pagina;
        },
        abriModalAprovarPagamento(idPedido) {
            this.idPedido = idPedido;
            this.modalConfirm = true;
        },
        async confirmAprovacao() {
            this.$loading(true);
            await api.get(`/Admin/approveOrder/${this.idPedido}`).then(
                success => {
                    this.$snotify.success("Pagamento aprovado com sucesso");
                    this.modalConfirm = false;
                    this.getPedidos();
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                    // else this.$snotify.error("An unexpected error has occurred");
                }
            );
            this.$loading(false);
        },
        async confirmReprovacao() {
            this.$loading(true);
            await api.delete(`/Admin/disapproveOrder/${this.idPedido}`).then(
                success => {
                    this.$snotify.success("Pagamento reprovado com sucesso");
                    this.modalConfirmDelete = false;
                    this.getPedidos();
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                    // else this.$snotify.error("An unexpected error has occurred");
                }
            );
            this.$loading(false);
        }
    }
}