import "./BarraPacotes.scss";

export default {
    name: 'BarraPacotes',
    props: ['dadosBarraPacotes', 'total','valorTotalRecebido'],
    data() {
        return {
            value: 45,
            dadosUser: {},
        }
    },
    beforeMount() {
        this.dadosUser = this.$store.getters['autenticacao/getLogin'].user;
    },
    methods: {
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        formatDecimal(value) {
            if (value)
                return value.toFixed(2);

            return "0.00";
        },
    }
}