var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('b-form',{},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('meus_dados.senha_atual')))]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['####']),expression:"['####']"}],staticClass:"input-padrao",class:{
                  'is-invalid': !_vm.assinaturaEletronica.assinaturaAtual,
               },attrs:{"required":"","placeholder":_vm.$t('meus_dados.senha_atual'),"type":"password"},model:{value:(_vm.assinaturaEletronica.assinaturaAtual),callback:function ($$v) {_vm.$set(_vm.assinaturaEletronica, "assinaturaAtual", $$v)},expression:"assinaturaEletronica.assinaturaAtual"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('meus_dados.nova_financeira')))]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['####']),expression:"['####']"}],staticClass:"input-padrao",class:{
                  'is-invalid': !_vm.assinaturaEletronica.assinaturaEletronica,
               },attrs:{"required":"","placeholder":_vm.$t('meus_dados.nova_financeira'),"type":"password"},model:{value:(_vm.assinaturaEletronica.assinaturaEletronica),callback:function ($$v) {_vm.$set(_vm.assinaturaEletronica, "assinaturaEletronica", $$v)},expression:"assinaturaEletronica.assinaturaEletronica"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('meus_dados.repetir_financeira')))]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['####']),expression:"['####']"}],staticClass:"input-padrao",class:{
                  'is-invalid':
                  _vm.assinaturaEletronica.assinaturaEletronica !=
                  _vm.assinaturaEletronica.repitaAssinatura,
               },attrs:{"required":"","placeholder":_vm.$t('meus_dados.repetir_financeira'),"type":"password"},model:{value:(_vm.assinaturaEletronica.repitaAssinatura),callback:function ($$v) {_vm.$set(_vm.assinaturaEletronica, "repitaAssinatura", $$v)},expression:"assinaturaEletronica.repitaAssinatura"}})],1)],1)],1)],1),_c('div',{},[_c('div',{staticClass:"modal-senha"},[_c('h6',{staticClass:"title-color mb-0"},[_c('b',[_vm._v(_vm._s(_vm.$t("meus_dados.deseja_gerar")))])]),_c('small',[_vm._v(_vm._s(_vm.$t("meus_dados.clique_aqui_senha")))]),(_vm.acessoRemoto == false)?_c('div',{staticClass:"bt-default mx-auto mt-3"},[_c('button',{on:{"click":function($event){return _vm.gerarNovaSenha()}}},[_vm._v(" "+_vm._s(_vm.$t("meus_dados.gerar_senha"))+" ")])]):_vm._e(),(_vm.acessoRemoto == true)?_c('div',{staticClass:"align-items-center"},[_c('b-col',{staticClass:"bg-warning py-2 mx-auto mt-2"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/icons/ico-info.svg")}}),_c('small',[_vm._v(" "+_vm._s(_vm.$t("generico.aviso_acesso_remoto"))+" ")])])],1):_vm._e()])]),_c('div',{staticClass:"bt-default bt-dados"},[_c('button',{staticClass:"w-100",on:{"click":function($event){return _vm.editarSenhaFinanceira()}}},[_vm._v(_vm._s(_vm.$t("generico.salvar")))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }