import http from "@/_helper/api-services.js";

export default {
    name: "TreeviewItem",
    data() {
        return {
            aberto: false,
            classe: 'plus',
            temFilhos: true,
        };
    },
    components: {
        treeviewItem: () => import('@/components/TreeviewItem/TreeviewItem.vue')
    },
    props: ['item'],
    beforeMount() {
    },
    methods: {
        formatPrice(valor) {
            if (!valor)
                return '$0,00';

            return this.casasDecimais(valor,2).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
        casasDecimais(num, precisao) {
            var casas = Math.pow(10, precisao);
            return Math.floor(num * casas) / casas;
        },
        expand() {
            this.$loading(true);
            if (!this.item.filhos || this.item.filhos.length == 0) {
                http.get("/network/expandListDirects/" + this.item.idUsuario).then(response => {
                    this.item.filhos = response.data;
                    //this.item.filhos.nivel = this.item.
                    this.$loading(false);
                    this.aberto = true;
                    this.classe = "minus";
                }, error => {
                    this.$loading(false);
                });
            } else {
                this.aberto = !this.aberto;
                this.classe = this.aberto ?  "minus" : "plus";
                this.$loading(false);
            }
            
        }
    }
}