import http from '@/_helper/api-services'

export default {
    name: 'LancamentoManual',
    data() {
        return {
            nomeLogin: '',
            lancamento: {
                valor: Number(),
                login: String(),
                descricao: String()
            },
            tipos: [],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '$ ',
                precision: 2,
                masked: false
            },
        }
    },
    components: {
    },
    mounted() {
        //this.obterNomeLogado();
        this.$loading(false);
    },
    async beforeMount() {
        // await this.buscarTipos();
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        gerarLancamento() {
            this.$loading(true);
            this.lancamento.valor = parseFloat(this.lancamento.valor);

            http.post('/launch/generateManualLaunch', this.lancamento).then((response) => {
                this.$snotify.success(response.data);
                this.limparCampos();
            }, error => {
                if (error.response.data)
                    this.$snotify.error(error.response.data);
            }).finally(() => { this.$loading(false); });
        },
        limparCampos() {
            this.lancamento.valor = "";
            this.lancamento.login = "";
            this.lancamento.descricao = "";
        },
        // async buscarTipos() {
        //     this.$loading(true);
        //     http.get('/Lancamento/buscarTipos').then((response) => {
        //         this.tipos = response.data;
        //     }, error => {
        //         if (error.response.data)
        //             this.$snotify.error(error.response.data);
        //         else
        //             this.$snotify.error("Erro ao efetuar solicitação");
        //     }).finally(() => { this.$loading(false); });
        // }
    }
}