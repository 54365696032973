import http from "@/_helper/api-services";
import "./Usuarios.scss";
import SideBar from "@/components/SideBar/SideBar.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { VueTelInput } from "vue-tel-input";

export default {
    name: "Usuarios",
    data() {
        return {
            locale: "pt",
            search: "",
            fields: [
                { key: "nome", label: this.$i18n.t('contato.usuario') },
                { key: "qualificadoBinario", label: this.$i18n.t('relatorio.binario_rede') },
                { key: "valorPago", label: this.$i18n.t('relatorio.binario_rede') },
            ],
            listDados: [],
            dadosUsuario: {},
            nomeLogin: '',
            listTipoFiltro: [],
            totalRows: 1,
            totalPages: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            loginFilter: "",
            dataInicialFilter: this.dataMenos30(),
            dataFinalFilter: new Date(),
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ],
            },
        };
    },
    components: {
        SideBar,
        PremiosDashboard,
        DatePicker,
        VueTelInput,
    },
    beforeMount() {
        this.obterDadosUsuario();
    },
    mounted() {
        //this.obterNomeLogado();
        this.$loading(true);
        this.buscarRelatorio();
    },
    filters: {
        formatMoeda: function(valor) {
            return (
                "$ " +
                parseFloat(valor)
                .toFixed(2)
                .replace(".", ",")
            );
        },
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return { text: f.label, value: f.key };
                });
        },
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        obterDadosUsuario() {
            this.$loading(true);
            http.get('/Dashboard/getBarStatus/').then((responde) => {
                this.dadosUsuario = responde.data;
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        exportarExcel() {
            this.$loading(true);

            var filter = {
                dataInicio: this.dataInicialFilter,
                dataFim: this.dataFinalFilter,
                login: this.loginFilter,
                pagina: this.currentPage,
                itensPorPagina: this.perPage,
            };
            http
                .blob(
                    "/report/userReportDownload?login=" +
                    this.loginFilter +
                    "&datatinicio=" +
                    moment(this.dataInicialFilter).format("YYYY-MM-DD") +
                    "&datafim=" +
                    moment(this.dataFinalFilter).format("YYYY-MM-DD"),
                    filter
                )
                .then((response) => {
                    var blob = new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    var url = window.URL.createObjectURL(blob);
                    var link = document.createElement("a");
                    link.href = url;
                    var fileName = "relatorio-usuario.xls";
                    link.download = fileName;
                    link.click();
                    this.$loading(false);
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
        },
        handlePageChange(value) {
            this.currentPage = value;
            this.buscarRelatorio();
        },
        buscarRelatorio() {
            this.$loading(true);
            var filter = {
                dataInicio: this.dataInicialFilter,
                dataFim: this.dataFinalFilter,
                login: this.loginFilter,
                pagina: this.currentPage,
                itensPorPagina: this.perPage,
            };
            this.listDados = [];
            http.post("/report/reportUsers", filter).then((response) => {
                //this.totalRows = response.data.totalRows;
                //this.totalPages = Math.ceil(this.totalRows / this.perPage);
                response.data.lista.forEach((item) => {
                    this.listDados.push(item);
                });
                this.totalRows=this.listDados.length;
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        formatDate(data) {
            let dateTime = new Date(data);
            return (
                dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString()
            );
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
    },
};