import http from '@/_helper/api-services';
import './SenhaFinanceira.scss'

export default {
    name: 'SenhaFinanceira',
    props: ['assinaturaEletronica'],
    data() {
        return {
            dadosUsuario: {},
            novaSenhaGerada: String(),
            dadosCompletos: false,
            acessoRemoto: false
        }
    },
    components: {
    },
    mounted() {
        this.$loading(false);
        this.verificaDadosCompletos();
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
    },
    methods: {
        salvarChaveStGuard() {
            this.$loading(true);

            http
                .post("/user/saveKeyStGuard", this.codigo)
                .then(
                    (response) => {
                        this.$snotify.success(response.data.message);
                    },
                    (error) => {
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.errors
                        )
                            error.response.data.errors.forEach((m) => this.$snotify.error(m));
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        gerarNovaSenha() {
            this.$loading(true);
            http.get('/user/signatureElectronicAleatory').then((response) => {
                this.novaSenhaGerada = response.data;
                this.$snotify.success(this.$i18n.t("cadastro.senha_aleatoria_gerada"));
            }, error => {
                if (error.response && typeof error.response.data == "object")
                    this.$snotify.error(error.response.data.message);
            }).finally(() => { this.$loading(false); });
        },
        editarSenhaFinanceira() {
            if(this.acessoRemoto){
                this.$snotify.error(this.$i18n.t("generico.aviso_acesso_remoto"));
                return;
            }
            if (this.checkFormSenhaFinanceira()) {
                this.$loading(true);
                http
                    .update("/user/updateElectronicSignature", this.assinaturaEletronica)
                    .then(
                        (response) => {
                            this.assinaturaEletronica.assinaturaAtual = '';
                            this.assinaturaEletronica.assinaturaEletronica = '';
                            this.assinaturaEletronica.repitaAssinatura = '';
                            this.$snotify.success(response.data.message);
                        },
                        (error) => {
                            if (error.response && typeof error.response.data == "object")
                                this.$snotify.error(error.response.data.message);
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        checkFormSenhaFinanceira() {
            if (!this.assinaturaEletronica.assinaturaAtual ||
                !this.assinaturaEletronica.assinaturaEletronica ||
                !this.assinaturaEletronica.repitaAssinatura ||
                this.assinaturaEletronica.assinaturaEletronica.length < 4 ||
                this.assinaturaEletronica.assinaturaEletronica !=
                this.assinaturaEletronica.repitaAssinatura
            ) {
                this.erroForm = true;
                this.$snotify.error(this.$t("erros.preencha_os_campos_senha"));
                return false;
            }

            return true;
        },
        verificaDadosCompletos() {
            const loggedIn = localStorage.getItem('user');

            if (loggedIn) {
                let json = JSON.parse(loggedIn);
                this.dadosCompletos = json.dadosCompletos;
            }
        }
    }
}