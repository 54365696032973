import http from '@/_helper/api-services'
import SideBar from "@/components/SideBar/SideBar.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';

export default {
    name: 'Arquivos',
    data() {
        return {
            fields: [
                { key: 'titulo', label: this.$i18n.t('suporte.titulo'), sortable: true, sortDirection: 'desc' },
                // { key: 'ativo', label: 'Status', sortable: true, class: 'text-center' },
                { key: 'download', label: this.$i18n.t('suporte.download'), sortable: false, class: 'text-center' }
            ],
            listArquivos: [],
            nomeLogin: '',
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            dadosUsuario: {},
        }
    },
    components: {
        SideBar,
        PremiosDashboard
    },
    beforeMount() {
        this.obterDadosUsuario();
    },
    mounted() {
        //this.obterNomeLogado();
        this.buscarArquivos();
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        obterDadosUsuario() {
            this.$loading(true);
            http.get('/Dashboard/getBarStatus/').then((responde) => {
                this.dadosUsuario = responde.data;
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        buscarArquivos() {
            this.$loading(true);

            this.listArquivos = [];
            http.get('/files/getFiles').then((response) => {
                response.data.forEach(item => {
                    this.listArquivos.push({
                        titulo: item.titulo,
                        ativo: item.ativo,
                        download: item.url
                    })
                });
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
    }
}