import http from '@/_helper/api-services';

export const usuarioService = {
    login(login, senha, otpCode, ipAcesso, origem, captchaText,
        captchaChiper, gToken) {
        const dados = {
            login,
            senha,
            otpCode,
            ipAcesso,
            origem,
            captchaText,
            captchaChiper,
            gToken
        };

        return http.login('/userLogin/authentication-v2', dados)
            .then(user => {
                if (user.data.token) {
                    localStorage.setItem('user', JSON.stringify(user.data));

                    if (!user.data.idPais) {
                        localStorage.setItem('force-country', true);
                    }
                }
                return user;
            }).catch(error => {
                throw error;
            });
    },
    register(user) {
        return http.post("/user/register", user)
            .then(success => {
                    return success;
                },
                error => {
                    throw error;
                });
    },
    resetPassword(email) {
        return http.get(`/userLogin/forgotPassword/${email}`).then(success => success, error => { throw error; });
    },
    validateToken(token) {
        return http.post(`/userLogin/openToken/${token}`).then(success => success, error => { throw error; });
    },
    updatePassword(data) {
        return http.post("/userLogin/changePassword", data).then(success => success, error => { throw error; });
    },
    checkSponsor(sponsor) {
        return http.get(`/user/verifySponsor/${sponsor}`).then(success => success, error => { throw error; });
    },
    confirmEmail(token) {
        return http.post(`/userLogin/confirmAccount/${token}`).then(success => success, error => { throw error });
    },
    confirmSaque(token) {
        return http.post("/withdraw/confirmWithdrawal/", { token }).then(success => success, error => { throw error });
    },
    resendEmailConfirmation(login) {
        return http.post("/userLogin/resendEmailConfirmation", { login }).then(success => success, error => { throw error; });
    },
    logout() {
        localStorage.removeItem("user");
        localStorage.removeItem("menu");
    }
};

export default usuarioService;