
const initialState = null;
    

export const transporteDados = {
    namespaced: true,
    state: initialState,
    actions: {
        
    },
    mutations: {
        
    },
    getters: {
        
    }
}