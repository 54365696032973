import Axios from 'axios';
const URL = 'api'; //process.env.VUE_APP_ROOT_API;


Axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.headers && error.response.headers['token-expired'] == "true") {
        localStorage.removeItem("user");
        localStorage.removeItem("menu");
        window.location.href = "/#/login";
    } else if (error.response && error.response.status == 403) {
        window.location.href = "/#/no-permission";
    }
    throw error;
});

export default {
    login(requestURL, data) {
        return Axios.post(URL + requestURL, data);
    },

    blob(requestURL) {
        let authHeader = {
            method: 'GET',
            headers: this.authHeader(),
            responseType: 'blob'
        };
        return Axios.get(URL + requestURL, authHeader);
    },

    get(requestURL) {
        let authHeader = {
            method: 'GET',
            headers: this.authHeader()
        };
        return Axios.get(URL + requestURL, authHeader);
    },

    post(requestURL, data) {
        let authHeader = {
            method: 'POST',
            headers: this.authHeader()
        };
        return Axios.post(URL + requestURL, data, authHeader);
    },

    update(requestURL, data) {
        let authHeader = {
            method: 'PUT',
            headers: this.authHeader()
        };
        return Axios.put(URL + requestURL, data, authHeader);
    },
    blobPost(requestURL, data) {
        let authHeader = {
            method: 'POST',
            headers: this.authHeader(),
            responseType: 'blob'
        };
        return Axios.post(URL + requestURL, data, authHeader);
    },
    //update(requestURL) {
    //    let authHeader = {
    //        method: 'PUT',
    //        headers: this.authHeader()
    //    };
    //    return Axios.put(URL + requestURL, authHeader);
    //},

    //axios({
    //    method: 'post',
    //    url: 'myurl',
    //    data: bodyFormData,
    //    headers: { 'Content-Type': 'multipart/form-data' }
    //})
    //.then(function (response) {
    //    //handle success
    //})
    //    .catch(function (response) {
    //        //handle error
    //    });

    delete(requestURL) {
        let authHeader = {
            method: 'DELETE',
            headers: this.authHeader()
        };
        return Axios.delete(URL + requestURL, authHeader);
    },

    authHeader() {
        let USER = JSON.parse(localStorage.getItem('user'));
        if (USER && USER.token) {
            return { 'Authorization': 'Bearer ' + USER.token };
        } else {
            return {};
        }
    }
};