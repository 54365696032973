import http from '@/_helper/api-services'

export default {
    name: 'AtivarPacote',
    data() {
        return {
            activate: {
                login: String(),
                idProduto: 0
            },
            tipos: [],
            pacotes: [{ text: this.$i18n.t("financeiro.escolher_pacote"), value: 0 }],
        }
    },
    components: {

    },
    mounted() {
        this.$loading(false);
    },
    async beforeMount() {
        this.obterPacotes();
    },
    methods: {
        activatePackage(){
            if(this.validate()){
                this.$loading(true);
                http.post("/product/activatePackage", this.activate).then((response) => {
                    this.$snotify.success(response.data.message);
                    this.limparCampos();

                }, error => {
                    if (error.response.data)
                        this.$snotify.error(error.response.data.message);
                    else
                        this.$snotify.error("Erro ao efetuar solicitação");
                }).finally(() => { this.$loading(false); });
            }
        },
        obterPacotes(){
            http.get("/product/searchGroupByCategory").then((response) => {
                response.data[0].produtos.forEach(item => {
                    this.pacotes.push({
                        text: item.nome,
                        value: item.idProduto
                    });
                });
            });
        },
        validate(){
            if(this.activate.login == ''){
                this.$snotify.error(this.$i18n.t("erros.preencha_os_campos"))
                return false;
            }
            if(this.activate.idProduto == 0){
                this.$snotify.error(this.$i18n.t("erros.preencha_os_campos"))
                return false;
            }
            return true;
        },
        limparCampos() {
            this.activate.login = "";
            this.activate.idProduto = 0;
        }
    }
}