import http from '@/_helper/api-services'
import './Saques.scss';
import SideBar from "@/components/SideBar/SideBar.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';

export default {
    name: 'Saques',
    data() {
        return {
            locale: 'pt',
            search: '',
            fields: [
                { key: 'nome', label: 'Usuário' },
                { key: 'status', label: 'Saque' },
                { key: 'valorSaque', label: 'Valores' },
                { key: 'valorLiquido', label: 'Total líquido' }
            ],
            listaStatus: [
                { text: 'Todos', value: 0 },
                { text: 'Em processamento', value: 1 },
                { text: 'Finalizada', value: 2 },
                { text: 'Cancelada', value: 3 },
                { text: 'Em aprovação', value: 4 }
            ],
            listDados: [],
            dadosUsuario: {},
            nomeLogin: '',
            listTipoFiltro: [],
            totalRows: 1,
            totalPages: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100, 150],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            filtroTela :{
                login: '',
                idStatus: 0, 
                dataInicio: this.dataMenos30(),
                dataFim: new Date()
            },
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            }
        }
    },
    components: {
        SideBar,
        PremiosDashboard,
        DatePicker
    },
    beforeMount() {
        this.obterDadosUsuario();
    },
    mounted() {
        this.obterNomeLogado();
        this.$loading(true);
        this.buscarRelatorio();
    },
    filters: {
        formatMoeda: function (valor) {
            return "$ " + parseFloat(valor).toFixed(2).replace(".", ",");
        },
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        obterDadosUsuario() {
            this.$loading(true);
            http.get('/Dashboard/getBarStatus/').then((responde) => {
                this.dadosUsuario = responde.data;
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        exportarExcel() {
            this.$loading(true);
            http.blob('/report/withdrawalsDownload?login=' + this.filtroTela.login + '&idtipo=' + this.filtroTela.idTipo + '&datatinicio=' + moment(this.filtroTela.dataInicio).format('YYYY-MM-DD') + '&datafim=' + moment(this.filtroTela.dataFim).format('YYYY-MM-DD'), this.filtroTela).then((response) => {
                var blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var url = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.href = url;
                var fileName = 'relatorio-saques.xls';
                link.download = fileName;
                link.click();
                this.$loading(false);
            });
        },
        alterarPagina(pagina) {
            this.currentPage = pagina;
            this.buscarRelatorio();
        },
        buscarRelatorio() {
            this.$loading(true);
            this.filtroTela.pagina = this.currentPage ;
            this.filtroTela.itensPorPagina = this.perPage;
            this.listDados = [];
            http.post('/report/listWithdrawals', this.filtroTela).then((response) => {
                this.totalRows = response.data.totalRows;
                this.totalPages = Math.ceil(this.totalRows / this.perPage);
                response.data.lista.forEach(item => {
                    this.listDados.push(item);
                });
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            } else {
                return '';
            }
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
    }
}