import http from '@/_helper/api-services';
import './ResumoUsuario.scss';
import BarraProgresso from '@/components/BarraProgresso/BarraProgresso.vue';
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';

export default {
    name: 'ResumoUsuario',
    data() {
        return {
            pontosUsuario: {},
            dadosBarraProgresso: {},
            locale: 'pt',
            search: '',
            fields: [{ key: 'tipo', label: 'Tipo', sortable: true, sortDirection: 'desc' },
            {
                key: 'data', label: 'Data', sortable: true, class: 'text-center', formatter: (value) => {
                    if (value && value != "")
                        return `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString()}`;
                    return "";
                }
            },
            { key: 'descricao', label: 'Descrição', sortable: true, class: 'text-center' },
            { key: 'valor', label: 'Valor', sortable: true, class: 'text-center' }],
            dadosResumoSaldo: {},
            dadosResumoCashBack: {},
            listDados: [],
            listTipoFiltro: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            tipoFilter: null,
            dataInicialFilter: this.dataMenos30(),
            dataFinalFilter: new Date(),
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            dadosUsuario: {}
        }
    },
    watch: {

    },
    components: {
        BarraProgresso,
        DatePicker
    },
    filters: {
        formatMoeda: function (valor) {
            return "$ " + parseFloat(valor).toFixed(2).replace(".", ",");
        },
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    beforeMount() {
        this.obterResumoUsuario();
        this.obterDadosBarraProgresso();
        this.listarDados();
        this.resumoSaldo();
        this.tipoFiltro();
        this.obterDadosUsuario();
    },
    methods: {
        obterResumoUsuario() {
            http.get("/user/getUserScore?idUsuario=" + this.$route.params.idUsuario).then((response) => {
                this.pontosUsuario = response.data;
            }, error => {
                if (error.response && typeof error.response.data == "object")
                    this.$snotify.error(error.response.data.message);

            }).finally(() => {
                this.$loading(false);
            });
        },
        obterDadosBarraProgresso() {
            this.$loading(true);
            http.get('/Dashboard/earningLimits?idUsuario=' + this.$route.params.idUsuario).then((responde) => {
                this.dadosBarraProgresso = responde.data;
                this.$loading(false);
            });
        },
        listarDados() {
            this.$loading(true);
            var filter = { dataInicio: this.dataInicialFilter, dataFim: this.dataFinalFilter, chave: this.tipoFilter, idUsuario: this.$route.params.idUsuario };
            this.listDados = [];
            http.post('/extract/fetchExtract', filter).then((response) => {
                this.totalRows = response.data.length;
                response.data.forEach(item => {
                    this.listDados.push({
                        tipo: item.tipo.descricao,
                        data: item.dataLancamento,
                        descricao: item.descricao,
                        valor: item.valor.toFixed(2),
                        valorFormated: '$ ' + item.valor.toFixed(2)
                    })
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                });
                this.$loading(false);
            });
        },
        resumoSaldo() {
            this.$loading(true);
            http.get('/extract/summaryBalance?idUsuario=' + this.$route.params.idUsuario).then((responde) => {
                this.dadosResumoCashBack = responde.data.filter(x => x.tipoSaldo == "CashBack")[0];
                this.dadosResumoSaldo = responde.data.filter(x => x.tipoSaldo == "SaldoRede")[0];
                this.$loading(false);
            });
        },
        tipoFiltro() {
            this.$loading(true);
            http.get('/general/type/cc').then((responde) => {
                this.listTipoFiltro.push({
                    value: null,
                    text: "Selecione um tipo"
                })
                responde.data.forEach(item => {
                    this.listTipoFiltro.push({
                        value: item.chave,
                        text: item.descricao
                    });
                });
                this.$loading(false);
            });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        obterDadosUsuario() {
            this.$loading(true);
            http.get('/Admin/userData/' + this.$route.params.idUsuario).then((response) => {
                this.dadosUsuario = response.data;
                this.$loading(false);
            });
        }
    }
}