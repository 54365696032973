
import http from "@/_helper/api-services.js";

export default {
    name: "AprovarEmail",
    data() {
        return {};
    },
    mounted() {
        if (this.$route.params.idCarteira) {
            let obj = {
                IdUsuarioCarteira: this.$route.params.idCarteira
            }

            http.update("/walletUser/approveWallet", obj).then(success => {
                this.$snotify.success(this.$t("cadastro.carteira_cadastrada"));
                this.$router.push("/my-data");
            }, error => {
                this.$snotify.error(this.$t("cadastro.carteira_nao_cadastrada"));
                this.$router.push("/my-data");
            });
        }
    }
}