import "./appFooter.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "appFooter",
    data(){
        return {
            year: new Date(Date.now()).getFullYear(),
            day: new Date(Date.now()).getDay(),
            dataae: new Date(Date.now()).getDate(),
            dadosUsuario: {}
        };
    },
    beforeMount(){
        window.addEventListener("resize", this.adjustFooterPadding);
    },
    beforeDestroy(){
        window.removeEventListener("resize", this.adjustFooterPadding);
    },
    mounted() {
        this.adjustFooterPadding();
        this.obterDadosUsuario();
    },
    methods:{
        adjustFooterPadding() {
            this.$emit("adjustFooterPadding", this.$refs.footerElement.clientHeight);
        },
        obterDadosUsuario() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/Dashboard/getBarStatus/').then((responde) => {
                    this.dadosUsuario = responde.data;
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                });
                this.$loading(false);
            }
        },
    }
}