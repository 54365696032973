import "./SelecionarPais.scss";
import http from '@/_helper/api-services';
import Multiselect from 'vue-multiselect'

export default {
    name: 'SelecionarPais',
    components: {
        Multiselect
    },
    props: ['salvouPais'],
    data() {
        return {
            selectedCountry: {},
            countryList: []
        }
    },
    beforeMount() {
        this.loadDropDown();
    },
    methods: {
        loadDropDown() {
            http.get("/UsuarioEndereco/listaPaises").then((response) => {
                response.data.forEach(item => {
                    this.countryList.push({
                        idPais: item.idPais,
                        name: item.nome,
                        codigo : item.codigo
                    });
                });
            });
        },
        nameWithLang({ name, codigo }) {
            if (!name || name == '')
                return 'Select one';

            return `+${codigo} - ${name}`
        },
        saveCountry() {
            this.$loading(true);
            if (!this.selectedCountry || !this.selectedCountry.idPais || this.selectedCountry.idPais == 0) {
                this.$snotify.error(this.$i18n.t("generico.selecione_pais"));
                this.$loading(false);
            } else {
                http.post("/user/saveCountry", this.selectedCountry)
                    .then(success => {
                        this.$snotify.success(this.$i18n.t("saque.acao_completada"));
                        localStorage.removeItem("force-country");
                        if (this.salvouPais) {
                            this.salvouPais();
                        }
                        this.$loading(false);
                    }, error => {
                        if (error.response && error.response.data && error.response.data.message)
                            this.$snotify.error(error.response.data.message, { bodyMaxLength: 300 });
                        this.$loading(false);
                    });
            }
        }
    }
}