import http from '@/_helper/api-services';
import './CompletarDados.scss';
import DadosPessoais from '@/components/DadosPessoais/DadosPessoais.vue';
import Endereco from '@/components/Endereco/Endereco.vue';
import FotoPerfil from '@/components/FotoPerfil/FotoPerfil.vue';
import SenhaFinanceira from '@/components/SenhaFinanceira/SenhaFinanceira.vue';
import DadosBancarios from '@/components/DadosBancarios/DadosBancarios.vue';

export default {
    name: 'CompletarDados',
    data() {
        return {
            dadosPessoais: {
                nome: String(),
                login: String(),
                celular: String(),
                rua: String(),
                numero: String(),
                bairro: String(),
                cep: String(),
                complemento: String(),
                imagem: String(),
                file: String(),
                idCidade: Number(),
                assinaturaEletronica: String(),
                repitaAssinatura: String(),
                senhaAntiga: String(),
                novaSenha: String(),
                novaSenhaConfirma: String()
            },
            erroForm: false
        }
    },
    watch: {
        'dadosPessoais.file': function (e) {
            var reader = new FileReader();
            reader.readAsDataURL(e);
            reader.onload = () => {
                this.dadosPessoais.imagem = reader.result;
            };
        }
    },
    components: {
        DadosPessoais,
        Endereco,
        FotoPerfil,
        SenhaFinanceira,
        DadosBancarios
    },
    mounted() {
        this.buscarDadosPessoais();
    },
    methods: {
        buscarDadosPessoais() {
            http.get('/user/getPersonalData').then((response) => {
                this.dadosPessoais.nome = response.data.nome;
                this.dadosPessoais.email = response.data.email;
                this.dadosPessoais.celular = response.data.celular;
                this.dadosPessoais.login = response.data.login;
                this.dadosPessoais.documento = null;

                this.$loading(false);
            });
        },
        atualizarDadosUsuario() {
            if (this.checkForm()) {
                this.$loading(true);

                http.post('/user/updateUserData', this.dadosPessoais).then((response) => {
                    this.$snotify.success(response.data.message);
                    const loggedIn = localStorage.getItem('user');
                    if (loggedIn) {
                        let json = JSON.parse(loggedIn);
                        json.dadosCompletos = true;

                        localStorage.removeItem("user")
                        localStorage.setItem("user", JSON.stringify(json));
                    }
                    setTimeout(() => {
                        this.$router.push("/");
                    }, 2000);
                }, error => {
                    if (error.response && typeof error.response.data == "object")
                        this.$snotify.error(error.response.data.message);
                }).finally(() => { this.$loading(false); });

            }
        },
        checkForm() {
            if (!this.dadosPessoais.nome ||
                !this.dadosPessoais.celular ||
                !this.dadosPessoais.rua ||
                !this.dadosPessoais.numero ||
                !this.dadosPessoais.bairro ||
                !this.dadosPessoais.cep ||
                !this.dadosPessoais.nome ||
                !this.dadosPessoais.documento ||
                ((this.dadosPessoais.novaSenha || this.dadosPessoais.novaSenhaConfirma) && !this.dadosPessoais.senhaAntiga) ||
                (this.dadosPessoais.novaSenha != this.dadosPessoais.novaSenhaConfirma) ||
                (this.dadosPessoais.assinaturaEletronica != this.dadosPessoais.repitaAssinatura)) {

                this.erroForm = true;
                this.$snotify.error(this.$i18n.t("erros.preencha_os_campos"));
                return false;
            }

            return true;
        }
    }
}