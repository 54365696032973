import http from '@/_helper/api-services';
import './CardPontos.scss';

export default {
    name: 'CardPontos',
    data() {
        return {
            pontuacao: {},
            userEsquerda: {
                login: String(),
                dataVinculo: null,
                nomeProduto: String(),
                posicao: Number(),
                pontos: Number()
            },
            userDireita: {
                login: String(),
                dataVinculo: null,
                nomeProduto: String(),
                posicao: Number(),
                pontos: Number()
            },
        }
    },
    watch: {

    },
    beforeMount() {
        this.buscarPontuacao();
    },
    methods: {
        buscarPontuacao() {
            http.get('/user/getUserScore').then((response) => {
                this.pontuacao = response.data;
                this.$loading(false);
            });
        }
    }
}