import http from '@/_helper/api-services';
import './PremiosDashboard.scss';


export default {
    name: 'PremiosDashboard',
    data() {
        return {
            dadosUsuario: {},
            resumoValores: {},
            dadosBarraProgresso: {},
            premioViagemNivel: 6,
            premioCarroNivel: 7,
            nivelUsuario: {
                text: 6
            },
            graduacaoUsuario: {
                graduacaoPremio: {
                    nivelAtual: null,
                    proximoPremio: Number(),
                    graduacaoAtualImagem: null
                }

            },
            listGraduation: [],
            contadorSaque:{
                hora: 0,
                minuto: 0,
                segundo: 0
            },
            timerBinario:{
                hora: 0,
                minuto: 0,
                segundo: 0
            },
        }
    },
    // props: ['nivelUsuario'],
    components: {
        
    },
    beforeMount() {
        //this.withdrawTimer();
        //this.obterDadosUsuario();
        //this.nextGraduation();
    },
    mounted (){
        this.contador();
    },
    methods: {
        obterDadosUsuario() {
            this.$loading(true);
            http.get('/Dashboard/getAwards/').then((responde) => {
                this.graduacaoUsuario = responde.data;
                this.graduacaoUsuario.graduacaoPremio.graduacaoAtualImagem = this.graduacaoUsuario.graduacaoPremio.imagemAtual;
                this.$loading(false);
            });
        },
        nextGraduation(){
            http.get("/Dashboard/nextGraduation/").then((response) => {
                response.data.forEach(item => {
                    this.listGraduation.push({
                        name: item.nome
                    });
                });
            });
        },
        withdrawTimer(){
            http.get("/Dashboard/timerWithdraw/").then((response) => {
                
                    this.contadorSaque.hora = response.data.hours < 10 ? '0' + response.data.hours : response.data.hours;
                    this.contadorSaque.minuto = response.data.minutes < 10 ? '0' + response.data.minutes : response.data.minutes;
                    this.contadorSaque.segundo = response.data.seconds < 10 ? '0' + response.data.seconds : response.data.seconds;
                
            });
        },
        binaryTimer(){
            http.get("/Dashboard/timerBinary/").then((response) => {
                const difference = response.data.totalMilliseconds;
                const hours = Math.floor(difference / 1000 / 60 / 60) % 24;
                const minutes = Math.floor(difference / 1000 / 60) % 60;
                const seconds = Math.floor(difference / 1000) % 60;

                this.timerBinario.hora = hours < 10 ? '0' + hours : hours;
                this.timerBinario.minuto = minutes < 10 ? '0' + minutes : minutes;
                this.timerBinario.segundo = seconds < 10 ? '0' + seconds : seconds;
            })
        },
        contador(){
            const myInterval = window.setInterval(this.withdrawTimer, 1000);
            const binary = window.setInterval(this.binaryTimer, 1000);

        }
    }
}