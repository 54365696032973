import http from "@/_helper/api-services.js";
import util from "@/_helper/util.js";
import 'vue2-datepicker/index.css';
import "./configuracoes.scss";

export default {
    name: "configuracoes",
    data() {
        return {
            fields: [
                { key: "descricao", label: "Descrição", sortable: true, class: 'text-left break-words' },
                { key: "valor", label: "Valor", sortable: true, class: 'text-left break-words' },
                { key: "action", label: "", sortable: false }],
            currentPage: 1,
            totalRows: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            filterOn: [],
            filter: String(),
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            list: [],
            config: {
                idconfiguracao: 0,
                valor: '',
                tipo: '',
                descricao: ''
            }
        };
    },
    components: {
    },

    beforeMount() {
        this.buscarConfiguracoes();
    },
    mounted() {
        this.$loading(false);
    },

    watch: {
        perPage() {
            this.search.page = 1;
            this.buscarConfiguracoes();
        },
        'search.page'() {
            this.buscarConfiguracoes();
        }
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        salvar(event) {
            event.preventDefault();
            if (this.config.valor && this.config.valor != '') {
                http.post("/settings/save", this.config)
                    .then(
                        success => {
                            if (success && success.data.success) {
                                this.$snotify.success("Configuração salva.");
                            }
                            this.$loading(false);
                            this.closeModal();
                            this.buscarConfiguracoes();
                        },
                        error => {
                            this.$loading(false);
                            if (error.response && error.response.data && error.response.data.message)
                                this.$snotify.error(error.response.data.message);
                        }
                    );
            }
            else
                this.$snotify.error("Insira um valor.");
        },
        edit(idConfiguracao) {
            var configSelecionada = this.list.filter(f => f.idConfiguracao == idConfiguracao)[0];
            this.config.idConfiguracao = configSelecionada.idConfiguracao;
            this.config.valor = configSelecionada.valor;
            this.config.tipo = configSelecionada.tipo;
            this.config.descricao = configSelecionada.descricao;
            this.openModal();
        },
        buscarConfiguracoes(event) {
            if (event) event.preventDefault();
            this.$loading(true);
            http.get("/settings/toList")
                .then(
                    success => {
                        this.list = [];
                        if (success && success.data) {
                            success.data.forEach(d => {
                                this.list.push(d);
                            });
                            this.totalRows = this.list.length;
                        }
                        this.$loading(false);
                    },
                    error => {
                        this.$loading(false);
                        if (error.response && error.response.data && error.response.data.message)
                            this.$snotify.error(error.response.data.message);
                    }
                );
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
        },
        formatDate(data) {
            return util.formatDate(data);
        },
        openModal() {
            this.$root.$emit('bv::hide::popover');
            this.$bvModal.show("modalEdit");
        },
        closeModal() {
            this.$bvModal.hide('modalEdit');
        }
    }
};