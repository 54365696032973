import "./boxAdcionarCategoria.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "boxAdcionarCategoria",
    data() {
        return {
            NomeCategoriaPai: '',
            IdCategoriaPai: 0,
            categoria: {
                idCategoria: 0,
                idCategoriaPai: 0,
                nome: '',
                ativo: false,
                icone: '',
                chave: '',
                chaveTraducao: ''
            }
        };
    },
    props: ['chave'],
    beforeMount() {
        this.getCategoriaPai();
    },
    mounted() {
        this.$bvModal.show('modal-adcionar-categoria');
    },
    methods: {
        atualizaCategoriaSelect() {
            this.$emit('atualizarSelectCategoria');
        },
        adcionarModalClose() {
            this.$emit('fecharModal');
        },
        adcionarCategoria() {
            this.categoria.idCategoriaPai = this.IdCategoriaPai;
            http.post("/category/addCategory", this.categoria).then(
                success => {
                    if (success.data) {
                        if (success.data.success) {
                            this.$snotify.success(success.data.message);
                            this.$bvModal.hide('modal-adcionar-categoria');
                            this.$emit('atualizarSelectCategoria');
                        }
                        else {
                            this.$snotify.error(success.data.message);
                        }
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },

        getCategoriaPai() {
            this.$loading(true);
            http.get("/category/searchCategoryFather/" + this.chave).then(
                success => {
                    if (success.data) {
                        this.NomeCategoriaPai = success.data.nome;
                        this.IdCategoriaPai = success.data.idCategoria;
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },
    }
}