import moment from 'moment';
import http from '@/_helper/api-services';

export default {
    name: 'BoxArvoreBinaria',
    props:['dadosUser'],
    data() {
        return {
            parent: [],
            pontuacao: {},
            dadosBarraStatus: {},
            resumoValores: {}
        }
    },
    filters: {
        dataFormatada: function (valor) {
            return moment(String(valor)).format('DD/MM/YYYY');
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
    },
    mounted() {
        this.parent = this.$parent;
    }
}