import "./confirmEmail.js";
import usuarioService from "@/_helper/usuario-service.js";

export default {
    name: "confirmEmail",
    data() {
        return {};
    },
    mounted() {
        if (this.$route.params.token) {
            usuarioService.confirmEmail(this.$route.params.token).then(
                success => {
                    this.$snotify.success(this.$i18n.t("login.email_validado"), { position: "rightTop" });
                    this.$router.push("/login");
                },
                error => {
                    this.$snotify.error(this.$i18n.t("erros.email_nao_validado"), { position: "rightTop" });
                    this.$router.push("/login");
                });
        }
    }
}