import http from "@/_helper/api-services";
import "./MinhasOperacoes.scss";
import SideBar from "@/components/SideBar/SideBar.vue";
import ContagemRegressiva from "../../components/ContagemRegressiva/ContagemRegressiva.vue";
import moment from 'moment';

export default {
  name: 'MinhasOperacoes',
  data() {
    return {
      dadosUsuario: {},
      botAuto: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '$ ',
        precision: 2,
        masked: false
      },
      fields: [
        // { key: 'direcao', label: (this.$i18n.t("minhas_compras.direcao")), sortable: true, class: 'text-center' },
        { key: 'valoroperacao', label: (this.$i18n.t("minhas_operacoes.investimento")), sortable: true, class: 'text-center' },
        { key: 'dataOperacao', label: (this.$i18n.t("minhas_operacoes.order_time")), sortable: true, class: 'text-center' },
        { key: 'lucro', label: this.$t('financeiro.lucro'), sortable: true, class: 'text-center' },
        //{ key: 'lucroPorcentagem', label: this.$t('financeiro.lucro_porcentagem'), sortable: true, class: 'text-center' },
        {
            key: 'status', label: this.$t('financeiro.status'), sortable: true, class: 'text-center',
            formatter: value => {
                return this.$t("status." + value);
            }
        }
      ],
      items: [],
      totalPaginas: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      formSearch: {
      //   dataInicio: this.dataMenos15(),
      //   dataFim: new Date(),
      //   tipoFilter: null,
        itensPorPagina: 25,
        paginaAtual: 1,
      //   chave: null,
      //   texto: "",
      },
      nomeLogin: '',
      listOperations: [],
      dataAtual: Date(),
      test: Boolean()
    };
  },
  components: {
    SideBar,
    ContagemRegressiva
    },
  beforeMount() {
    this.listarOperacoes();
    this.getHoraServidor();
  },
  mounted() {
    //this.obterNomeLogado();
  },
  
  methods: {
    getHoraServidor(){
      http.get("/dashboard/serverTime").then((response) => {
        this.dataAtual = response.data.serverTime;
      });
    },
    obterNomeLogado() {
        const logado = localStorage.getItem('user');
        this.dadoLogado = JSON.parse(logado);
        this.nomeLogin = this.dadoLogado.username.split(' ')[0];
    },
    buscarArbitragens(codigo) {
      this.items = [];  
      http.get("/Arbitragem/myOperations/" + codigo).then((response) => {
          response.data.forEach(item => {
            this.items.push({
              idProduto: item.idProduto,
              imagemUrl: item.imagemUrl,
              tipoArbitragem: item.tipoArbitragem,
              dataOperacao: item.dataOperacao,
              valoroperacao: item.valorOperacao.toFixed(2),
              lucro: item.lucro.toFixed(2),
              lucroPorcentagem: item.lucroPorcentagem.toFixed(2),
              status: item.status,
              hash: item.Hash
          });
        });
        this.totalRows = this.items.length;
        this.$bvModal.show('modal-operacoes');
        }, error => {
            if (error.response && error.response.data && error.response.data.message)
                this.$snotify.error(error.response.data.message);
        }).finally(() => {
            this.$loading(false);
    });
    
    },
    listarOperacoes(){
      this.listOperations = [];
      this.currentPage = 1;
      http.get('/Arbitragem/listOperations').then((response) => {
        this.listOperations = response.data;
      },
      error => {
        if(error.response && error.response.data && error.response.data.message)
          this.$snotify.error(error.response.data.message);
      })
    },
    formatDate(data) {
        if (data) {
            let dateTime = new Date(data);
            return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
        }
    },
    formatPrice(valor) {
      if(!valor)
        return '$0,00';
      
      return valor.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  }
};
   
    
