import http from "@/_helper/api-services.js";
import util from "@/_helper/util.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from 'vue-multiselect'
import "./GerenciarBots.scss";
import moment from "moment";

export default {
    name: "GerenciarBots",
    data() {
        return {
            nomeLogin: '',
            availableBalance: 0,
            valorBTCSelecionado: 0,
            pendingReceivedBalance: 0,
            listaPaises: [{ text: "Selecione o país", value: 0 }],
            paisSelecionado: {},
            search: {
                login: String(),
                dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                nomeProduto: String(),
                idProduto: 0,
                pago: null,
                quantidade: 50,
                page: 1
            },
            totalRows: 1,
            currentPage: 1,
            resumoSaque: {
                aprovadosSemana: 0,
                aprovadosTotal: 0,
                pendentesSemana: 0,
                valorTaxa: 0,
            },
            listStatus: [],
            tipoProduto: [
                { value: 0, text: "Produto"},
                { value: 13, text: "Bot"},
                { value: 14, text: "Ingresso"}
            ],
            statusPagamento: null,
            tipoPagamento: [
                { value: null, text: "Status pagamento"},
                { value: false, text: "Não pago"},
                { value: true, text: "Pago"}
            ],
            fields: [
                // { key: "selecionado", label: "", sortable: false },
                { key: "login", label: "Login", sortable: true, sortDirection: "desc" },
                { key: "email", label: "Email", sortable: true, sortDirection: "desc" },
                { key: "quantidade", label: "Quantidade", sortable: true, sortDirection: "desc" },
                { key: "nomeProduto", label: "Nome Produto" },
                {
                    key: "valor",
                    label: "Valor",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return `$ ${value.toFixed(2)}`;
                    },
                },
                { key: "formaPagamento", label: "Forma Pagamento"},
                { key: "dataCompra", label: "Data Compra", sortable: true },
                { 
                    key: "pago", 
                    label: "Pago", 
                    sortable: true,
                    formatter: (value) => {
                        if(value == 0){
                            return "Não pago"
                        }
                        else{
                            return "Pago"
                        }
                    }
                },
                { key:"acoes", label:""}
            ],
            fieldsSelecionados: [
                { key: "login", label: "Login", sortable: true, sortDirection: "desc" },
                { key: "data", label: "Data Saque", sortable: true },
                {
                    key: "valorReal",
                    label: "Valor a receber",
                    sortable: true,
                    formatter: (value) => {
                        if (!value) return "";
                        return `$ ${value.toFixed(2)}`;
                    },
                },
            ],
            tipoMoeda:[
                { value: null, text: "Tipo moeda"},
                { value: "BTC", text: "BTC"},
                { value: "USDT(TRC20)", text: "USDT(TRC20)"}
            ],
            list: [],
            valorSelecionado: 0,
            selected: [],
            perPage: 50,
            pageOptions: [50, 100, 150, {
                value: 99999,
                text: "show all"
            }],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            action: "",
            filterOn: [],
            todosSelecionados: false,
            usuarioConfiguracao: {
                idUsuarioConfiguracao: 0,
                nomeUsuario: String(),
                idUsuario: String(),
                taxaSaque: Number(),
            },
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez",],
            },
            pagamentoManual: {
                idSaque: 0,
                urlTransacao: String(),
            },
            cotacaoBlockChain: Number(),
            twoFactorCode: String()
        };
    },
    components: {
        DatePicker,
        Multiselect
    },

    beforeMount() {
        this.listarPaises();
        this.buscarCompras();
        //this.buscarStatusSaque();
        this.buscarResumoSaque();
    },
    mounted() {
        //this.obterNomeLogado();
    },
    watch: {
        perPage() {
            this.search.page = 1;
            this.buscarCompras();
        },
        "search.page"() {
            this.buscarCompras();
        },
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        exportarExcel() {
            this.$loading(true);
            // var statusPago = this.search.pago
            // if(this.search.pago == null)
            //     statusPago = "nulo"

            var filter = {
                datainicio: this.search.dataInicio,
                datafim: this.search.dataFim,
                login: this.search.login,
                //nomeproduto: this.search.nomeProduto,
                idproduto: this.search.idProduto
                //pago: ,
                // pagina: this.currentPage,
                // itensPorPagina: this.perPage,
            };
            http
                .blobPost("/report/managebotsDownload", this.search)
                .then((response) => {
                    var blob = new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    var url = window.URL.createObjectURL(blob);
                    var link = document.createElement("a");
                    link.href = url;
                    var fileName = "relatorio-bot-ingresso.xls";
                    link.download = fileName;
                    link.click();
                    this.$loading(false);
                });
        },
        listarPaises() {
            http.get("/general/getCountries").then(
                success => { 
                    if (success.data) {
                        success.data.forEach(item => {
                            this.listaPaises.push({
                                text: item.nome,
                                value: item.idPais
                            });
                        });
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            )

        },
        aprovarSelecionados() {
            if (this.list.filter((c) => c.selecionado === true).length == 0) {
                this.$snotify.error("Nenhum saque selecionado");
            } else {
                this.openModal("aprovar");
            }
        },
        pagarSelecionados() {
            this.$loading(true);
            this.valorSelecionado = 0;
            this.valorBTCSelecionado = 0;
            if (this.list.filter((c) => c.selecionado === true).length == 0) {
                this.$snotify.error("Nenhum saque selecionado");
                this.$loading(false);
            } else {

                http.get("/withdraw/valueInWallet").then(
                    (success) => {
                        var selected = this.list.filter((c) => c.selecionado === true);

                        for (let index = 0; index < selected.length; index++) {
                            var element = selected[index];
                            this.valorSelecionado += element.valorReal;
                        }
                        this.valorBTCSelecionado = (1 / this.cotacaoBlockChain) * this.valorSelecionado;
                        this.availableBalance = success.data.availableBalance;
                        this.pendingReceivedBalance = success.data.pendingReceivedBalance;
                        this.$bvModal.show("modalSaquesSelecionados");
                        this.$loading(false);
                    },
                    (error) => {
                        this.$snotify.error("Sem permissão para consultar saldo");
                        this.$loading(false);
                    }
                );
            }
        },
        cancelarSelecionados() {
            if (this.list.filter((c) => c.selecionado === true).length == 0) {
                this.$snotify.error("Nenhum saque selecionado");
            } else {
                this.openModal("cancelar");
            }
        },
        selecionarTodos() {
            this.buscarCompras();

            //var lista = this.list;
            //for (let index = 0; index < lista.length; index++) {
            //    lista[index].selecionado = this.todosSelecionados;
            //}
            ////for (let index = 0; index < (lista.length / 50); index++) {
            ////    lista = this.selecionarTodosSplit(lista, index * 50, (index + 1) * 50);
            ////}
            //this.list = lista;
        },
        selecionarTodosSplit(lista, de, ate) {
            if (ate > this.list.length)
                ate = this.list.length;

            for (let index = de; index < ate; index++) {
                lista[index].selecionado = this.todosSelecionados;
            }
            return lista;
        },
        selecionados(item) {
            this.selected = this.list.filter((c) => c.selecionado === true);
        },
        totalSelecionado() {
            return this.list.filter((c) => c.selecionado === true).length;
        },
        buscarCompras(event) {
            if (event) event.preventDefault();
            this.$loading(true);
            this.selected = [];
            this.search.quantidade = this.perPage;
            this.statusPagamento = this.search.pago;
            
            http.post("/admin/listBots", this.search).then(
                (success) => {
                    this.list = [];
                    if (success && success.data) {
                        // this.cotacaoBlockChain = success.data.cotacao;
                        success.data.lista.forEach((d) => {
                            this.list.push({
                                login: d.login,
                                pago: d.pago,
                                dataCompra: d.dataCompra,
                                nomeProduto: d.nomeProduto,
                                valor: d.valorProduto,
                                email: d.email,
                                quantidade: d.quantidade,
                                formaPagamento: d.formaPagamento,
                                enderecoDeposito: d.enderecoDeposito
                                // selecionado: this.todosSelecionados,
                                // dataProcessado: d.dataProcessado,
                                // idSaque: d.idSaque,
                                // valorReal: d.valorReal,
                                // processado: d.processado,
                                // enderecoBTC: d.enderecoBTC,
                                // taxaSaque: d.taxaSaque,
                                // tipo: d.tipo,
                                // aprovador: d.aprovador,
                                // historico: d.historico,
                                // dataAprovacao: d.dataAprovacao,
                                // urlTransacao: d.urlTransacao,
                                // observacao: d.observacao,
                                // pais: d.pais,
                                // moeda: d.moeda
                            });
                        });
                        this.totalRows = this.list.length;
                    }
                    this.selected = [];
                    this.$loading(false);
                },
                (error) => {
                    this.$loading(false);
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    )
                        this.$snotify.error(error.response.data.message);
                }
            );
        },
        buscarStatusSaque() {
            http.get("/withdraw/getStatusWithdrawal").then((response) => {
                this.listStatus.push({
                    value: 0,
                    text: "Todos",
                });
                response.data.forEach((item) => {
                    this.listStatus.push({
                        value: item.value,
                        text: item.key == "Em processamento" ? "Aprovado" : item.key,
                    });
                });
            });
        },
        buscarResumoSaque() {
            http.get("/withdraw/getSummaryWithdrawal").then((response) => {
                this.resumoSaque.aprovadosSemana = response.data.aprovadosSemana;
                this.resumoSaque.aprovadosTotal = response.data.aprovadosTotal;
                this.resumoSaque.pendentesSemana = response.data.pendentesSemana;
                this.resumoSaque.valorTaxa = response.data.valorTaxa;
            });
        },
        verificarUsuario() {
            http.get("/admin/checkUserAdmin").then((response) => {
                this.openModalPagamentoManual();
            },
                (error) => {
                    this.$snotify.error(error.response.data);
                });
        },
        confirmarAcao() {
            this.$loading(true);
            var url = "/withdraw/cancelWithdrawals";
            if (this.action == "aprovar") url = "/withdraw/approveWithdrawals";
            var selecionados = [];
            this.list
                .filter((c) => c.selecionado === true)
                .forEach((l) => {
                    selecionados.push({
                        idSaque: l.idSaque,
                    });
                });

            http.post(url, selecionados).then(
                (success) => {
                    if (this.action === "aprovar") {
                        this.$snotify.success("Saques aprovados!");
                    } else {
                        this.$snotify.success("Saques cancelados!");
                    }
                    this.buscarCompras();
                    this.buscarResumoSaque();
                    this.fecharModal();
                    this.selected = [];
                    this.$loading(false);
                },
                (error) => {
                    this.$loading(false);
                    this.fecharModal();
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    )
                        this.$snotify.error(error.response.data.message);
                }
            );
        },
        fecharModal() {
            this.$bvModal.hide("modal-confirm");
        },
        openModal(tipo) {
            this.action = tipo;
            this.$bvModal.show("modal-confirm");
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
        },
        formatDate(data) {
            return util.formatDate(data);
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        handleSubmit(event) {
            event.preventDefault();
            this.$loading(true);
            this.pagamentoManual.idSaque = +this.pagamentoManual.idSaque;
            http
                .post("/withdraw/paymentManual", this.pagamentoManual)
                .then(
                    (success) => {
                        if (success.data && success.data.message) {
                            this.$snotify.success(success.data.message);
                            this.closeModalPagamentoManual();
                            this.buscarCompras();
                        }
                    },
                    (error) => {
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.errors
                        )
                            error.response.data.errors.forEach((message) =>
                                this.$snotify.error(message)
                            );
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        open(Id) {
            alert(1);
        },
        processarNovamente(event) {
            event.preventDefault();
            this.$loading(true);
            this.pagamentoManual.idSaque = +this.pagamentoManual.idSaque;
            http
                .post("/withdraw/processAgain", this.pagamentoManual)
                .then(
                    (success) => {
                        if (success.data && success.data.message) {
                            this.$snotify.success(success.data.message);
                            this.closeModalPagarNovamente();
                            this.buscarCompras();
                        }
                    },
                    (error) => {
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.errors
                        )
                            error.response.data.errors.forEach((message) =>
                                this.$snotify.error(message)
                            );
                    }
                )
                .finally(() => {
                    this.closeModalPagarNovamente();
                    this.$loading(false);
                });
        },
        openModalPagamentoManual(idSaque) {
            this.$root.$emit("bv::hide::popover");
            this.pagamentoManual.idSaque = +idSaque;
            this.$bvModal.show("modalPagarManual");
        },
        closeModalPagamentoManual() {
            this.$bvModal.hide("modalPagarManual");
            this.pagamentoManual.idSaque = 0;
        },
        closeModalPagarNovamente() {
            this.$bvModal.hide("modalPagarNovamente");
            this.pagamentoManual.idSaque = 0;
        },
        openModalPagamentoManual() {
            this.$bvModal.show("modalSaquesAprovado");
        },
        openPagamentoManual(idSaque) {
            this.pagamentoManual.idSaque = +idSaque;
            this.$bvModal.show("modalPagarManual");
        },
        openProcessarNovamente(idSaque) {
            this.pagamentoManual.idSaque = +idSaque;
            this.$bvModal.show("modalPagarNovamente");
        },
        async pagarSaquesAprovados(event) {
            if (event) event.preventDefault();
            this.$loading(true);
            await http.post("/withdraw/payApprovedWithdrawals", { cotacao: +this.cotacaoBlockChain, codigo: this.twoFactorCode })
                .then(
                    (success) => {
                        if ((success.status = 200)) {
                            this.$snotify.success("Pagamentos efetuados com sucesso");
                            this.$bvModal.hide("modalSaquesAprovado");
                            this.buscarCompras();
                        } else {
                            this.$snotify.error("Erro ao efetuar pagamentos");
                        }
                    },
                    (error) => {
                        this.$snotify.error(error.response.data);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },

        async pagarSaquesSelecionados(event) {
            if (event) event.preventDefault();
            this.$loading(true);
            var selecionados = [];
            this.list
                .filter((c) => c.selecionado === true)
                .forEach((l) => {
                    selecionados.push({
                        idSaque: l.idSaque,
                    });
                });

            if (selecionados.length == 0) {
                this.$snotify.error("Nenhum saque selecionado.");
                this.$loading(false);
                return false;
            }

            await http.post("/withdraw/paySelectedWithdrawals", { lista: selecionados, cotacao: +this.cotacaoBlockChain, codigo: this.twoFactorCode })
                .then(
                    (success) => {
                        if ((success.status = 200)) {
                            this.$snotify.success("Pagamentos enviados para a fila de processamento e logo estarão finalizados.");
                            this.$bvModal.hide("modalSaquesSelecionados");
                            this.buscarCompras();
                        } else {
                            this.$snotify.error("Erro ao efetuar pagamentos");
                        }
                        this.$loading(false);
                    },
                    (error) => {
                        this.$snotify.error(error.response.data);
                        this.$loading(false);
                    }
                );
        },
    },
};