import "./formCadastro.scss";
import usuarioService from "@/_helper/usuario-service.js";
import VueCountryCode from "vue-country-code-select";

export default {
    name: "formCadastro",
    data() {
        return {
            name: String(),
            document: String(),
            email: String(),
            login: String(),
            password: String(),
            confirmPassword: String(),
            sponsor: 'cadastro',
            acceptTerms: true,
            phone: String(),
            //data_nascimento: String(),
            //countryCode: '',
            countryName: '',
            countryIso: '',
            disabled: false,
            recaptchaToken: '',
            pin: String(),
            confirmPin: String(),
            tipoPagamento: null,
            recaptchaToken: '',
            code: '',
        };
    },
    components: {
        VueCountryCode,
    },
    max:100,
    props: ['sponsorP'],
    mounted() {
        if (this.$props.sponsorP != null && this.$props.sponsorP != "") {
            usuarioService.checkSponsor(this.$props.sponsorP).then(
                success => {
                    this.sponsor = this.$props.sponsorP;
                    this.disabled = true;
                },
                error => {
                    if (error.response && error.response.data) {
                        this.$snotify.error(error.response.data.message, { position: "rightTop" });
                    }
                }
            );
        }

        setTimeout(() => {
            const recaptcha = this.$recaptchaInstance
            recaptcha.showBadge()
        }, 500)
    },
    methods: {
        //maiorIdade(data_nasc) {
        //    // let data_nasc = "2002-02-31"
        //    let nasc = this.data_nascimento.split("-").map(Number);
        //    if (nasc[2] > 0 && nasc[2] < 32 && nasc[1] > 0 && nasc[1] < 13 && nasc[0] >= 1930) {
        //      //construir data 18 anos a seguir a data dada pelo usuario
        //      let depois18Anos = new Date(nasc[0] +18 , nasc[1] - 1,nasc[2]);
        //      let agora = new Date();
        //      if (depois18Anos <= agora) {
        //        return false
        //      } else {
        //        return true
        //      }
        //    } else {
        //      return true
        //    }
        //  },
        numbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            /*
             * 45 = -
             * 32 = SPACE
             * 43 = +
             * 40 = (
             * 41 = )
             */
            if (charCode == 45 || charCode == 32 || charCode == 43 || charCode == 40 || charCode == 41 || (charCode >= 48 && charCode <= 57)) {
                if (this.phone.length > 30)
                    evt.preventDefault();;

                return true;
            } else {
                evt.preventDefault();;
            }
        },
        onSelect({ name, iso2 }) {
            //this.countryCode = dialCode;
            this.countryName = name;
            this.countryIso = iso2;
        },
        removeNonNumeric(text) {
            if (text == "" || text == null) return "";
            return text.match(/\d+/g).join("");
        },
        removeEspecialCharacter() {
            this.login = this.login.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        },
        validateLogin(event) {
            if (/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi.test(event.key) || event.key == " ")
                event.preventDefault();
        },
        async recaptcha() {
            if (this.validate()) {
                this.$loading(true);
                // (optional) Wait until recaptcha has been loaded.
                await this.$recaptchaLoaded()
                // Execute reCAPTCHA with action "login".
                const token = await this.$recaptcha('register')
                // Do stuff with the received token.
                this.recaptchaToken = token;
                this.register();
            }
        },
         validate(){
            if (this.name == '') {
                this.$snotify.error(this.$i18n.t("erros.nome_nao_informado"));
                return false;
            }
            if (this.email == '') {
                this.$snotify.error(this.$i18n.t("erros.email_nao_informado"));
                return false;
            }
            if (this.phone == '') {
                this.$snotify.error(this.$i18n.t("erros.telefone_nao_informado"));
                return false;
            }
            // if(this.data_nascimento == '') {
            //     this.$snotify.error(this.$i18n.t("erros.data_nascimento"));
            //     return false;
            // }
            // if(this.data_nascimento.length < 10) {
            //     this.$snotify.error(this.$i18n.t("erros.data_nascimento_invalida"));
            //     return false;
            // }
            // if(this.maiorIdade(this.data_nascimento) == true) {
            //     this.$snotify.error(this.$i18n.t("erros.data_nascimento_proibido"));
            //     return false;
            // }
            if (this.login == '') {
                this.$snotify.error(this.$i18n.t("erros.usuario_nao_informado"));
                return false;
            }
            if (this.password == '') {
                this.$snotify.error(this.$i18n.t("erros.senha_nao_informado"));
                return false;
            }
            if (this.password != this.confirmPassword) {
                this.$snotify.error(this.$i18n.t("erros.senha_nao_confere"));
                return false;
            }
            if (this.pin == '') {
                this.$snotify.error(this.$i18n.t("erros.pin_nao_informado"));
                return false;
            }
            if (this.pin != this.confirmPin) {
                this.$snotify.error(this.$i18n.t("erros.pin_nao_confere"));
                return false;
            }
            if (this.sponsor == '') {
                this.$snotify.error(this.$i18n.t("erros.patrocinador_nao_informado"));
                return false;
            }
            if (!this.tipoPagamento) {
                this.$snotify.error(this.$i18n.t("erros.meio_pagamento"));
                return false;
            }
            if(!this.acceptTerms) {
                this.$snotify.error(this.$i18n.t("erros.aceite_os_termos"));
                return false;
            }
            return true;
        },
         register(){
            if (this.validate()){
            this.$loading(true);
            //const dtNascimento = new Date(this.data_nascimento);
             usuarioService.register(
                {
                    nome: this.name,
                    email: this.email,
                    login: this.login,
                    loginPatrocinador: this.sponsor,
                    senha: this.password,
                    countryCode: this.countryCode,
                    countryName: this.countryName,
                    countryIso: this.countryIso,
                    //dataNascimento: dtNascimento, 
                    // documento: this.document,
                    gToken: this.recaptchaToken,
                    celular: this.removeNonNumeric(this.phone),
                    assinaturaEletronica: this.pin,
                    tipoPagamento: this.tipoPagamento
                }).then(success => {
                    //this.$router.push('login');
                    this.loginUser();
                    this.$snotify.success(this.$i18n.t("cadastro.concluido"));
                }, error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                }).finally(() => { this.$loading(false); });
        }
    },
    async loginUser() {
        //this.$loading(true);
        const { dispatch } = this.$store;
        const token = await this.$recaptcha('login')
        // Do stuff with the received token.
        this.recaptchaToken = token;
        var ip = { data: '10.0.0.0' };
        var origem = window.location.origin;
        dispatch("autenticacao/login", {
            user: this.login,
            senha: this.password,
            otpCode: this.code,
            ipAcesso: ip.data,
            origem: origem,
            captchaText: null, //this.captchaText,
            captchaChiper: null, //this.chiper,
            gToken: this.recaptchaToken
        })
            .then(success => {
                if (this.remember)
                    localStorage.setItem("remember", JSON.stringify({ user: this.user }));

                localStorage.setItem("showComunicado", "true");
                window.location.href = "/";
            },
                error => {
                    //this.$loading(false);
                    if (error.response && error.response.data) {
                        this.$snotify.error(error.response.data, { position: "rightTop" });
                   }
                });
    },
    }
}