import "./alterarDadosUsuario.scss";
import http from "@/_helper/api-services.js";
import { VueTelInput } from "vue-tel-input";

export default {
    name: "alterarDadosUsuario",
    data() {
        return {
            form: {
                idUsuario: String(),
                login: String(),
                email: String(),
                senha: String(),
                senhaConfirma: String(),
                loginPatrocinador: String(),
                codigoOtpAdmin: "",
                celular: String(),
                nome: String(),
                idGraduacao: 1,
                tipoPagamento:"",
                pin: String()
            },
            fields: [{ key: "moeda", label: "Moeda", sortable: true, sortDirection: "desc" },
            { key: "endereco", label: "Endereco", sortable: true },
            { key: "status", label: "Status", sortable: true , formatter: val => { return val == true ? this.$i18n.t("graduacao.Active") : this.$i18n.t("graduacao.Inactive") }},
            // { key: "acao", label: "Editar" }
            ],
            items:[],
            currentPage: 1,
            totalPages: 0,
            totalRows:0,
            perPage: 5,
            pageOptions: [5, 10, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            listaGraduacoes: [],
            opcaoMeioPagamento:[
                {text:"PIX", value:"PIX"},
                {text:"Cripto", value:"Cripto"}
            ],
            editWallet: {
                idUsuarioCarteira: String(),
                endereco: null,
                codigoOtpAdmin: ""
            }
        };
    },
    components: {
        VueTelInput,
    },
    beforeMount() {
        this.form.idUsuario = this.$route.params.idUsuario;
        this.getUserData();
        this.buscarCarteiras();
        this.buscaGraduacoes();
    },
    mounted() {
        this.$loading(false);
    },
    methods: {
        modalCarteira(obj){
            this.$bvModal.show("modal-carteira");
            this.editWallet.idUsuarioCarteira = obj.idUsuarioCarteira
            this.editWallet.endereco = obj.endereco;
        },
        closeModalCarteira(){
            this.$bvModal.hide("modal-carteira");
            this.editWallet.codigoOtpAdmin = "";
        },
        editarCarteira(){
            this.$loading(true);
            http.post("/admin/editUserWallet", this.editWallet).then((success) => {
                this.$snotify.success(success.data.message);
                this.closeModalCarteira();
                this.buscarCarteiras();
            },
            (error) => {
                if (error.response.data) {
                    this.$snotify.error(error.response.data.message, { position: "rightTop" });
                } 
                this.$loading(false);
            }).finally(() => {
                    this.$loading(false);
                });
        },
        buscaGraduacoes(){
            http.get("/general/getGraduations").then((response) => {
                response.data.forEach(item => {
                    this.listaGraduacoes.push({
                        text: item.nome,
                        value: item.idGraduacao
                    })
                });
            });
        },
        getUserData() {
            http.get(`/admin/userData/${this.form.idUsuario}`).then(
                (success) => {
                    if (success && success.data) {
                        this.form.login = success.data.login;
                        this.form.email = success.data.email;
                        this.form.loginPatrocinador = success.data.loginPatrocinador;
                        this.form.celular = success.data.celular;
                        this.form.nome = success.data.nome;
                        this.form.idGraduacao = success.data.idGraduacao;
                        this.form.tipoPagamento = success.data.tipoPagamento;
                    }
                },
                (error) => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                    else this.$snotify.error("Erro ao encontrar o usuario");
                }
            );
        },
        buscarCarteiras() {
            this.$loading(true);
            http.get(`/walletUser/getUserWalletAdmin/${this.form.idUsuario}`).then((response) => {
                this.items = [];
                response.data.forEach((item) => {
                    this.items.push({
                        idUsuarioCarteira: item.idUsuarioCarteira,
                        descricao: item.descricao,
                        endereco: item.endereco,
                        moeda: item.moeda,
                        aprovado: item.aprovado,
                        dataAprovacao: item.dataAprovacao,
                        status: item.ativo
                    });
                });
                this.totalRows = response.data.length;
                this.$loading(false);
            });
        },
        closeModal() {
            this.$bvModal.hide("modal-2FA");
        },
        openModal() {
            this.$bvModal.show("modal-2FA");
        },
        handleSubmit(event) {
            event.preventDefault();
            this.openModal();
        },
        submitData() {
            this.$loading(true);
            http.post("/Admin/editUserData", this.form)
                .then(
                    (success) => {
                        if (success && success.data) {
                            this.$snotify.success(success.data.message);
                            this.form.codigoOtpAdmin = String();
                            this.form.pin = String();
                            this.form.senha = String();
                            this.form.senhaConfirma = String();
                            this.closeModal();
                        }
                    },
                    (error) => {
                        if (error.response.data) {
                            this.$snotify.error(error.response.data.message, { position: "rightTop" });
                        } 
                        this.$loading(false);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        handleReset() {
            this.form.login = String();
            this.form.email = String();
            this.form.codigoOtpAdmin = String();
            this.form.senha = String();
            this.form.senhaConfirma = String();
            this.form.loginPatrocinador = String();
            this.form.celular = String();
            this.form.nome = String();
            this.form.pin = String();
            this.getUserData();
        },
    },
};