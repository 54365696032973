export default {
  name: "StGuard",
  props: ["codigo", "erroForm"],
  data() {
    return {};
  },
  components: {},
  mounted() {
    this.$loading(false);
  },
  methods: {},
};
