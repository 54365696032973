import "./listagemIngressos.scss";
import itemIngresso from "@/components/ItemIngresso/itemIngresso.vue";
import http from "@/_helper/api-services.js";
import carousel from "vue-owl-carousel";
import chrevon from "@/assets/icons/chrevon-carousel.svg";

export default {
    name: "listagemIngressos",
    components: {
        itemIngresso,
        carousel,
        chrevon
    },
    data() {
        return {
            produtos: [],
            categorias: [],
            paymentForms: Object(),
            configCarousel: {
                autoHeight: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: false,
                        margin: 5,
                        loop: false
                    },
                    768: {
                        items: 2,
                        nav: false,
                        margin: 10,
                        loop: false
                    },
                    992: {
                        items: 3,
                        nav: true,
                        margin: 15,
                        loop: false
                    },
                    1200: {
                        items: 4,
                        nav: true,
                        margin: 25,
                        loop: false
                    }
                }
            },
            teste: 0
        };
    },
    computed: {
    },
    beforeMount() {
         this.getCategorysWithProducts();
         this.getPaymentsForms();
    },
    methods: {
        atualizarTabela() {
            this.$emit('atualizarTabela');
        },
        getCategorysWithProducts() {
            http.get("/product/searchTickets")
                .then(success => {
                    if (success.data) {
                        this.produtos = success.data;
                        this.categorias = [{ nome: 'Ingressos' }];
                    }
                }, error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                });
        },
        getPaymentsForms() {
            http.get("/purchase/getPurchaseMethods").then(success => {
                this.paymentForms = success.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            });
        }
    }
}