import "./categorias.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "categorias",
    data() {
        return {
            nomeLogin: '',
            fields: [{ key: "categoriaPai", label: "Categoria pai", sortable: true },
            { key: "nome", label: "Nome", sortable: true },
            { key: "ativo", label: "Ativo", sortable: true },
            { key: "action", label: "", sortable: false }],
            categorias: [],
            currentPage: 1,
            perPage: 25,
            totalRows: 0,
            pageOptions: [25, 50, 100],
            filterOn: [],
            filter: String(),
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            categoria: {
                idCategoriaPai: "",
                nome: String(),
                categoriaPai: false
            }
        };
    },
    components: {
    },
    mounted() {
        //this.obterNomeLogado();
    },
    computed: {
        items() {
            return this.categorias.map(m => {
                return {
                    categoriaPai: m.idCategoriaPai ? this.categorias.filter(p => p.idCategoria == m.idCategoriaPai)[0].nome : "",
                    nome: m.nome,
                    idCategoria: m.idCategoria,
                    ativo: m.ativo
                };
            });
        },
        categoriasPai() {
            let retorno = [];
            if (!this.categoria.idCategoriaPai)
                retorno.push({ value: "", text: "Selecione a categoria pai" });

            this.categorias.filter(c => !c.idCategoriaPai).forEach(c => {
                retorno.push({
                    value: c.idCategoria,
                    text: c.nome
                });
            })

            return retorno;
        }
    },
    async beforeMount() {
        this.$loading(true);
        await this.getCategorias();
        this.$loading(false);
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            if(logado){
                this.dadoLogado = JSON.parse(logado);
                this.nomeLogin = this.dadoLogado.username.split(' ')[0];
            }
        },
        async getCategorias() {
            await http.get("/category/searchAllCategories").then(
                success => {
                    if (success && success.data) {
                        this.categorias = success.data;
                        this.totalRows = success.data.length;
                    }
                }, error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            );
        },
        activateDeactivateCategoria(idCategoria) {
            this.$loading(true);
            http.get(`/category/inactivateCategory/${idCategoria}`).then(
                success => {
                    this.categorias.forEach(c => {
                        if (c.idCategoria == idCategoria)
                            c.ativo = !c.ativo;
                    });
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => { this.$loading(false); })
        },
        modalCategoria(idCategoria) {
            this.$root.$emit('bv::hide::popover');
            if (idCategoria)
                this.categoria = this.categorias.filter(c => c.idCategoria == idCategoria)[0];
            this.$bvModal.show("cadastrarCategoria");
        },
        closeModal() {
            this.categoria = {
                idCategoriaPai: "",
                nome: String()
            };
            this.$bvModal.hide("cadastrarCategoria");
        },
        handleSubmit(event) {
            event.preventDefault();
            if (this.categoria.idCategoria)
                this.updateCategoria()
            else
                this.addCategoria();
        },
        updateCategoria() {
            this.$loading(true);
            http.update("/category/editCategory", {
                idCategoria: this.categoria.idCategoria,
                idCategoriaPai: this.categoria.idCategoriaPai ? this.categoria.idCategoriaPai : null,
                nome: this.categoria.nome,
                ativo: this.categoria.ativo
            }).then(
                success => {
                    if (success && success.data)
                        this.$snotify.success(success.data.message);

                    this.$bvModal.hide("cadastrarCategoria");
                    this.getCategorias();
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => { this.$loading(false); });
        },
        addCategoria() {
            this.$loading(true);
            http.post("/category/addCategory", {
                idCategoriaPai: this.categoria.idCategoriaPai ? this.categoria.idCategoriaPai : null,
                nome: this.categoria.nome
            }).then(
                success => {
                    if (success && success.data)
                        this.$snotify.success(success.data.message);

                    this.$bvModal.hide("cadastrarCategoria");
                    this.getCategorias();
                }, error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => { this.$loading(false); });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}