import moment from 'moment';
import "./ContagemRegressivaAtivo.scss";
import http from '@/_helper/api-services';

export default {
  name: 'ContagemRegressiva',
  props: ['dataOperacao', 'dataSistema'],
  data() {
    return {
      ativoTime: [],
      timeServer: {},
      dataAtivo: {},
      dias: 0,
      horas: 0,
      minutos: 0,
      segundos: 0,
      counter: 0
    };
  },
  created() {
    http.get('/Dashboard/ativoTime/').then((responde) => {
      this.ativoTime = responde.data;
      this.dataAtivo = this.ativoTime.dataAtivo
      this.timeServer = this.ativoTime.serverTime
      const dateAtivo = new Date(this.dataAtivo);
      const dateServer = new Date(this.timeServer);
      this.$loading(false);
      if (this.dataAtivo == null || dateAtivo <= dateServer) {
        return
      }
      else {
        const dataFinal = moment(this.dataAtivo);
        this.counter = setInterval(() => {
          http.get("/dashboard/serverTime").then((response) => {
            this.timeServer = response.data.serverTime;
          });
          const agora = moment(this.timeServer);
          const diferenca = moment.duration(dataFinal.diff(agora));
          if (diferenca.seconds() < 0) {
            return;
          }else{
            this.dias = diferenca.days();
            this.horas = diferenca.hours();
            this.minutos = diferenca.minutes();
            this.segundos = diferenca.seconds();
          }
        }, 1000);
      };
    });
  },
  beforeDestroy() {
    clearInterval(this.counter);
  }
};