import "./Dashboard.scss";
import http from "@/_helper/api-services.js";
import { integer } from "vuelidate/lib/validators";
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import BarraToken from "@/components/BarraToken/BarraToken.vue";
import BarraPacotes from '@/components/BarraPacotes/BarraPacotes.vue';

export default {
    name: "Dashboard",
    data() {
        return {
            dadosBarraPacotes:{},
            nomeLogin: '',
            fields: [
                { key: 'nome', label: 'Nome' },
                { key: 'produto', label: 'Pacote' },
                { key: 'celular', label: 'Telefone' },
                { key: 'email', label: 'E-mail' },
            ],
            items: [
            ],
            fields1: [
                { key: "tipos", label: "Tipos lançamentos", sortable: false, class: 'text-left' },
                { key: "total", label: "Total", sortable: false, class: 'text-right' }
            ],
            history: [],
            produtos: [],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            seriesProdutos: [],
            seriesUsuarios: [],
            chartProdutoOptions: {
                chart: {
                    width: 540,
                    type: "pie",
                },
                legend: {
                    position: "bottom"
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 280
                        },
                        legend: {
                            position: "right"
                        }
                    }
                }]
            },
            chartUsuariosOptions: {
                chart: {
                    type: 'line',
                    shadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 1
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ["#AD2E33", "#ddb365"],
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                yaxis: {
                    title: {
                        text: 'Quantidade'
                    }
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'Dias'
                    }
                }
            },
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            entradasTotais: String(),
            binario: String(),
            total: 0,
            valorTotalRecebido: 0,
            rendimento: String(),
            direto: String(),
            saquesPendentes: String(),
            saquesFinalizados: String(),
            saquesAprovacao: String(),
            comprasPagasBlockIO: String(),
            saidasTotais: String(),
            valorPedidosManuais: '$ 0.00',
            totalPedidosManuais: '0',
            totalUsuariosCadastrados: 0,
            totalUsuariosAtivos: 0,
            keyUsuarios: 0,
            dataInicio: this.dataMenos30(),
            dataFim: new Date(),
            series: [{
                data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
              }],
              chartOptions: {
                chart: {
                  type: 'bar',
                  height: 350
                },
                plotOptions: {
                  bar: {
                    borderRadius: 4,
                    horizontal: true,
                  }
                },
                dataLabels: {
                  enabled: false
                },
                xaxis: {
                  categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
                    'United States', 'China', 'Germany'
                  ],
                }
              },
              relBots: {}
        };
    },
    components: {
        BarraToken,
        BarraPacotes,
        DatePicker
    },
    mounted() {
        //this.obterNomeLogado();
    },
    beforeMount() {
        this.obterResumoDashAdmin();
        this.getGraficoProduto();
        this.getGraficoUsuarios();
        this.getUltimosUsuarios();
        this.obterDadosBarraPacotes();
        this.relatorioCompraBot();
    },
    methods: {
        relatorioCompraBot(){
            http.get('/Admin/relBots').then((response) => {
                this.relBots = response.data
            }, error => {
                if (error && error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            });
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            if(logado){
                this.dadoLogado = JSON.parse(logado);
                this.nomeLogin = this.dadoLogado.username.split(' ')[0];
            }
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        getUltimosUsuarios() {
            http.get('/Admin/lastActiveUsers/').then((responde) => {
                this.items = responde.data;
            });
        },
        obterDadosBarraPacotes() {
            this.$loading(true);
            http.get('/Dashboard/earningLimits/').then((responde) => {
                this.dadosBarraPacotes = responde.data;
                this.$loading(false);
            });
        },
        obterResumoDashAdmin() {
            this.$loading(true);
            let data = {
                dataInicio: this.dataInicio,
                dataFim: this.dataFim
            };
            http.post("/Admin/getSummaryDashAdmin", data).then(response => {
                this.history = [];
                Object.keys(response.data).forEach((item) => {
                    if (item == 'Valor total pago de binário') {
                        this.binario = this.formatPrice(response.data[item]);
                    } else if (item == 'Valor total pago de cashback') {
                        this.rendimento = this.formatPrice(response.data[item]);
                    } else if (item == 'Valor total pago direto') {
                        this.direto = this.formatPrice(response.data[item]);
                    }else if (item == 'Valor total saques finalizados') {
                        this.saquesFinalizados = this.formatPrice(response.data[item]);
                    }else if (item == 'Valor total saques pendentes') {
                        this.saquesPendentes = this.formatPrice(response.data[item]);
                    }else if (item == 'Valor total saques em aprovação') {
                        this.saquesAprovacao = this.formatPrice(response.data[item]);
                    } else if (item == 'EntradasTotais') {
                        this.entradasTotais = this.formatPrice(response.data[item]);
                    } else if (item == 'Compras pagas') {
                        this.comprasPagasBlockIO = this.formatPrice(response.data[item]);
                    } else if (item == 'ValorPedidosManuais') {
                        this.valorPedidosManuais = this.formatPrice(response.data[item]);
                    } else if (item == 'TotalPedidosManuais') {
                        this.totalPedidosManuais = response.data[item];
                    } else if (item == 'SaidasTotais') {
                        this.saidasTotais = this.formatPrice(response.data[item]);
                    } else if (item == 'TotalUsuariosCadastrados') {
                        this.totalUsuariosCadastrados = response.data[item];
                    } else if (item == 'TotalUsuariosAtivos') {
                        this.totalUsuariosAtivos = response.data[item];
                    }
                });
                this.$loading(false);
            }, error => { this.$loading(false); });
        },
        filtrar(){
            this.obterResumoDashAdmin();
        },
        formatDate(data) {
            let dateTime = new Date(data);
            return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
        },
        async getGraficoProduto() {
            await http.get("/Admin/GetChartSaleProducts").then(response => {

                if (response && response.data) {
                    response.data.forEach(item => {
                        this.produtos.push({ produto: item.nome, total: item.valorTotal, quantidade: item.quantidadeVendida });
                        this.total += item.quantidadeVendida;
                        this.valorTotalRecebido += item.valorTotal;
                    });
                }
            }, error => {
                if (error && error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            });
        },
        formatUSD(valor){
            return valor.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              });
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        async getGraficoUsuarios() {
            await http.get("/report/reportActivatedRegistered").then(
                success => {
                    if (success && success.data) {
                        this.seriesUsuarios.push({
                            name: "Ativados",
                            data: success.data.map(d => d.ativados)
                        })
                        this.seriesUsuarios.push({
                            name: "Cadastrados",
                            data: success.data.map(d => d.cadastrados)
                        });
                        this.chartUsuariosOptions.xaxis.categories = success.data.map(d => {
                            return new Date(d.data).toLocaleDateString();
                        });
                        this.keyUsuarios++;
                    }
                }, error => {
                    if (error && error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            );
        }
    }
}