import http from '@/_helper/api-services';
import "./SideBar.scss";
import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";
import CardInvestimento from '@/components/CardInvestimento/CardInvestimento.vue';
import Graduacao from "@/components/Graduacao/Graduacao.vue";
import ContagemRegressivaAtivo from "../../components/ContagemRegressivaAtivo/ContagemRegressivaAtivo.vue";

export default {
    name: 'SideBar',

    data() {
        return {
            dadosUsuario: {},
            pontosUsuario: {},
            resumoValores: {},
            totalUsers: 0,
            dadoLogado: {},
            nomeLogin: '',
            userPackage: {},
            diaSelecionado: 15,
            ganhosDias: {},
            activeClass: false,
            listContrats: [
                {
                    value: null,
                    text: this.$i18n.t("jogos.escolher_contrato"),
                    valorContrato: 0,
                    disponivel: false
                }
            ],
            formContrato: {
                idPedido: null,
                idJogo: 0,
                valorContrato: 0,
                disponivel: Boolean()
            },
            ganhoEstimado: 0,
            habilitaBotao: true,
            operacaoAutomatica: false,
            countClock: 0,
            dataBanco: Date(),
            timer: {
                hours: 0,
                minutes: 0,
                seconds: 0
            }
        }
    },
    props: ['jogoSelecionado','graduacaoUsuario'],
    components: {
        LinkPatrocinador,
        CardInvestimento,
        Graduacao,
        ContagemRegressivaAtivo
    },
    watch: {
        "formContrato.idPedido"(e) {
            if (this.jogoSelecionado.percentage && e) {
                if(this.formContrato.idPedido === 999999999 ){
                  
                    let filtrado = this.listContrats.filter(function(obj) { return obj.disponivel == true && obj.value != 999999999 });
                    let somatorioTotal = 0; 
                    filtrado.forEach(item => {
                        somatorioTotal += (item.valorContrato * (this.jogoSelecionado.percentage / 100))
                    });

                    this.formContrato.disponivel = this.listContrats.filter(item => item.value === this.formContrato.idPedido)[0].disponivel;
                    this.ganhoEstimado = somatorioTotal;
                    this.habilitaBotao = false;
                }
                else{
                    let valorDoContrato = this.listContrats.filter(item => item.value === this.formContrato.idPedido)[0].valorContrato;
                    this.formContrato.disponivel = this.listContrats.filter(item => item.value === this.formContrato.idPedido)[0].disponivel;
                    this.ganhoEstimado = valorDoContrato * (this.jogoSelecionado.percentage / 100)
                    this.habilitaBotao = false;
                }
            }
            else {
                this.ganhoEstimado = 0
            }
        },
        "jogoSelecionado.percentage"(e) {
            if (this.formContrato.idPedido && e) {
                if(this.formContrato.idPedido === 999999999 ){
                  
                    let filtrado = this.listContrats.filter(function(obj) { return obj.disponivel == true && obj.value != 999999999 });
                    let somatorioTotal = 0; 
                    filtrado.forEach(item => {
                        somatorioTotal += (item.valorContrato * (this.jogoSelecionado.percentage / 100))
                    });
                    
                    this.formContrato.disponivel = this.listContrats.filter(item => item.value === this.formContrato.idPedido)[0].disponivel;
                    this.ganhoEstimado = somatorioTotal;
                    this.habilitaBotao = false;
                }
                else{
                    let valorDoContrato = this.listContrats.filter(item => item.value === this.formContrato.idPedido)[0].valorContrato;
                    this.formContrato.disponivel = this.listContrats.filter(item => item.value === this.formContrato.idPedido)[0].disponivel;
                    this.ganhoEstimado = valorDoContrato * (this.jogoSelecionado.percentage / 100)
                    this.habilitaBotao = false;
                }
            }
            else {
                this.ganhoEstimado = 0
            }
        }
    },
    mounted() {
        this.obterPontosUsuario();
        this.obterResumoValores();
        //this.obterNomeLogado();
    },
    beforeMount() {
        this.obterDadosUsuario();
        this.getContrats();
        this.obterNomeLogado();
        this.meuPacote();
        this.getdate();
    },

    methods: {
        getdate(){
            http.get('/general/getDate').then((response) => {
               this.dataBanco = new Date(response.data);
               
                this.countClock = setInterval(() => {
                    this.timer.hours = this.dataBanco.getHours();
                    this.timer.minutes = this.dataBanco.getMinutes();
                    this.timer.seconds = this.dataBanco.getSeconds();

                    this.dataBanco.setSeconds(this.dataBanco.getSeconds() + 1);
                }, 1000)


            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });
        },
        activateDesactivateAutomaticOperations() {
            this.$loading(true);
            http.post('/Jogo/ativate-disable-automatic-operations').then((response) => {
                this.operacaoAutomatica = response.data.operation;
                this.$snotify.success(response.data.message);
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });
        },
        checkContracts(){
            let activeContract = this.listContrats.filter(item => item.disponivel === true);

            if(activeContract.length){
                this.listContrats.splice(1, 0,
                    {
                        value: 999999999,
                        text: this.$i18n.t("status.todos"),
                        valorContrato: 0,
                        disponivel: true
                    }

                )
            }
        },
        confirmAction() {
            if(this.formContrato.disponivel == true && this.formContrato.idPedido != null){
                this.formContrato.valorContrato = this.listContrats.filter(item => item.value === this.formContrato.idPedido)[0].valorContrato;
                this.$bvModal.show('modal-confirm');
            }
        },
        checkForm() {
            if (!this.jogoSelecionado.idJogo ||
                !this.formContrato.idPedido) {
                return false;
            }

            return true;
        },
        betnow() {
            this.$loading(true);
            if (this.checkForm()) {

                let dados = {
                    idJogo: this.jogoSelecionado.idJogo,
                    idPedido: this.formContrato.idPedido,
                    valorContrato: this.listContrats.filter(item => item.value === this.formContrato.idPedido)[0].valorContrato,
                    profit: this.jogoSelecionado.percentage,
                    disponivel: this.listContrats.filter(item => item.value === this.formContrato.idPedido)[0].disponivel
                };

                http.post('/jogo/startBet', dados).then((response) => {
                    this.$router.push(`/surebet`);
                    this.$loading(false);
                },
                    error => {
                        if (error.response.data && error.response.data.message)
                            this.$snotify.error(error.response.data.message);
                        // else
                        //     this.$snotify.error("An unexpected error has occurred");
                        this.$loading(false);
                    })
            }

        },
        getContrats() {
            http.get('/requests/contratosDiponiveis').then((response) => {
                response.data.forEach(item => {
                    this.listContrats.push({
                        value: item.idPedido,
                        text: this.$i18n.t("minhas_operacoes.contrato") + " $ " + item.valorPedido + " " + this.$i18n.t("jogos." + item.descricao),
                        valorContrato: item.valorPedido,
                        disponivel: item.disponivel,
                        descricao: item.descricao
                    });
                });
               
                this.checkContracts();
            },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                    this.$loading(false);
                })
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return this.casasDecimais(valor,2).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        formatPriceReais(valor) {
            if(!valor)
                return 'R$0.00';
            
            return valor.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
          });
        },
        changeClass() {
            this.activeClass = true;
            setTimeout(() => { this.activeClass = false }, 1000);
        },
        alterarBuscaGanhos(diaSelecionado) {
            // let model = {
            //     dia: diaSelecionado
            // };

            let dia = diaSelecionado;

            if (this.dadosUsuario.diaConsultaSaldo != diaSelecionado) {
                this.$loading(true);
                http.post("/Dashboard/balances/" + dia).then((responde) => {
                    this.ganhosDias = responde.data
                    this.dadosUsuario.diaConsultaSaldo = diaSelecionado;

                    this.$loading(false);
                },
                    error => {
                        this.$loading(false);
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    });
            }

        },
        obterLucroEGanho(dia) {
            //let dia = this.dadosUsuario.diaConsultaSaldo;
            http.post("/Dashboard/balances/" + dia).then((responde) => {
                this.ganhosDias = responde.data;
                this.$loading(false);
            },
            error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        obterDadosUsuario() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/Dashboard/getBarStatus/').then((responde) => {
                    this.dadosUsuario = responde.data;
                    this.operacaoAutomatica = this.dadosUsuario.operacaoAutomatica;
                    this.diaSelecionado = this.dadosUsuario.diaConsultaSaldo;

                    this.totalUsers = this.dadosUsuario.totalPessoas;
                    this.changeClass();

                    this.obterLucroEGanho(this.dadosUsuario.diaConsultaSaldo);
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }

            const forceCountry = localStorage.getItem("force-country");
            if (forceCountry) {
                this.$bvModal.show('modal-pais');
            }
        },
        handleMessage(total) {
            this.totalUsers = total;
            this.changeClass();
        },
        obterPontosUsuario() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/user/getUserScore/').then((responde) => {
                    this.pontosUsuario = responde.data;
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            if(logado){
                this.dadoLogado = JSON.parse(logado);
                this.nomeLogin = this.dadoLogado.username.split(' ')[0];
            }
        },
        obterResumoValores() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/Dashboard/summariesValues/').then((responde) => {
                    this.resumoValores = responde.data;
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }
        },
        meuPacote() {
            http.get('/product/getMyPackage').then((response) => {
                this.userPackage = response.data;
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            })

        },
        casasDecimais(num, precisao) {
            var casas = Math.pow(10, precisao);
            return Math.floor(num * casas) / casas;
        }
    },
    beforeDestroy() {
        clearInterval(this.countClock);
    }
}