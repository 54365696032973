import http from "@/_helper/api-services";
import "./MeusDados.scss";
import SideBar from "@/components/SideBar/SideBar.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
import DadosPessoais from "@/components/DadosPessoais/DadosPessoais.vue";
import Endereco from "@/components/Endereco/Endereco.vue";
import FotoPerfil from "@/components/FotoPerfil/FotoPerfil.vue";
import SenhaFinanceira from "@/components/SenhaFinanceira/SenhaFinanceira.vue";
import Carteira from "@/components/Carteira/Carteira.vue";
import StGuard from "@/components/StGuard/StGuard.vue";
import TwoFactorAuthenticator from "@/components/TwoFactorAuthenticator/TwoFactorAuthenticator.vue";

export default {
    name: "MeusDados",
    data() {
        return {
            ativo: true,
            dadosUsuario: {},
            nomeLogin: '',
            dadosPessoais: {
                nome: String(),
                login: String(),
                celular: String(),
                repitaSenha: String(),
                senhaAntiga: String(),
                novaSenha: String(),
                novaSenhaConfirma: String(),
                documento: String(),
            },
            assinaturaEletronica: {
                assinaturaEletronica: String(),
                repitaAssinatura: String(),
                assinaturaAtual: String(),
            },
            fotoPerfil: {
                imagem: String(),
                file: String(),
            },
           
            codigo: {
                stGuard: String(),
            },
            erroForm: false,
            menuMeusDados: "dados-pessoais",
            acessoRemoto: false
        };
    },
    watch: {
        "fotoPerfil.file": function(e) {
            var reader = new FileReader();
            reader.readAsDataURL(e);
            reader.onload = () => {
                this.fotoPerfil.imagem = reader.result;
            };
        },
    },
    components: {
        SideBar,
        PremiosDashboard,
        DadosPessoais,
        Endereco,
        FotoPerfil,
        SenhaFinanceira,
        Carteira,
        StGuard,
        TwoFactorAuthenticator
    },
    mounted() {
        //this.obterNomeLogado();
        this.obterDadosUsuario();
        this.buscar2FA();
        if (this.$route.fullPath.indexOf("#wallets") !== -1) {
            this.menuMeusDados = "carteira";
        }
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        obterDadosUsuario() {
            this.$loading(true);
            http.get('/Dashboard/getBarStatus/').then((responde) => {
                this.dadosUsuario = responde.data;
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        animationRoll(which) {
            this.$scrollTo(which, 800, {});
        },
        buscarDadosPessoais() {
            this.$loading(true);
            http.get("/user/getPersonalData").then((response) => {
                this.dadosPessoais.nome = response.data.nome;
                this.dadosPessoais.email = response.data.email;
                this.dadosPessoais.celular = response.data.celular;
                this.dadosPessoais.login = response.data.login;
                this.dadosPessoais.documento = response.data.documento;
                this.codigo.stGuard = response.data.stGuard;

                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        buscar2FA() {
            this.$loading(true);
            //alert('Buscou');
            // http.get("/user/TwoFactorAuthenticator").then((response) => {
            //   this.carteiras = response.data;
            //   this.$loading(false);
            // });
        },
        salvarDados() {
            http.get("/User/checkStatus").then((response) => {
                    this.ativo = response.data;
                    this.$loading(false);

                    if (this.ativo) {
                        if (this.menuMeusDados == "dados-pessoais") this.editarUsuario();
                        else if (this.menuMeusDados == "endereco") this.editarEndereco();
                        else if (this.menuMeusDados == "foto-perfil") this.editarImagem();
                        else if (this.menuMeusDados == "senha-financeira") this.editarSenhaFinanceira();
                        else if (this.menuMeusDados == "carteira") this.salvarCarteira();
                        //else if (this.menuMeusDados == "st-guard") this.salvarChaveStGuard();
                        else if (this.menuMeusDados == "twoFactorAuthenticator") this.salvar2FA();
                    }
                },
                error => {
                    this.$snotify.error(error.response.data.message);
                });
        },
        editarUsuario() {
            if (this.checkFormDadosPessoais()) {
                this.$loading(true);

                if(this.acessoRemoto){
                    this.dadosPessoais.senhaAntiga = "";
                    this.dadosPessoais.novaSenha = "";
                    this.dadosPessoais.novaSenhaConfirma = "";
                }

                http
                    .post("/user/editUser", this.dadosPessoais)
                    .then(
                        (response) => {
                            this.$snotify.success(response.data.message);
                        },
                        (error) => {
                            if (error.response && typeof error.response.data == "object")
                                this.$snotify.error(error.response.data.message);
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        editarImagem() {
            this.$loading(true);
            http
                .post("/user/updateImage", this.fotoPerfil)
                .then(
                    (response) => {
                        this.$snotify.success(response.data.message);
                    },
                    (error) => {
                        if (error.response && typeof error.response.data == "object")
                            this.$snotify.error(error.response.data.message);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        salvarChaveStGuard() {
            this.$loading(true);

            http
                .post("/user/saveKeyStGuard", this.codigo)
                .then(
                    (response) => {
                        this.$snotify.success(response.data.message);
                    },
                    (error) => {
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.errors
                        )
                            error.response.data.errors.forEach((m) => this.$snotify.error(m));
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        checkFormDadosPessoais() {
            if (!this.dadosPessoais.nome ||
                !this.dadosPessoais.celular ||
                ((this.dadosPessoais.novaSenha ||
                        this.dadosPessoais.novaSenhaConfirma) &&
                    !this.dadosPessoais.senhaAntiga) ||
                this.dadosPessoais.novaSenha != this.dadosPessoais.novaSenhaConfirma
            ) {
                this.erroForm = true;
                this.$snotify.error(this.$t("erros.preencha_os_campos"));
                return false;
            }

            return true;
        },
        
        voltarDashboard() {
            this.$router.push("/");
        },
        salvar2FA() {
            //alert('Salvou');
        },
    },
};