import http from '@/_helper/api-services'
import axios from 'axios';
import "./DetalhesContato.scss";

export default {
    name: 'DetalhesContato',
    props: ['code', 'isAdmin'],
    data() {
        return {
            mensagens: [],
            tipoSuporte: String(),
            opcoesContato: [],
            request: {
                nome: String(),
                email: String(),
                mensagem: String(),
                login: String(),
                categoria: null,
                anexos: null
            },
            opcoesCategoria: [],
            opcoesStatus: [],
            listArquivos: [],
            dadosUser: {},
        }
    },
    async beforeMount() {
        
        // this.$loading(true);
        await this.buscarTiposContato();
        await this.buscarDados();
    },
    methods: {
        buscarTiposContato() {
            this.listArquivos = [];
            http.get('/support/getOptions').then((response) => {
                response.data.tipoList.forEach(item => {
                    this.opcoesCategoria.push({
                        text: this.$i18n.t("MotivoContato." + item.nome),
                        value: item.id,
                    })
                });

                response.data.statusList.forEach(item => {
                    this.opcoesStatus.push({
                        text: this.$i18n.t("StatusSuporte." + item.nome),
                        value: item.id,
                    })
                });
            });
        },
        buscarDados() {
            this.$loading(true);
            if (this.$props.code) {
                http.get('/support/details/' + this.$props.code)
                    .then((response) => {
                        this.mensagens = response.data.mensagens;
                        this.tipoSuporte = response.data.suporte.tipo;
                        this.request.categoria = response.data.suporte.categoria;


                        setTimeout(() => this.$refs.messenger.scrollTop = this.$refs.messenger.scrollHeight, 100);
                        setTimeout(() => this.$loading(false), 100);
                    },
                    error => {
                        if (error.response && error.response.data && error.response.data.message)
                            this.$snotify.error(error.response.data.message);

                        this.$loading(false);
                        if (this.$props.isAdmin) {
                            this.$router.push('/admin/support/tickets');
                        } else {
                            this.$router.push('/support/tickets');
                        }
                    }
                );
            }else{
                this.$loading(false);
            }
        },
        submit() {
            this.$loading(true);

            let dataForm = new FormData();
            for (let file of this.$refs.files.files) {
                dataForm.append(`anexos`, file);
            }

            let dadosUsuario = this.$store.getters["autenticacao/getLogin"].user;

            if (dadosUsuario) {
                this.request.nome = dadosUsuario.username;
                this.request.email = dadosUsuario.email;
                this.request.login = dadosUsuario.login;
                this.request.telefone = dadosUsuario.celular;
            }

            dataForm.append("nome", this.request.nome);
            dataForm.append("mensagem", this.request.mensagem);
            dataForm.append("login", this.request.login);
            dataForm.append("email", this.request.email);
            dataForm.append("categoria", this.request.categoria);
            dataForm.append("suporte", this.$props.isAdmin);

            if (this.$props.code) {
                http.post('/support/replySupport/' + this.$props.code, dataForm).then((response) => {
                    this.$snotify.success(this.$i18n.t("contato.sucesso"));
                    this.request.mensagem = "";
                    this.request.categoria = null;
                    this.buscarDados(this.$props.code);
                },
                    error => {
                        if (error.response && error.response.data && error.response.data.message)
                            this.$snotify.error(error.response.data.message);

                        this.$loading(false);
                        if (this.$props.isAdmin) {
                            this.$router.push('/admin/support/tickets');
                        } else {
                            this.$router.push('/support/tickets');
                        }
                    });
            } else {
                if (this.request.categoria) {
                    http.post('/support/submitSupport', dataForm).then((response) => {
                        this.$snotify.success(this.$i18n.t("contato.sucesso"));
                        this.request.mensagem = "";
                        this.request.categoria = null;
                       // this.buscarDados(this.$props.code);
                        this.$router.push("/support");
                    },
                        error => {
                            if (error.response && error.response.data && error.response.data.message)
                                this.$snotify.error(error.response.data.message);

                            this.$loading(false);
                        });
                } else {
                    this.$snotify.error(this.$i18n.t("erros.preencha_os_campos"));
                    this.$loading(false);
                }
            }
        },
        formatDate(date, showTime) {
            if (!showTime) return new Date(date).toLocaleDateString();
            else
                return `${new Date(date).toLocaleDateString()} ${new Date(
                    date
                ).toLocaleTimeString()}`;
        },
        responderSuporte() {
            this.$loading(true);

            if (this.request.categoria) {
                let dataForm = new FormData();
                for (let file of this.$refs.files.files) {
                    dataForm.append(`anexos`, file);
                }
            }
        },
        voltar() {
            var admin = this.$props.isAdmin;
            if (admin) {
                this.$router.push('/admin/support/tickets');
            } else {
                this.$router.push('/support/tickets');
            }
        },
        finalizar() {
            this.$loading(true);
            http.post(`/support/FinalizeSupportCode/` + this.$props.code)
                .then(
                    success => {
                        this.$snotify.success("This ticket has been closed");
                        this.buscarDados();
                    },
                    error => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                );
        },
        getFiles(idMensagem) {
            this.$loading(true);

            var mensagem = this.mensagens.find(m => m.idSuporteLog == idMensagem);

            mensagem.anexos.forEach(file => {
                var link = document.createElement("a");
                link.href = file;
                var fileName = "file";
                link.download = fileName;
                link.click();
                this.$loading(false);
                this.downloadFile(file);
            });
        },
        goBack() {           
            this.$router.go(-1);
        },
    },
}