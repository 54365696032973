import http from "@/_helper/api-services";
import "./Cursos.scss";

export default {
  name: "Cursos",
  data() {
    return {
      text: "",

      modoTeatro: true,
      curso: {
        idCurso: Number(),
        titulo: String(),
        descricao: String(),
        ativo: true,
        modulos: [],
        videos: [],
      },
    };
  },
  // create() {
  //   this.tratarLoading();
  // },
  beforeMount() {
    this.$loading(true);
    var idCurso = this.$route.params.idCurso;
    this.buscarCurso(idCurso);
  },

  methods: {
    //Função para buscar os conteúdos de um curso
    buscarCurso(id) {
      if (id == "") return false;

      this.$loading(true);
          http.get("/courses/" + id).then((response) => {
        if (response.status == 200) {

          this.curso.idCurso = response.data.idCurso;
          this.curso.titulo = response.data.titulo;
          this.curso.descricao = response.data.descricao;
          this.curso.urlVideo = response.data.urlVideo;
          this.curso.ativo = response.data.ativo;
        }

        if (!this.curso.modulos.length)
          this.curso.modulos.push({ titulo: "Vídeos" });

        this.curso.videos.push({
          titulo: this.curso.titulo,
          url: this.curso.url,
        });

        // this.tratarLoading();
        this.$loading(false);
      });
    },

    // tratarLoading() {
    //   this.buscarCurso();
    //   if (this.curso.videos == (length = 0)) {
    //     document.getElementById("divBtns").classList.add("d-none");
    //     document.getElementById("divModulos").classList.add("d-none");
    //     document.getElementById("divPlayer").classList.add("d-none");
    //     document.getElementById("desProps").classList.add("d-none");
    //     document.getElementById("opsAlert").classList.remove("d-none");
    //   }
    // },

    //Função para colocar o player em modo teatro
    setIframeHeight(width, height, modoTeatro) {
      var ifrm = document.getElementById("player");
      ifrm.width = modoTeatro ? width : 740;
      ifrm.height = modoTeatro ? height : 416;

      var divBtns = document.getElementById("divBtns");
      divBtns.setAttribute(
        "style",
        modoTeatro ? "width:1120px" : "width:740px"
      );

      this.modoTeatro = !modoTeatro;
    },
    teatro() {
      document.getElementById("divPlayer").classList.toggle("col-md-12");
      document.getElementById("divPlayer").classList.toggle("teatro");
      document.getElementById("divPlayer2").classList.toggle("teatro");

      document.getElementById("divModulos").classList.toggle("d-none");

      this.setIframeHeight(1120, 630, this.modoTeatro);

      let elmnt = document.getElementById("divPlayer");
      elmnt.scrollIntoView(false);
    },

    //Função para chamar a url do vídeo selecionado
    urlInsert(url) {
      var videoUrl = url;
      document.getElementById("player").src = videoUrl;
    },
  },

  created() {
    this.$loading(false);
  },
};

//https://player.vimeo.com/video/467848844
//https://player.vimeo.com/video/465466196
//https://player.vimeo.com/video/81766071
