import "./arquivos.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "arquivos",
    data() {
        return {
            nomeLogin: '',
            fields: [
                { key: 'titulo', label: this.$i18n.t('arquivo.titulo'), sortable: true },
                { key: 'download', label: this.$i18n.t('arquivo.link'), sortable: true, class: "thw-150" },
                { key: 'action', label: "", sortable: false, class: "thw-150" }
            ],
            listArquivos: [],
            arquivo: {
                arquivo: null,
                base64Arquivo: ''
            },
            totalRows: 0,
            perPage: 25,
            perPageOptions: [25, 50, 100],
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: "asc",
        };
    },
    components: {
    },
    beforeMount() {
        this.getFiles();
    },
    mounted() {
        //this.obterNomeLogado();
        this.$loading(false);
    },
    watch: {
        "arquivo.arquivo"(e) {
            let reader = new FileReader();
            reader.readAsDataURL(e);
            reader.onload = () => {
                this.arquivo.base64Arquivo = reader.result;
            };
        }
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        getFiles() {
            this.listArquivos = [];
            this.$loading(true);
            http.get("/files/getFiles").then(
                success => {
                    if (success && success.data) {
                        success.data.forEach(item => {
                            this.listArquivos.push({
                                titulo: item.titulo,
                                url: item.Url,
                                ativo: item.ativo,
                                download: item.url,
                                idArquivos: item.idArquivos
                            });
                        });
                        this.totalRows = success.data.length;
                    }
                }, error => {
                    if (error && error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },
        openModal() {
            this.$root.$emit('bv::hide::popover');
            this.$bvModal.show("modalCadastro");
            this.arquivo = { arquivo: null, base64Arquivo: '' };
        },
        closeModal() {
            this.$bvModal.hide('modalCadastro');
        },
        excluirArquivo(idArquivos) {
            this.$loading(true);
            http.update("/files/remove", { IdArquivos: idArquivos }).then(
                success => {
                    this.$snotify.success(success.data.message);
                },
                error => {
                    if (error && error.response && error.response.data)
                        this.$snotify.success(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
                this.getFiles();
            });
        },
        salvarArquivo(event) {
            event.preventDefault();
            this.$loading(true);

            let data = {
                titulo: this.arquivo.titulo,
                base64Arquivo: this.arquivo.base64Arquivo
            };
            http.post("/files/save", data).then(
                success => {
                    if (success && success.data) {
                        this.$snotify.success(success.data.message);
                        this.getFiles();
                        this.closeModal();
                    } else {
                        this.$snotify.error(success.data.message);
                    }
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => { this.$loading(false); });
        }
    }
}