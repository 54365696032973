import "./BoxProdutoFisico.scss";
import http from "@/_helper/api-services.js";
import { Money } from 'v-money'
import { decimal } from "vuelidate/lib/validators";
import VueCountryCode from "vue-country-code-select";

export default {
    name: "BoxProdutoFisico",
    components: {
         Money,
         VueCountryCode
        },
    data() {
        return {
            id: String(),
            code: String(),
            paymentForms: [{ text: this.$i18n.t("produtos.selecione_pagamento"), value: null }],
            paymentForm: null,
            password: String(),
            email: '',
            passwordCW: '',
            confirmPassword: String(),
            passwordMatch: true,
            blockButton: false,
            usarSaldoCompra: false,
            valorHaUtilizar: 0,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '$ ',
                precision: 2,
                masked: false
            },
            renovar: Boolean(),
            dadosEndereco: {
                rua: String(),
                numero: String(),
                bairro: String(),
                cep: String(),
                complemento: String(),
                cidade: String(),
                estado: String(),
                //pais: String()
            },
            countryCode: '',
            countryName: '',
            countryIso: '',
            filtroBusca: {
                dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                idStatus: 0
            },
            items: [],
            pedidoSelecionado: {
                produto: String(),
                data: String(),
                valor: String(),
                cotacao: Number(),
                valorTaxa: Number(),
                status: String(),
                codigoPedido: String(),
                valorBTC: Number(),
                moeda: String(),
                upgrade: false,
                renovacao: false,
                taxaRepasse: String(),
                visualizar: {
                    status: String(),
                    codigo: String(),
                    enderecoDeposito: String(),
                    urlPagamento: String()
                },
            },
            taxaRepasse: String(),
            code: String(),
            qtdIngresso: 1
        };
    },
    watch: {
        confirmPassword() {
            if (this.$data.password === this.$data.confirmPassword) this.$data.passwordMatch = true;
            else this.$data.passwordMatch = false;
        }
    },
    props: {
        product: Object(),
        krptprice: decimal
    },
    computed: {
        // nameState() {
        //     return this.valorHaUtilizar.length > 2 ? true : false
        // },
        // testeCounter(){
        //     if(this.qtdIngresso > 1)
        //       return this.product.valor += this.product.valor
        // }
    },
    beforeMount() {
        this.getPaymentsFormsForTranslation();
        if (!this.$props.paymentFormsAlreadyLoaded)
            this.getPaymentsForms(true);
            
    },
    mounted() {
        this.id = this._uid.toString();
        this.code = "code_" + this.id;
        var dadosUser = this.$store.getters['autenticacao/getLogin'].user;
        this.email = dadosUser.email;
    },
    methods: {
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        getPaymentsFormsForTranslation() {
            http.get("/purchase/getPaymentMethodsTranslation").then(success => {
                this.paymentFormsTraducao = success.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
                
            });
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        formatDecimal(value, places) {
            if (value)
                return value.toFixed(places);

            return "0.00";
        },
        openBuyConfirmation(renovar) {
            this.renovar = renovar;
            this.paymentForms= [{ text: this.$i18n.t("produtos.selecione_pagamento"), value: null }];
            this.getPaymentsForms(renovar);
            this.$bvModal.show(this.id);
            
        },
        getPaymentsForms(permitirSaldo) {
            http.get("/purchase/getPurchaseProductMethods?allowBalance=" + permitirSaldo).then(success => {
                let data = success.data;
                Object.keys(data).forEach(nameIndex => {
                if (this.$props.product.idProduto == 13 && nameIndex == 'Balance') {
                
                }else {
                    this.paymentForms.push({
                    text: this.$i18n.t("meios_pagamento." + nameIndex),
                    value: data[nameIndex],
                    });
                }
             
                });
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
                
            });
        },
        handleReset() {
            this.paymentForm = null;
            this.password = String();
            this.passwordCW = String();
            this.email = '';
            this.confirmPassword = String();
        },
        async handleSubmit() {
            if(this.checkForm()){
                if (this.paymentForm == 10) {
                    this.blockButton = false;
                    this.showModalCW();
                } else {
                    await this.comprar();
                }
            }
        },
        async handleSubmitCW() {
            if (this.email != '' && this.passwordCW != '') {
                await this.comprar();
            } else {
                //this.$snotify.error(this.$i18n.t("erros.erro_desconhecido"));
            }
        },
        showModalCW() {
            this.$bvModal.hide(this.id);
            this.$bvModal.show('CW_' + this.id);
        },
        async comprar() {
            // if (this.blockButton == true) {
            //     return false;
            // }

            // this.blockButton = true;
            // if (!this.passwordMatch) {
            //     this.blockButton = false;
            //     return false;
            // }

            if(this.paymentForm == 7)
                this.usarSaldoCompra = true;

            this.$loading(true);
            let filtro = {
                idProduto: this.$props.product.idProduto,
                //senhaEletronica: this.password,
                metodoDePagamento: this.paymentForm,
                usarSaldoCompra: this.usarSaldoCompra,
                valorHaUtilizar: this.valorHaUtilizar,
                rua: this.dadosEndereco.rua,
                numero: this.dadosEndereco.numero,
                complemento: this.dadosEndereco.complemento,
                cidade: this.dadosEndereco.rua,
                bairro: this.dadosEndereco.bairro,
                estado: this.dadosEndereco.estado,
                cep: this.dadosEndereco.cep,
                pais: this.countryIso,
                quantidade: parseInt(this.qtdIngresso)
                // renovacao: this.renovar,
                // emailCW: this.email,
                // senhaCW: this.passwordCW
            }

            await http.post("/purchase/buyProduct", filtro)
                .then(success => {
                    this.$snotify.success(this.$i18n.t("financeiro.compra_sucesso"));
                    this.buscarPedidoComprado(success.data.codigo);
                    //this.buscarPedidos(success.data.codigo);
                    this.blockButton = false;
                    this.$loading(false);
                    
                }, error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message, { bodyMaxLength: 300});
                    //this.blockButton = false;
                    this.$loading(false);
                });
        },
        onSelect({ name, iso2, dialCode }) {
            this.countryCode = dialCode;
            this.countryName = name;
            this.countryIso = iso2;
        },
        checkForm() {
            if (!this.paymentForm ||
                !this.dadosEndereco.rua ||
                !this.dadosEndereco.numero ||
                !this.dadosEndereco.bairro ||
                !this.dadosEndereco.cep ||
                !this.dadosEndereco.cidade ||
                !this.dadosEndereco.estado
            ) {
                this.erroForm = true;
                this.$snotify.error(this.$t("erros.preencha_os_campos"));
                return false;
            }

            return true;
        },
        closeModal(id) {
            this.$bvModal.hide(id);
        },
        closeModalCW(id) {
            this.$bvModal.hide('CW_' + id);
            this.$bvModal.show(id);
        },
        // buscarPedidos() {
        //     this.$loading(true);
        //     this.items = [];
        //     http.post('/requests/listOrdersProduct', this.filtroBusca).then((response) => {
        //         response.data.forEach(item => {
        //             this.items.push({
        //                 produto: item.nomeProduto,
        //                 codigoPedido: item.codigoPedido,
        //                 data: item.dataPedido,
        //                 dataPagamento: item.dataPagamento,
        //                 valor: item.valorPedido,
        //                 valorTaxa: item.valorTaxa,
        //                 cotacao: item.cotacao,
        //                 status: item.statusTransacao,
        //                 pago: item.pago,
        //                 meioPagamento: item.meioPagamento,
        //                 upgrade: item.upgrade,
        //                 renovacao: item.renovacao,
        //                 // boleto: item.urlBoleto
        //                 valorBTC: (((item.valorPedido + item.valorTaxa) * 1.002) / item.cotacao.toFixed(2)).toFixed(8),
        //                 visualizar: { status: item.statusTransacao, codigo: item.codigoPedido, enderecoDeposito: item.enderecoDeposito, urlPagamento: item.urlPagamento },
        //             });
        //         });
        //     }, error => {
        //         if (error.response && typeof error.response.data == "object")
        //             this.$snotify.error(error.response.data.message);
        //         else
        //             this.$snotify.error(this.$i18n.t("erros.erro_desconhecido"));
        //     }).finally(() => { this.$loading(false); });
        // },
        buscarPedidoComprado(codigo) {
            this.$loading(true);
            this.items = [];
            http.post('/requests/listOrdersProduct', this.filtroBusca).then((response) => {
                this.taxaRepasse = response.data.taxaRepasse;
                response.data.lstPedidos.forEach(item => {
                    this.items.push({
                        produto: item.nomeProduto,
                        codigoPedido: item.codigoPedido,
                        data: item.dataPedido,
                        dataPagamento: item.dataPagamento,
                        valor: item.valorPedido,
                        valorTaxa: item.valorTaxa,
                        cotacao: item.cotacao,
                        status: item.statusTransacao,
                        pago: item.pago,
                        meioPagamento: item.meioPagamento,
                        upgrade: item.upgrade,
                        renovacao: item.renovacao,
                        // boleto: item.urlBoleto
                        valorBTC: (((item.valorPedido + item.valorTaxa) * 1.002) / item.cotacao.toFixed(2)).toFixed(8),
                        visualizar: { status: item.statusTransacao, codigo: item.codigoPedido, enderecoDeposito: item.enderecoDeposito, urlPagamento: item.urlPagamento },
                    });

                });
                this.openModal(codigo);
                this.$bvModal.hide(this.id);
                
            }, error => {
                if (error.response && typeof error.response.data == "object")
                    this.$snotify.error(error.response.data.message);
            }).finally(() => { this.$loading(false); });
        },
        openModal(codigo) {
            this.$loading(true);
            let object = this.items.filter(f => f.codigoPedido == codigo);
            if (object) {
                var tax = 1.002;
                if (object[0].meioPagamento == 12) {
                    tax = 1;
                }
                this.pedidoSelecionado.produto = object[0].produto;
                this.pedidoSelecionado.upgrade = object[0].upgrade;
                this.pedidoSelecionado.renovacao = object[0].renovacao;
                this.pedidoSelecionado.data = object[0].data;
                this.pedidoSelecionado.valor = object[0].valor;
                this.pedidoSelecionado.moeda = this.paymentFormsTraducao.find(p => p.tipo === object[0].meioPagamento) ? this.paymentFormsTraducao.find(p => p.tipo === object[0].meioPagamento).nome : "";
                this.pedidoSelecionado.valorTaxa = object[0].valorTaxa;
                this.pedidoSelecionado.cotacao = object[0].cotacao;
                this.pedidoSelecionado.status = object[0].status;
                this.pedidoSelecionado.informacaoAdicional = object[0].informacaoAdicional;
                this.pedidoSelecionado.codigoPedido = object[0].codidoPedido;
                this.pedidoSelecionado.taxaRepasse = this.taxaRepasse;
                this.pedidoSelecionado.visualizar.status = object[0].visualizar.statusTransacao;
                this.pedidoSelecionado.visualizar.codigo = object[0].visualizar.codidoPedido;
                this.pedidoSelecionado.visualizar.idPedido = object[0].visualizar.idPedido;
                this.pedidoSelecionado.visualizar.urlPagamento = object[0].visualizar.urlPagamento;
                this.pedidoSelecionado.visualizar.enderecoDeposito = object[0].visualizar.enderecoDeposito;
                this.pedidoSelecionado.valorBTC = ((this.pedidoSelecionado.valor + this.pedidoSelecionado.valorTaxa * tax) / this.pedidoSelecionado.cotacao).toFixed(8);
                if (object[0].meioPagamento == 12) {
                    this.pedidoSelecionado.valorBTC = ((this.pedidoSelecionado.valor + this.pedidoSelecionado.valorTaxa * tax) / this.pedidoSelecionado.cotacao).toFixed(2);
                }
                this.$bvModal.show(this.code);
            }
            setInterval(() => {
                this.$loading(false);
            }, 500);
        },
        closeDetails() {
            this.$bvModal.hide('modal-pedido');
        },
        closeDetailsPurchase() {
            this.$bvModal.hide(this.code);
            this.$router.go();
        },
        // verificaFormaPagamento(object) {
        //     if (!object.urlPagamento)
        //         this.openModal(object.codigo);
        //     else
        //         window.open(object.urlPagamento, '_blank');
        // },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            }
        },
    }
}