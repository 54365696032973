import { render, staticRenderFns } from "./HistoricoTransferencia.vue?vue&type=template&id=69ebe917&"
import script from "./HistoricoTransferencia.js?vue&type=script&lang=js&"
export * from "./HistoricoTransferencia.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports