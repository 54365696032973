import DetalhesContato from '@/components/Suporte/Contato/DetalhesContato.vue';

export default {
    name: "Contato",
    components: { DetalhesContato },
    data() {
        return {
            isAdmin: true
        };
    }
}