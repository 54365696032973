import "./ingressos.scss";
import http from '@/_helper/api-services';
import listagemProdutos from "@/components/ListagemIngressos/listagemIngressos.vue";
import axios from 'axios';

export default {
    name: "ingressos",
    components: {
        listagemProdutos
    },
    data() {
        return {
            paymentForms: [],
            paymentFormsTraducao: [],
            fields: [
                { key: 'quantidade', label: this.$i18n.t("ingressos.quantidade"), sortable: true, class: 'text-center' },
                { key: 'produto', label: this.$t('ingressos.evento'), sortable: false, class: 'text-left' },
                { key: 'data', label: this.$t('minhas_compras.data'), sortable: true, class: 'text-center' },
                { key: 'valor', label: this.$i18n.t("minhas_compras.valor"), sortable: true, class: 'text-center' },
                {
                    key: 'meioPagamento',
                    label: this.$i18n.t("minhas_compras.tipo_pagamento"),
                    sortable: true,
                    class: 'text-center',
                    formatter: value => {
                        return this.paymentFormsTraducao.find(p => p.tipo === value) ? this.$t("minhas_compras." + this.paymentFormsTraducao.find(p => p.tipo === value).nome) : "";
                    }
                },
                { key: 'pago', label: this.$t('minhas_compras.status'), sortable: true, class: 'text-center' },
                { key: 'dataPagamento', label: this.$t('minhas_compras.data_pagamento'), sortable: true, class: 'text-center' },
                { key: 'visualizar', label: this.$i18n.t("minhas_compras.visualizar"), sortable: true, class: 'text-center' },
            ],
            fieldsTicket: [
                { key: 'idPedido', label: this.$i18n.t("minhas_compras.codigo"), sortable: false, class: 'text-center' },
                { key: 'quantidade', label: this.$i18n.t("ingressos.quantidade"), sortable: false, class: 'text-center' },
                { key: 'idPedidoIngresso', label: this.$i18n.t("arquivo.download"), sortable: true, class: 'text-center' }
            ],
            listDados: [],
            ticketList: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            pedidoRemover: {
                idPedido: 0,
                codigoPedido: ''
            },
            pedidoSelecionado: {
                produto: String(),
                data: String(),
                valor: String(),
                cotacao: Number(),
                valorTaxa: Number(),
                status: String(),
                codigoPedido: String(),
                valorBTC: Number(),
                moeda: String(),
                upgrade: false,
                renovacao: false,
                visualizar: {
                    status: String(),
                    codigo: String(),
                    enderecoDeposito: String(),
                    urlPagamento: String()
                }
            },
            filtroBusca: {
                dataInicio: this.dataMenos60(),
                dataFim: new Date(),
                idStatus: 0
            },
        };
    },
    async beforeMount() {
        this.$loading(true);
        await this.getPaymentsFormsForTranslation();
    },
    mounted() {
        this.buscarPedidos();
    },

    methods: {
        modalCancelarPedido(idPedido, codigoPedido) {
            this.pedidoRemover.idPedido = idPedido;
            this.pedidoRemover.codigoPedido = codigoPedido;
            this.$bvModal.show('modal-cancelar-pedido');
        },
        getFile(url) {
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const urli = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = urli;
                link.setAttribute('download', 'file.jpeg');
                document.body.appendChild(link);
                link.click();
            });
        },
        cancelarPedido() {
            this.$loading(true);
            http.delete(`/requests/cancelOrder/${this.pedidoRemover.idPedido}`).then(
                success => {
                    this.pedidoRemover.idPedido = 0;
                    this.$snotify.success("Order canceled.");
                    this.modalConfirmDelete = false;
                    this.buscarPedidos();
                    this.$bvModal.hide('modal-cancelar-pedido');
                    this.$loading(false);
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                    // else this.$snotify.error("An unexpected error has occurred");

                    this.$loading(false);
                }
            );

        },
        openModal(codigo) {
            this.$loading(true);
            let object = this.listDados.filter(f => f.codigoPedido == codigo);
            if (object) {
                this.pedidoSelecionado.produto = object[0].produto;
                this.pedidoSelecionado.upgrade = object[0].upgrade;
                this.pedidoSelecionado.renovacao = object[0].renovacao;
                this.pedidoSelecionado.data = object[0].data;
                this.pedidoSelecionado.valor = object[0].valor;
                this.pedidoSelecionado.moeda = this.paymentFormsTraducao.find(p => p.tipo === object[0].meioPagamento) ? this.paymentFormsTraducao.find(p => p.tipo === object[0].meioPagamento).nome : "";
                this.pedidoSelecionado.valorTaxa = object[0].valorTaxa;
                this.pedidoSelecionado.cotacao = object[0].cotacao;
                this.pedidoSelecionado.status = object[0].status;
                this.pedidoSelecionado.quantidade = object[0].quantidade;
                this.pedidoSelecionado.codigoPedido = object[0].codidoPedido;
                this.pedidoSelecionado.temArquivo = object[0].temArquivo;
                this.pedidoSelecionado.visualizar.status = object[0].visualizar.statusTransacao;
                this.pedidoSelecionado.visualizar.codigo = object[0].visualizar.codidoPedido;
                this.pedidoSelecionado.visualizar.urlPagamento = object[0].visualizar.urlPagamento;
                this.pedidoSelecionado.visualizar.enderecoDeposito = object[0].visualizar.enderecoDeposito;
                this.pedidoSelecionado.valorBTC = ((this.pedidoSelecionado.valor + this.pedidoSelecionado.valorTaxa * 1.002) / this.pedidoSelecionado.cotacao).toFixed(8);
            }

            this.$bvModal.show('modal-pedido');


            setInterval(() => {
                this.$loading(false);
            }, 500);
        },
        verificaFormaPagamento(object) {
            if (object.enderecoDeposito)
                this.openModal(object.codigo);
            else
                window.open(object.urlPagamento, '_blank');
        },
        async getPaymentsFormsForTranslation() {
            await http.get("/purchase/getPaymentMethodsTranslation").then(success => {
                this.paymentFormsTraducao = success.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            });
        },
        formatValor(valor) {
            if(!valor)
                return '$0,00';

            return valor.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
        buscarPedidos() {
            this.$loading(true);
            this.listDados = [];
            http.post('/requests/listTickets', this.filtroBusca).then((response) => {
                response.data.forEach(item => {
                    this.listDados.push({
                        produto: item.nomeProduto,
                        idPedido: item.idPedido,
                        codigoPedido: item.codigoPedido,
                        data: item.dataPedido,
                        dataPagamento: item.dataPagamento,
                        valor: item.valorPedido,
                        valorTaxa: item.valorTaxa,
                        cotacao: item.cotacao,
                        status: item.statusTransacao,
                        pago: item.pago,
                        meioPagamento: item.meioPagamento,
                        upgrade: item.upgrade,
                        renovacao: item.renovacao,
                        quantidade: item.quantidade,
                        temArquivo: item.temArquivo,
                        valorBTC: (((item.valorPedido + item.valorTaxa) * 1.002) / item.cotacao.toFixed(2)).toFixed(8),
                        visualizar: { status: item.statusTransacao, codigo: item.codigoPedido, enderecoDeposito: item.enderecoDeposito, urlPagamento: item.urlPagamento },

                    });
                });
            }, error => {
                if (error.response && typeof error.response.data == "object")
                    this.$snotify.error(error.response.data.message);
            }).finally(() => { this.$loading(false); });
        },
        modalCancelarPedido(idPedido, codigoPedido) {
            this.pedidoRemover.idPedido = idPedido;
            this.pedidoRemover.codigoPedido = codigoPedido;
            this.$bvModal.show('modal-cancelar-pedido');
        },
        closeDownload() {
            this.$bvModal.hide('modal-download');
        },
        modalDownload(idPedido) {
            this.$loading(true);
            this.ticketList = [];
            http.get("/requests/listTicketsPrint?idPedido=" + idPedido).then(success => {
                this.ticketList = success.data;
                this.$bvModal.show('modal-download');
                this.$loading(false);
            }, error => {
                this.$loading(false);
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            });
        },
        cancelarPedido() {
            this.$loading(true);
            http.delete(`/requests/cancelOrder/${this.pedidoRemover.idPedido}`).then(
                success => {
                    this.pedidoRemover.idPedido = 0;
                    this.$snotify.success("Order canceled.");
                    this.modalConfirmDelete = false;
                    this.buscarPedidos();
                    this.$bvModal.hide('modal-cancelar-pedido');
                    this.$loading(false);
                },
                error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                    // else this.$snotify.error("An unexpected error has occurred");

                    this.$loading(false);
                }
            );

        },
        dataMenos60() {
            var result = new Date();
            result.setDate(result.getDate() + -60);
            return result;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            }
        },
        verificaFormaPagamento(object) {
            if (object.enderecoDeposito)
                this.openModal(object.codigo);
            else
                window.open(object.urlPagamento, '_blank');
        },
        atualizarTabela() {
            this.buscarPedidos();
        }
    }
}