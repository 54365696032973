import "./gerenciarfaq.scss";
import http from "@/_helper/api-services.js";
import util from "@/_helper/util.js";
import { AlertPlugin } from "bootstrap-vue";
import boxAdcionarCategoria from '@/components/AdcionarCategoria/boxAdcionarCategoria.vue'

export default {
    name: "gerenciarfaq",
    components: {
        boxAdcionarCategoria
    },
    data() {
        return {
            opemModalAddCategoria: false,
            faq: [],
            search: {
                idCategoria: 0,
                Ativo: 0
            },
            listCategoria: [],
            listCategoriaModal: [],
            listAtivo: [],
            fields: [{ key: "pergunta", label: "Pergunta", sortable: true, sortDirection: "desc" },
            // { key: "resposta", label: "Responsta", sortable: true },
            { key: "categoria", label: "Categoria", sortable: true },
            { key: "ativo", label: "Ativo", sortable: true },
            { key: "dataCadastro", label: "Data Cadastro", sortable: true },
            { key: "opcoes", label: "Opções" }],
            list: [],
            history: [],
            totalPages: 0,
            perPage: 50,
            pageOptions: [50, 100, 150],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            faqViewModel: {
                idFaq: 0,
                pergunta: '',
                resposta: '',
                ativo: 0,
                idCategoria: 0,
                dataCadastro: new Date()
            },
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            list: [],
            todosSelecionados: false,
        }
    },
    beforeMount() {
        this.getFaqAdmin();
        this.buscarCategoriaFaq();
        this.selectAtivo();
    },
    methods: {
        fecharModal() {
            this.opemModalAddCategoria = false;
            //buscarCategoriaFaq();
        },
        removeSpecialCharacters(text) {
            return text.replace(/ /g, '').replace(/[^\w\s]/gi, '');
        },

        getFaqAdmin() {
            this.$loading(true);
            this.search.quantidade = this.perPage;
            http.post("/faq/getFaqAdmin", this.search).then(
                success => {
                    this.list = [];
                    if (success && success.data) {
                        this.totalPages = success.data.totalPages;
                        success.data.lista.forEach(d => {
                            this.list.push({
                                pergunta: d.pergunta,
                                resposta: d.resposta,
                                ativo: d.ativo,
                                dataCadastro: d.dataCadastro,
                                categoria: d.categoria,
                                idFaq: d.idFaq,
                                idCategoria: d.idCategoria,
                                dataCadastro: d.dataCadastro
                            });
                        });
                        this.totalRows = success.data.length;
                    }
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },
        selecionarTodos() {
            this.list.forEach(l => {
                l.selecionado = !this.todosSelecionados;
            });
        },
        formatDate(data) {
            return util.formatDate(data);
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
        },
        buscarCategoriaFaq() {
            this.$loading(true);
            http.get('/faq/getCategoryFaqSelect').then((response) => {
                this.listCategoria = [];
                this.listCategoria.push({
                    value: 0,
                    text: "Todos"
                })
                response.data.forEach(item => {
                    this.listCategoria.push({
                        value: item.idCategoria,
                        text: item.nome
                    });
                });
                this.$loading(false);
            });
        },

        buscarCategoriaFaqModal() {
            this.$loading(true);
            this.listCategoriaModal = [];
            http.get('/faq/getCategoryFaqSelect').then((response) => {
                response.data.forEach(item => {
                    
                    this.listCategoriaModal.push({
                        value: item.idCategoria,
                        text: item.nome
                    });
                });
                this.$loading(false);
            });
        },
        selectAtivo() {
            this.$loading(true);

            this.listAtivo.push({
                value: 0,
                text: "Todos"
            });

            this.listAtivo.push({
                value: 1,
                text: "Ativo"
            })

            this.listAtivo.push({
                value: 2,
                text: "Inativo"
            })

            this.$loading(false);

        },
        openModalEditarFaq(idFaq) {

            this.buscarCategoriaFaqModal();
            var faq = this.list.filter(f => f.idFaq == idFaq)[0];

            this.faqViewModel.pergunta = faq.pergunta;
            this.faqViewModel.resposta = faq.resposta;
            this.faqViewModel.ativo = faq.ativo;
            this.faqViewModel.idCategoria = faq.idCategoria;
            this.faqViewModel.dataCadastro = faq.dataCadastro;
            this.faqViewModel.idFaq = faq.idFaq;

            this.$bvModal.show('modal-editar-taxa');
        },
        salvarFaq() {
            this.$loading(true);
            if (this.faqViewModel.idFaq === 0) {

                http.post("/faq/addFaqAdmin", this.faqViewModel).then(
                    success => {
                        if (success.data) {
                            this.close();
                            this.getFaqAdmin();
                        }
                    },
                    error => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                ).finally(() => {
                    this.$loading(false);
                });
            }
            else {
                http.post("/faq/editFaqAdmin", this.faqViewModel).then(
                    success => {
                        if (success.data) {
                            this.close();
                            this.getFaqAdmin();
                        }
                    },
                    error => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                ).finally(() => {
                    this.$loading(false);
                });
            }

        },
        close() {
            this.$bvModal.hide('modal-editar-taxa');
        },
        abrirModaladcionarFaq() {
            this.buscarCategoriaFaqModal();
            this.$bvModal.show('modal-editar-taxa');
        }
    }
}