import http from '@/_helper/api-services';
import buscaCep from '@/_helper/busca-cep';
import './Endereco.scss'

export default {
    name: 'Endereco',
    props: ['erroForm'],
    data() {
        return {
            estadoSelecionado: null,
            dadosEndereco: {
                rua: String(),
                numero: String(),
                bairro: String(),
                cep: String(),
                complemento: String(),
                idCidade: Number(),
                cidade: String(),
                estado: String(),
                pais: String()
            }
        }
    },
    components: {
    },
    beforeMount() {
        this.buscarUsuarioEndereco();
    },
    mounted() {
        //this.buscarEstados();
        //this.setarEstado();
    },
    methods: {
        buscarUsuarioEndereco() {
            this.$loading(true);
            http.get("/userAddress/getUserAddress").then((response) => {
                if (response.data.idEndereco > 0)
                    this.dadosEndereco = response.data;

                this.$loading(false);
            });
        },
        editarEndereco() {
            if (this.checkFormEndereco()) {
                this.$loading(true);
                http
                    .update("/userAddress/editUserAddress", this.dadosEndereco)
                    .then(
                        (response) => {
                            this.$snotify.success(response.data.message);
                        },
                        (error) => {
                            if (error.response && typeof error.response.data == "object")
                                this.$snotify.error(error.response.data.message);
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        checkFormEndereco() {
            if (!this.dadosEndereco.rua ||
                !this.dadosEndereco.numero ||
                !this.dadosEndereco.bairro ||
                !this.dadosEndereco.cep ||
                !this.dadosEndereco.cidade ||
                !this.dadosEndereco.estado ||
                !this.dadosEndereco.pais
            ) {
                this.erroForm = true;
                this.$snotify.error(this.$t("erros.preencha_os_campos"));
                return false;
            }

            return true;
        },
        //buscarEstados() {
        //    http.get('/general/searchStatus').then((response) => {
        //        response.data.forEach(element => {
        //            this.estados.push({
        //                value: element.idEstado,
        //                text: element.nome,
        //                key: element.uf
        //            })
        //        });
        //    });
        //},
        //buscarCidades(setarCidade, cidade) {
        //    this.cidades = [{ value: null, text: this.$i18n.t('meus_dados.cidade') }];
        //    this.$props.dadosEndereco.idCidade = null;
        //    this.$loading(true);

        //    if (this.estadoSelecionado) {
        //        http.get('/general/searchCityByUF/' + this.estadoSelecionado).then((response) => {
        //            response.data.forEach(element => {
        //                if (setarCidade && element.nome == cidade)
        //                    this.$props.dadosEndereco.idCidade = element.idCidade;

        //                this.cidades.push({
        //                    value: element.idCidade,
        //                    text: element.nome
        //                });
        //            });
        //        }, error => {
        //            if (error.response && typeof error.response.data == "object")
        //                this.$snotify.error(error.response.data.message);
        //            else
        //                this.$snotify.error(this.$i18n.t("erros.erro_desconhecido"));
        //        }).finally(() => { this.$loading(false); });
        //    }
        //},
        //buscarCep() {
        //    if (this.dadosEndereco.cep && this.dadosEndereco.cep.length == 9) {
        //        this.$loading(true);
        //        buscaCep.get(this.$props.dadosEndereco.cep).then((response) => {
        //            if (response.data.erro) {
        //                this.$props.dadosEndereco.rua = '';
        //                this.$snotify.error(this.$i18n.t("erros.cep_nao_encontrado"));
        //                this.$props.dadosEndereco.bairro = '';
        //                this.$loading(false);
        //            } else {
        //                this.$props.dadosEndereco.rua = response.data.logradouro;
        //                this.$props.dadosEndereco.bairro = response.data.bairro;

        //                let data = this.estados.filter(f => f.key == response.data.uf);

        //                this.estadoSelecionado = data[0].value;

        //                this.buscarCidades(true, response.data.localidade);

        //                this.$loading(false);
        //            }
        //        });
        //    }
        //},
        //setarEstado() {
        //    if (this.$props.dadosEndereco.cidade) {
        //        this.estadoSelecionado = this.$props.dadosEndereco.cidade.estado.idEstado;
        //        this.setarCidadeSelecionada();
        //    }
        //},
        //setarCidadeSelecionada(setarCidade, cidade) {
        //    this.cidades = [{ value: null, text: this.$i18n.t('meus_dados.cidade') }];
        //    this.$loading(true);

        //    if (this.estadoSelecionado) {
        //        http.get('/Geral/buscaCidade/' + this.estadoSelecionado).then((response) => {
        //            response.data.forEach(element => {
        //                this.cidades.push({
        //                    value: element.idCidade,
        //                    text: element.nome
        //                });
        //            });
        //        }, error => {
        //            if (error.response && typeof error.response.data == "object")
        //                this.$snotify.error(error.response.data.message);
        //            else
        //                this.$snotify.error(this.$i18n.t("erros.erro_desconhecido"));
        //        }).finally(() => { this.$loading(false); });
        //    }
        //},
    }
}