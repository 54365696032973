import http from "@/_helper/api-services.js";
import util from "@/_helper/util.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from 'vue-multiselect'
import "./GerenciarWallets.scss";
import { Money } from 'v-money'

export default {
    name: "GerenciarWallets",
    data() {
        return {
            nomeLogin: '',
            totalRows: 1,
            currentPage: 1,
            fields: [
                { key: "time", label: "Date", sortable: true , sortDirection: "desc"},
                { key: "address", label: "Address", sortable: false },
                { key: "balance", label: "Balance", sortable: true },
                { key: "category", label: "Category", sortable: true },
                { key: "confirmations", label: "Confirmations", sortable: true },
                { key: "txId", label: "", sortable: true },
            ],
            list: [{
                time: String(),
                address: String(),
                balance: 0,
                category: String(),
                confirmations: 0,
                txId: String()
            }],
            perPage: 20,
            pageOptions: [50, 100, 150, {
                value: 99999,
                text: "show all"
            }],
            resumoWallet:{},
            form: {
                WalletAddress: String(),
                Amount: 0,
                OtpCode: String(),
                WalletPassword: String()
            },
            money: {
                decimal: '.',
                thousands: ',',
                prefix: 'BTC ',
                precision: 8,
                masked: false
            },
            otpAtivo: false,
            address: String(),
            resultWallet: 0,
            filter: null,
            filterOn: []
        };
    },
    components: {
        Money
    },
    // computed: {
    //     categorysWithProducts() {
    //         return this.categorys.filter(c => c.produtos && c.produtos.length > 0);
    //     },
    // },
    beforeMount() {
        this.walletData();
        this.verificarOTP();
        this.transactions();
    },
    mounted() {
        //this.obterNomeLogado();
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        abrirModal() {
            if(this.validate()){
                this.$bvModal.show('modalSenhaFinanceira');
            }
        },
        solicitarSaque(){
            if(this.validateWithdraw()){
                //chamada para api solicitar saque
            }
        },
        verificarOTP() {
            http.get("/User/verifyOtp").then(response => {
                this.otpAtivo = response.data;
            }, error => {
            });
        },
        walletData(){
            this.$loading(true);
            http.get("/withdraw/valueInWallet").then((response) => {
                this.resumoWallet = response.data;
                this.$loading(false);
            })
        },
        async openValidate(){
            if(this.form.WalletAddress == ''){
                this.$snotify.error("Insert a wallet");
                return false;
            }
          await this.verifyWallet(this.form.WalletAddress);
           this.abrirModal();
        },
        validate(){
        if(this.form.Amount == 0){
            this.$snotify.error("Insert a valid value");
            return false;
        }    
        if (this.form.Amount > this.resumoWallet.availableBalance) {
            this.$snotify.error(this.$i18n.t("saque.saldo_insuficiente"));
            return false
        } 
        if(this.form.WalletAddress == ''){
            this.$snotify.error("Insert a wallet");
            return false;
        }
        if(this.resultWallet == 1000){
            this.$snotify.error("Invalid wallet");
            return false;
        }
        return true;
        },
        validateWithdraw(){
            if(this.form.walletPassword == ""){
                this.$snotify.error("The password is not valid");
                return false;
            }    
            if (this.form.codigoOtp == "") {
                this.$snotify.error(this.$i18n.t("2FA is required"));
                return false
            } 
            return true;
        },
        async verifyWallet(WalletAddress){
            this.address = WalletAddress
            await http.get(`/withdraw/validateBTC/${this.address}`).then((response) => {
                this.resultWallet = response.data.result.code;
            });
        },
        sendBTC(){
            if(this.validateWithdraw()){
                http.post("/withdraw/sendBtc", this.form).then((response) => {
                    if(response.data.message){
                        this.$bvModal.hide('modalSenhaFinanceira');
                        this.$snotify.success("Transação concluída");
                    }else{
                        this.$snotify.error("Transação não concluída");
                    }
                })
            }
        },
        transactions(){
            this.$loading(true);
            http.get("/withdraw/transactionsBTC").then((response) => {
                response.data.result.transactions.forEach(item => {
                    this.list.push({
                        time: item.time,
                        address: item.address,
                        balance: item.balance,
                        category: item.category,
                        confirmations: item.confirmations,
                        txId: item.txId
                    });
                });
                this.totalRows = this.list.length;
            });
        },
        verificaFormaPagamento(object) {
            let link = "https://live.blockcypher.com/btc/tx/" + object;
            window.open(link, '_blank');
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        }
        
    },
};