import http from "@/_helper/api-services.js";
import util from "@/_helper/util.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from 'vue-multiselect'
import "./TransferSaldo.scss";
import { Money } from 'v-money'

export default {
    name: "TransferSaldo",
    data() {
        return {
            totalRows: 1,
            currentPage: 1,
            fields: [
                { key: "address", label: "Address", sortable: false },
                { key: "balance", label: "Balance", sortable: true, sortDirection: "desc" },
                { key: "category", label: "Category", sortable: true },
                { key: "confirmations", label: "Confirmations", sortable: true },
                { key: "txId", label: "Hash", sortable: true },
            ],
            list: [{
                address: String(),
                balance: 0,
                category: String(),
                confirmations: 0,
                txId: String()
            }],
            perPage: 50,
            pageOptions: [50, 100, 150, {
                value: 99999,
                text: "show all"
            }],
            resultTransfer:{},
            form: {
                Login: String(),
                Amount: 0,
                //FinancialPassword: String()
            },
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '',
                precision: 2,
                masked: false
            },
            otpAtivo: false,
            address: String(),
            resultWallet: 0,
            balance: 0,
            electronicSignature: Boolean()
        };
    },
    components: {
        Money
    },
    computed: {
        
    },
    beforeMount() {
        this.getBalance();
        this.lastTransfer();
        //this.verifyFinancialPwd();
    },
    mounted() {
    },
    methods: {
        abrirModal() {
            if(this.validate()){
                this.$bvModal.show('modalSenhaFinanceira');
            }
        },
        validate(){
        if(this.form.Amount == 0){
            this.$snotify.error(this.$i18n.t("erros.preencha_os_campos"));
            return false;
        }    
        if (this.form.Amount > this.balance) {
           this.$snotify.error(this.$i18n.t("saque.saldo_insuficiente"));
           return false;
        } 
        if(this.form.Login == ''){
            this.$snotify.error(this.$i18n.t("erros.preencha_os_campos"));
            return false;
        }
        return true;
        },
        // validateTransfer() {
        //     if (this.form.FinancialPassword == "") {
        //         this.$snotify.error(this.$i18n.t("erros.senha_nao_informado"));
        //         return false;
        //     }    
        //     return true;
        // },
        formatDate(data) {
            return util.formatDate(data);
        },
        // verifyFinancialPwd(){
        //     http.get("/user/verifyElectronicSignature").then((response) => {
        //         this.electronicSignature = response.data;
        //     },error =>{
        //         if(error.response.data){
        //             this.$snotify.error(error.response.data.message);
        //         }
        //         else{
        //             this.$snotify.error(this.$i18n.t("erros.erro_desconhecido"));
        //         }
        //     });
        // },
        lastTransfer(){
            http.get("/transfer/lastTransfer").then((response) => {
                this.resultTransfer = response.data;
            },error =>{
                if(error.response.data){
                    this.$snotify.error(error.response.data.message);
                }
            });
        },
        getBalance(){
            http.get("/transfer/getSolarCreditBalance").then((response) => {
                this.balance = response.data;
            },error =>{
                if(error.response.data){
                    this.$snotify.error(error.response.data.message);
                }
            });
        },
        sendBalance(){
                this.$loading(true);
                http.post("/transfer/transferBalance", this.form).then((response) => {
                    this.$bvModal.hide('modalSenhaFinanceira');
                    this.$snotify.success(response.data.message);
                    this.clearFields();
                    this.lastTransfer();
                }, error => {
                    if(error.response.data){
                        this.$snotify.error(error.response.data.message);
                    }
                }).finally(() => { this.$loading(false); });
        },
        clearFields(){
            this.form.Login = "";
            this.form.Amount = 0;
        }
        
    }
};