import http from "@/_helper/api-services.js";
import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";
import HistoricoTransferencia from "@/components/HistoricoTransferencia/HistoricoTransferencia.vue";
import { Money } from 'v-money'

export default {
    name: "Transferencia",
    data() {
        return {
            dadosUsuario: {},
            resumoSaldo: [
                { saldo: String() },
                { saldo: String() }
            ],
            form: {
                login: String(),
                valor: null,
                idTipo: 0,
                senhaEletronica: String(),
                confirmarSenhaEletronica: String()
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '$ ',
                precision: 2,
                masked: false
            },
            tiposSaque: [{ text: this.$i18n.t("tipos.Tipo de Saldo"), value: 0 }],
            tipoSaqueSelecionado: String(),
            historicoAtualizado: true,
            valorMinTransf: Number(),
            resumoValores: {},
        };
    },
    components: {
        LinkPatrocinador,
        HistoricoTransferencia,
    },
    watch: {

    },
    beforeMount() {
        this.obterSaldos();
        this.$loading(false);
        this.getTiposDeSaldo();
        this.obterValorMinimoTransf();
        this.obterResumoValores();
        this.obterDadosUsuario();
    },
    methods: {
        obterDadosUsuario() {
            this.$loading(true);
            http.get('/Dashboard/getBarStatus/').then((responde) => {
                this.dadosUsuario = responde.data;
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        getTiposDeSaldo() {
            http.get("/withdraw/listWithdrawalTypes").then(success => {
                if (success.data) {
                    success.data.forEach(item => {
                        this.tiposSaque.push({
                            text: this.$i18n.t("tipos." + item.descricao),
                            value: item.idTipo
                        });
                    });
                }
            }, error => {
            });
        },
        obterResumoValores(){
            this.$loading(true);
            http.get('/Dashboard/summariesValues/').then((responde) => {
                this.resumoValores = responde.data;
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        obterSaldos() {
            http.get("/extract/summaryBalance").then(response => {
                this.resumoSaldo = response.data;
            }, error => {
            });
        },
        obterValorMinimoTransf() {
            http.get("/transfer/getMinimumTransferValue").then(response => {
                this.valorMinTransf = response.data;
            }, error => {
            });
        },
        solicitarTransferencia() {
            this.$loading(true);

            this.form.valor = parseFloat(this.form.valor);

            http.post("/transfer/requestTransfer", this.form).then(response => {
                if (response.data.status) {
                    this.$snotify.success(response.data.message);
                    this.fecharModal();
                    this.obterSaldos();

                    this.historicoAtualizado = !this.historicoAtualizado;

                    this.form.valor = null;
                    this.form.login = null;
                    this.form.idTipo = null;
                }
            }, error => {
                this.$snotify.error(error.response.data);
            }).finally(() => {
                this.$loading(false);
            });;
        },
        abrirModal() {
            this.$bvModal.show('modalSenhaFinanceira');
        },
        buscarTipoSelecionado(idTipo) {
            this.tipoSaqueSelecionado = this.tiposSaque.filter(f => f.value == idTipo)[0].text;;
        },
        formatValor(valor) {
            if(!valor)
                return '$0,00';

            return valor.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
        fecharModal() {
            this.form.confirmarSenhaEletronica = '';
            this.form.senhaEletronica = '';

            this.$bvModal.hide('modalSenhaFinanceira');
        }
    }
}