import "./historicoSaque.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "historicoSaque",
    data() {
        return {
            fields: [
                //{ key: "tipoSaque", label: this.$i18n.t("transferencia.tipo_saldo"), sortable: true, },
                { key: "dataSolicitacao", label: this.$i18n.t("transferencia.data_solicitacao"), sortable: true, },
                { key: "status", label: this.$i18n.t("transferencia.status"), sortable: true },
                /*{ key: "tipo", label: this.$i18n.t("financeiro.tipo"), sortable: true },*/
                { key: "valor", label: this.$i18n.t("financeiro.valor"), sortable: true },
                { key: "taxaSaque", label: this.$i18n.t("financeiro.taxa_saque"), sortable: true },
                { key: "porcentagemSaque", label: this.$i18n.t("financeiro.taxa_porcentagem"), 
                formatter: (value) => {
                    return `${value.toFixed(2)}%`;
                }, sortable: true },
                { key: "dataProcessado", label: this.$i18n.t("financeiro.data_processado"), sortable: true },
                { key: "recibo", label: this.$i18n.t("financeiro.recibo"), sortable: true },
                { key: "acoes", label: this.$i18n.t("generico.acoes"), sortable: false },
            ],
            history: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            recibo: {
                valor: 0,
                valorBTC: 0
            },
            filter: null,
            filterOn: [],
        };
    },
    props: ['atualizado'],
    watch: {
        atualizado(el) {
            this.getWithdrawalHistory();
        }
    },
    beforeMount() {
        this.getWithdrawalHistory();
    },
    methods: {
        getWithdrawalHistory() {
            this.$loading(true);

            http.get("/withdraw/withdrawalsRequested").then(success => {
                this.history = [];

                if (success.data) {
                    success.data.forEach(d => {
                        this.history.push({
                            idSaque: d.idSaque,
                            dataSolicitacao: d.dataSolicitacao,
                            status: d.status,
                            tipo: d.tipo,
                            valor: d.valor,
                            dataProcessado: d.dataProcessado,
                            valorBTC: d.valorBTC,
                            cotacao: d.cotacao,
                            urlTransacao: d.urlTransacao,
                            enderecoBTC: d.enderecoBTC,
                            tipoSaque: d.tipoSaque,
                            taxaSaque: d.taxaSaque,
                            porcentagemSaque: d.porcentagemSaque
                        });
                    });
                }
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                this.$loading(false);
            });
        },
        async cancelarSolicitacao(idSolicitacao) {
            this.$loading(true);
            http.post("/withdraw/cancelWithdrawal/" + idSolicitacao).then(success => {
                this.history = [];
                if (success.data) {
                    this.$snotify.success(this.$i18n.t("saque.acao_completada"), { position: "rightTop" });
                    window.location.reload();
                }
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                this.$loading(false);
            });
        },
        verRecibo(object) {
            this.recibo = {};
            if (object) {
                this.recibo = object;
            }
            this.$bvModal.show('modal-recibo');
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            }
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        verificaFormaPagamento(urlTransacao) {
            window.open('https://www.blockchain.com/explorer/transactions/btc/' + urlTransacao, '_blank');
        }
    }
}