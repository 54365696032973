import http from '@/_helper/api-services';
import './ParceiroDireto.scss';
import SideBar from "@/components/SideBar/SideBar.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';

export default {
    name: 'ParceiroDireto',
    data() {
        return {
            dadosUser: this.$store.getters['autenticacao/getLogin'].user,
            fields: [{ key: 'nome', label: this.$i18n.t('cadastro.nome'), sortable: true, sortDirection: 'desc' },
            { key: 'login', label: this.$i18n.t('cadastro.login'), sortable: true, class: 'text-center' },
            { key: "pais", label: this.$i18n.t('cadastro.pais'), sortable: true, class: 'text-left title-color ' },
            { key: 'graduacao', label: this.$i18n.t('cadastro.graduacao'), sortable: true, class: 'text-center' },
            { key: 'temProduto', label: this.$i18n.t('minhas_compras.status'), sortable: true, class: 'text-center' },
            { key: 'telefone', label: this.$i18n.t('cadastro.celular'), sortable: true, class: 'text-center' },
       /*     { key: 'posicao', label: this.$i18n.t('cadastro.binario_lado'), sortable: true, class: 'text-center' },*/
            { key: 'pacoteAtual', label: this.$i18n.t('cadastro.pacote_atual'), sortable: true, class: 'text-center' }],
            listDados: [],
            dadosUsuario: {},
            nomeLogin: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            networkData: {}
        }
    },
    components: {
        SideBar,
        PremiosDashboard,
    },
    beforeMount() {
        this.obterDadosUsuario();
    },
    mounted() {
        //this.obterNomeLogado();
        this.$loading(true);
        this.listarDados();
        this.dadosRede();
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    methods: {
        dadosRede(){
            http.get('/network/getNetworkData').then((response) => {
                this.networkData = response.data;
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            })
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        obterDadosUsuario() {
            this.$loading(true);
            http.get('/Dashboard/getBarStatus/').then((responde) => {
                this.dadosUsuario = responde.data;
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        listarDados(){ 
            this.$loading(true);           
            http.get('/user/directUserLogin').then((responde) => {
                responde.data.forEach(item => {
                    this.listDados.push({
                        nome: item.nome,
                        login: item.login,
                        pais: item.pais.nome,
                        paisIso: item.pais.iso,
                        graduacao: (item.graduacao != null) ? item.graduacao.nome : '',
                        telefone: this.formatTelefone(item.celular),
                        email: item.email,
                        temProduto: item.temProdutoAtivo,
                        pacoteAtual: item.nomeProduto,
                        posicao: item.posicaoBinarioPai
                    })
                });
                this.totalRows = this.listDados.length;                
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        formatTelefone(valor) {
            valor = valor.replace(/\D/g, "");
            valor = valor.replace(/^(\d{2})(\d)/g, "($1) $2");
            valor = valor.replace(/(\d)(\d{4})$/, "$1-$2");
            return valor;
        }
    }
}