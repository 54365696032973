import "./index.scss";
import Drawer from "vue-simple-drawer";
import http from '@/_helper/api-services';
import ListaIdiomaSite from '@/components/ListaIdiomaSite/ListaIdioma.vue';

export default {
    name: "index",
    data() {
        return {
            open: false,
            innerOpen: false,
            align: "left",
            closeable: true,
            mask: false,
            maskClosable: true,
        };
    },
    components: {
        Drawer,
        ListaIdiomaSite
    },
    async beforeMount() {
        this.$emit("hideHeaderFooter");
        // window.location.href = 'home.html';
        //this.obterDadosCotacao();
        
    },
    mounted() {
        //this.$loading(false);
        this.$emit("hideHeaderFooter");
    },
    methods: {
        animationRoll(which) {
            this.$scrollTo(which, 800, {});
        },
        toggle() {
            this.open = !this.open
        },
        closeDrop() {
            this.open = false;
        }
    }
}