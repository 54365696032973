import http from '@/_helper/api-services';
import './DadosPessoais.scss'
import { VueTelInput } from "vue-tel-input";

export default {
    name: "DadosPessoais",
    props: ["dadosPessoais", "erroForm"],
    data() {
        return {
            qrCodeImageUrl: '',
            secret: '',
            otpcall: 0,
            twoFactorCode: '',
            twoFactorAuthEnabled: false,
            twoFactorLoaded: false,
            acessoRemoto: false,
            // dadosPessoais: {
            //     nome: String(),
            //     login: String(),
            //     celular: String(),
            //     repitaSenha: String(),
            //     senhaAntiga: String(),
            //     novaSenha: String(),
            //     novaSenhaConfirma: String(),
            //     documento: String(),
            // },
        };
    },
    components: {
        VueTelInput,
    },
    mounted() {
        this.getDadosPessoais();
        this.buscarDadosPessoais();
        this.getOTP();
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
    },
    methods: {
        numbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            /*
             * 45 = -
             * 32 = SPACE
             * 43 = +
             * 40 = (
             * 41 = )
             */
            if (charCode == 45 || charCode == 32 || charCode == 43 || charCode == 40 || charCode == 41 || (charCode >= 48 && charCode <= 57)) {
                if (this.phone.length > 18)
                    evt.preventDefault();;

                return true;
            } else {
                evt.preventDefault();;
            }
        },
        buscarDadosPessoais() {
            this.$loading(true);
            http.get("/user/getPersonalData").then((response) => {
                this.dadosPessoais.nome = response.data.nome;
                this.dadosPessoais.email = response.data.email;
                this.dadosPessoais.celular = response.data.celular;
                this.dadosPessoais.login = response.data.login;
                this.dadosPessoais.documento = response.data.documento;
                // this.codigo.stGuard = response.data.stGuard;

                this.$loading(false);
            });
        },
        editarUsuario() {
            if (this.checkFormDadosPessoais()) {
                this.$loading(true);

                if (this.acessoRemoto) {
                    this.dadosPessoais.senhaAntiga = "";
                    this.dadosPessoais.novaSenha = "";
                    this.dadosPessoais.novaSenhaConfirma = "";
                }

                http
                    .post("/user/editUser", this.dadosPessoais)
                    .then(
                        (response) => {
                            this.$snotify.success(response.data.message);
                        },
                        (error) => {
                            if (error.response && typeof error.response.data == "object")
                                this.$snotify.error(error.response.data.message);
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        checkFormDadosPessoais() {
            if (!this.dadosPessoais.nome ||
                !this.dadosPessoais.celular ||
                ((this.dadosPessoais.novaSenha ||
                    this.dadosPessoais.novaSenhaConfirma) &&
                    !this.dadosPessoais.senhaAntiga) ||
                this.dadosPessoais.novaSenha != this.dadosPessoais.novaSenhaConfirma
            ) {
                this.erroForm = true;
                this.$snotify.error(this.$t("erros.preencha_os_campos"));
                return false;
            }

            return true;
        },
        getDadosPessoais() {
            this.$loading(false);
        },
        getOTP() {
            this.$loading(true);
            this.otpcall += 1;
            http.get('/user/OTP/').then((response) => {
                this.qrCodeImageUrl = response.data.qrCodeImageUrl;
                this.secret = response.data.manualEntrySetupCode;
                this.twoFactorAuthEnabled = response.data.twoFactorAuthEnabled;
                this.twoFactorLoaded = true;
                this.otpcall = 0;
            },
                error => {
                    if (this.otpcall < 2) {
                        this.getOTP();
                    } 
                });

            this.$loading(false);
        },
        openModalCancel() {
            this.$bvModal.show('modal-2FA-Disable');
        },
        async enable2FA() {
            this.$loading(true);

            let body = {
                code: this.twoFactorCode
            }

            await http.update("/user/otp", body)
                .then(success => {
                    this.$snotify.success(this.$i18n.t("meus_dados.TwoFactorAuthEnabled"));
                    this.twoFactorAuthEnabled = true;
                    this.cancel();
                }, error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                });

            this.reset();
            this.$loading(false);
        },
        async disable2FA() {
            this.$loading(true);

            let body = {
                code: this.twoFactorCode
            }

            await http.update("/user/disableotp", body)
                .then(success => {
                    this.$snotify.success(this.$i18n.t("meus_dados.TwoFactorAuthDisabled"));
                    this.twoFactorAuthEnabled = false;
                    this.cancel();
                }, error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                });

            this.reset();
            this.$loading(false);
        },
        cancel() {
            this.reset();

            this.$refs['modal-2FA-Enable'].hide();
            this.$refs['modal-2FA-Disable'].hide();
        },
        reset() {
            this.twoFactorCode = '';
        }
    },
};