import './Login.scss'
import formLogin from '@/components/FormLogin/formLogin.vue';
import ListaIdiomaSite from '@/components/ListaIdiomaSite/ListaIdioma.vue';
import loginSocial from '@/components/LoginSocial/loginSocial.vue';
import appFooter from "@/components/AppFooter/appFooter.vue";

export default {
    data() {
        return {
            year: new Date(Date.now()).getFullYear(),
            leftPanelPosition: "0px",
            rightPanelPosition: "0px",
            action: 'login',
            animationFunction: {
                register: () => {
                    this.$data.action = "register";
                    this.$data.leftPanelPosition = `${this.$refs.rightPanel.clientWidth}px`;
                    this.$data.rightPanelPosition = `${this.$refs.leftPanel.clientWidth}px`;
                    this.readjustPosition();
                },
                login: () => {
                    this.$data.action = "login";
                    this.$data.leftPanelPosition = "0px";
                    this.$data.rightPanelPosition = "0px";
                    this.readjustPosition();
                },
                resetPassword: () => {
                    this.$data.action = "resetPassword";
                    this.$data.leftPanelPosition = `${this.$refs.rightPanel.clientWidth}px`;
                    this.$data.rightPanelPosition = `${this.$refs.leftPanel.clientWidth}px`;
                    this.readjustPosition();
                }
            },
            sponsor: String()
        }
    },
    beforeMount() {
        window.addEventListener('resize', this.readjustPosition);
        this.$emit("hideHeaderFooter");
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.readjustPosition);
    },
    components: {
        formLogin,
        ListaIdiomaSite,
        loginSocial,
        appFooter
    },
    computed: {
        loggingIn() {
            return this.$store.state.autenticacao.status.loggingIn;
        }
    },
    mounted() {
        this.$loading(false);
        if(this.$route.params.sponsor){
            this.switchPanel("register");
            this.sponsor = this.$route.params.sponsor;
        }
    },
    methods: {
        switchPanel(to) {
            let whatToDo = this.animationFunction[to];
            whatToDo();
        },
        readjustPosition() {
            setTimeout(() => { this.switchPanel(this.action) }, 450);
        }
    }
};