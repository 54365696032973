import "./BarraProgresso.scss";
import http from '@/_helper/api-services';

export default {
    name: 'BarraProgresso',
    props: ['dadosBarraProgresso'],
    data() {
        return {
            value: 45,
            max: 300,
            dadosUser: {},

            series: [],
            chartOptions: {
                chart: {
                    height: 200,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: '95%',
                            /*background: '#000',*/
                        },

                        track: {
                            background: "#F6F6F6",
                            strokeWidth: '100%',
                            margin: -18,
                        },

                        dataLabels: {
                            name: {
                                show: true,
                                fontSize: '12px',
                                fontWeight: '400',
                                color: '#aaa',
                                offsetY: 0
                            },

                            value: {
                                offsetY: -10,
                                fontSize: '18px',
                                fontWeight: 'bold',
                                fontStyle: 'normal',
                                color: '#000'
                            }
                        }
                    }
                },
                fill: {
                    colors: '#902A2F',
                    opacity: 1,
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.1,
                        gradientToColors: ['#902A2F'],
                        /*gradientToColors: ['#442d73'],*/
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100]
                    }
                },
                // stroke: {
                //     dashArray: 6
                // },
                labels: [""],
            },
            investiment: {}
        }
    },
    beforeMount() {
        this.dadosUser = this.$store.getters['autenticacao/getLogin'].user;
        this.totalInvestiment();
    },
    methods: {
        totalInvestiment(){
            http.get("/Arbitragem/getBalance").then((response) => {
                this.investiment = response.data;
            }, (error) =>{
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                )
                    error.response.data.errors.forEach((m) =>
                        this.$snotify.error(m)
                    );
            })
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        fixedFloat(value, places) {
            if (value)
                return value.toFixed(places);

            return '0.00'
        }
    }
}