import http from '@/_helper/api-services'
import './Arbitragem.scss'
import SideBar from "@/components/SideBar/SideBar.vue";
import { Money } from 'v-money';

const SegundosParaAtualizarQuotes = 30;

export default {
    name: 'Arbitragem',
    data() {
        return {
            acessoRemoto:false,
            dadosUsuario: {},
            nomeLogin: '',
            cotacoes: [],
            saldoDisponivel: 0,
            sugeridoMax: {},
            sugeridoMin: {},
            operating_quantity: '',
            isActive: false,
            isModalManual: false,
            temOperacao: false,
            operacaoAutomatica: false,
            manual: {
                buy: {},
                sell: {},
                valorOperacao: 0,
                tipoArbitragem: 'Manual'
            },
            suggestion: {
                valorOperacao: 0,
                tipoArbitragem: 'Sugerida'
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'USDT$ ',
                precision: 2,
                masked: false
            },
            fields: [
                { key: 'campeonato', label: this.$t('financeiro.campeonato'), sortable: true, class: 'text-center' },
                { key: 'jogo', label: this.$t('financeiro.jogo'), sortable: true, class: 'text-center' },
                // {
                //     key: 'tipoArbitragem', label: this.$t('financeiro.tipo_arbitragem'), sortable: true, class: 'text-left',
                //     formatter: value => {
                //         return this.$t("financeiro." + value);
                //     }
                // },
                { key: 'valorOperacao', label: "Stake", sortable: true, class: 'text-center' },
                { key: 'lucro', label: this.$t('financeiro.lucro'), sortable: true, class: 'text-center' },
                { key: 'lucroPorcentagem', label: this.$t('financeiro.lucro_porcentagem'), sortable: true, class: 'text-center' },
                { key: 'dataOperacao', label: this.$t('financeiro.data_operacao'), sortable: true, class: 'text-center' },
                {
                    key: 'status', label: this.$t('financeiro.status'), sortable: true, class: 'text-center',
                    formatter: value => {
                        return this.$t("status." + value);
                    }
                }
                // { key: 'hash', label: this.$t('financeiro.hash'), sortable: true, class: 'text-center' }
            ],
            items: [],
            percentualSugerido: 0,
            automaticArbitrage: Boolean(),
            totalPaginas:0,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            renewalBotAuto: false,
            intervalId: null,
            isBot: Boolean(),
            selectedBot:{
                id: null,
                name: ''
            },
            activeBot: {
                conservador: false,
                moderado: false,
                agressivo: false
            }
        }
    },
    components: {
        SideBar,
        Money
    },
    computed: {
        nameState() {
            return this.manual.valorOperacao > 2 ? true : false
        },
        percentualManual() {
            if (this.manual.sell.lastValue && this.manual.buy.lastValue) {
                let valorPercentualManual = ((this.manual.buy.lastValue * 100) / this.manual.sell.lastValue).toFixed(2);
                return (100 - valorPercentualManual).toFixed(2);
            }
            else {
                return 0;
            }
        }
        
    },
    beforeMount() {
        //this.getBalance();
        //this.getQuotes();
        this.buscarArbitragens();
        this.verifyActivateBot();
        //this.obterDadosUsuario();
        //this.obterResumoValores();
        //this.userAutomaticArbitrage();
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
    },
    updated() {
        //this.manualPorcentagem();
    },
    mounted() {
        ///this.obterNomeLogado();
        //this.verifyRenewalBotAuto();
        //this.startBackgroundTask();
    },
    methods: {
        ativarBot(){
            http.post("/Arbitragem/activeBot").then(
                (response) => {
                    this.$snotify.success(response.data);
                    this.verifyActivateBot();
                },
                (error) =>{
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            );
        },
        checkUpdateOp(){
            if(this.operating_quantity == ''){
                this.$snotify.error(this.$t("erros.preencha_os_campos"));
                return false;
            }
           
            return true;
            
        },
        updateBot(){
           if(this.checkUpdateOp()){
            
            let updateData = {
                TipoBot: this.selectedBot.id,
                QtdOperacoes: parseInt(this.operating_quantity)
            };

               http.post('/Arbitragem/updateBot', updateData).then(
                   (response) => {
                       this.$snotify.success(response.data);
                       this.verifyActivateBot();
                       this.$bvModal.hide('modal-1');
                   },
                   
                error => {
                    if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
                });
           }
        },
        tipoBot(number,tipo){
            this.selectedBot = {}
            this.selectedBot.id = number;
            this.selectedBot.name = tipo;
            this.operating_quantity = '';
            this.$bvModal.show('modal-1');
        },

        verifyActivateBot(){
            this.activeBot = {
                conservador: false,
                moderado: false,
                agressivo: false
            };

            http.get("/Arbitragem/verifyActivateBot").then(
                (response) => {
                    this.isBot = response.data;
                    switch(response.data.tipoBot){
                        case 1:
                            this.activeBot.conservador = true;
                            break;
                        case 2:
                            this.activeBot.moderado = true;
                            break;
                        case 3:
                            this.activeBot.agressivo = true;
                            break;
                    };
                },
                (error) =>{
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.errors
                      )
                        error.response.data.errors.forEach((e) => {
                          this.$snotify.error(e);
                        });
                    //   else this.$snotify.error("An unexpected error has occurred");
                }
            );
        },
        startBackgroundTask() {
            // Seta de quanto em quanto tempo vai atualizar as BTC Quotes
            this.intervalId = setInterval(this.backgroundTask, SegundosParaAtualizarQuotes * 1000);
        },
        backgroundTask() {
            this.getQuotes();
        },
        verifyRenewalBotAuto() {
            http.get("/Arbitragem/verifyRenewalBotAuto").then(
              (response) => {
                this.renewalBotAuto = response.data;
              },
              (error) => {
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.errors
                )
                  error.response.data.errors.forEach((e) => {
                    this.$snotify.error(e);
                  });
                // else this.$snotify.error("An unexpected error has occurred");
              }
            );
          },
          modifyRenewalBotAuto(e) {
            this.$loading(true);
            http
              .post("/Arbitragem/modifyRenewalBotAuto/" + e)
              .then(
                (response) => {
                  this.$snotify.success(response.data.message);
                },
                (error) => {
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                  )
                    error.response.data.errors.forEach((e) => {
                      this.$snotify.error(e);
                    });
                //   else this.$snotify.error("An unexpected error has occurred");
                }
              )
              .finally(() => {
                this.$loading(false);
              });
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        closeCancel() {
            this.$bvModal.hide('modal-operation');
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        obterDadosUsuario() {
            http.get('/Dashboard/obterBarraStatus/').then((responde) => {
                this.dadosUsuario = responde.data;
            });
        },
        // obterResumoValores() {
        //         http.get('/Dashboard/resumoSaldo').then((responde) => {
        //             this.saldoDisponivel = responde.data.saldoDisponivel.toFixed(2);
        //         });
        // },
        buscarArbitragens() {
            this.items = [];
            http.get('/Arbitragem/getArbitration').then((response) => {
                response.data.forEach(item => {
                    this.items.push({
                        tipoArbitragem: item.tipoArbitragem,
                        dataOperacao: item.dataOperacao,
                        valorOperacao: item.valorOperacao.toFixed(2),
                        lucro: item.lucro.toFixed(2),
                        lucroPorcentagem: item.lucroPorcentagem.toFixed(2) + '%',
                        status: item.status,
                        hash: item.hash,
                        campeonato: item.sportTitle,
                        timeCasa: item.homeTeam,
                        visitante: item.awayTeam,
                        placarTimeCasa: item.homeTeamScore,
                        placarVisitante: item.awayTeamScore,
                        jogo: item.homeTeam + ' ' + item.homeTeamScore + ' x ' + item.awayTeamScore + ' ' + item.awayTeam
                    });

                this.totalRows = this.items.length;

                });

                if (this.items.length == 0) {
                    this.temOperacao = false;
                }
                else {
                    this.temOperacao = true;
                }
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            });
        },
        getBalance() {
            http.get('/Arbitragem/getBalance').then((response) => {
                this.saldoDisponivel = response.data.toFixed(2);
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            })
        },
        getQuotes() {
            this.$loading(true);
            http.get('/Arbitragem/getQuotes').then((response) => {
                this.cotacoes = [];
                response.data.lista.forEach(item => {
                    this.cotacoes.push({
                        code: item.code,
                        name: item.name,
                        lastValue: item.lastValue
                    })
                });
                this.sugeridoMax = response.data.maximo;
                this.sugeridoMin = response.data.minimo;
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });
        },
        manualPorcentagem() {
            let valorPercentual = ((this.sugeridoMin.lastValue * 100) / this.sugeridoMax.lastValue).toFixed(2);
            this.percentualSugerido = (100 - valorPercentual).toFixed(2);
        },
        buy(buy) {
            this.manual.buy = buy;
        },
        sell(sell) {
            this.manual.sell = sell;
        },
        userAutomaticArbitrage() {
            this.$loading(true);
            http.get('/Arbitragem/find-automatic-arbitrage').then((response) => {
                this.automaticArbitrage = response.data;
                if (this.automaticArbitrage == true) {
                    this.operacaoAutomatica = true;
                }
                else {
                    this.operacaoAutomatica = false;
                }
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });;
        },
        activateAutomaticArbitrage() {
            this.$loading(true);
            http.post('/Arbitragem/activate-automatic-arbitrage').then((response) => {
                this.operacaoAutomatica = true;
                this.$snotify.success(response.data.message);
                this.$router.go();
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });;
        },
        disableAutomaticArbitrage() {
            this.$loading(true);
            http.post('/Arbitragem/disable-automatic-arbitrage').then((response) => {
                this.operacaoAutomatica = false;
                this.$snotify.success(response.data.message);
                this.$router.go();
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });;
        },
        manualArbitrage() {
            //Remove o auto update dos BTC Quotes pra n ficar fazendo update durante o request
            clearInterval(this.intervalId);

            this.fecharModal();
            this.$loading(true);
            let dados = {
                valorCompra: this.manual.buy.lastValue,
                exchangeDE: this.manual.buy.name,
                valorVenda: this.manual.sell.lastValue,
                exchangePARA: this.manual.sell.name,
                valorOperacao: this.manual.valorOperacao,
                tipoArbitragem: this.manual.tipoArbitragem
            }

            http.post("/Arbitragem/arbitrage", dados).then(response => {
                this.$router.go();
                this.$snotify.success(this.$i18n.t("financeiro.arbitragem_sucesso"));
            }, error => {
                if (error.response && error.response.data && error.response.data.message){
                    this.getQuotes();
                    this.$snotify.error(error.response.data.message);
                }
            }).finally(() => {
                this.$loading(false);

                //Insere novamente o auto update do BTC Quotes
                this.startBackgroundTask();
            });;
        },
        suggestionArbitrage() {
            //Remove o auto update dos BTC Quotes pra n ficar fazendo update durante o request
            clearInterval(this.intervalId);

            this.fecharModal();
            this.$loading(true);
            let dados = {
                valorCompra: this.sugeridoMin.lastValue,
                exchangeDE: this.sugeridoMin.name,
                valorVenda: this.sugeridoMax.lastValue,
                exchangePARA: this.sugeridoMax.name,
                valorOperacao: this.suggestion.valorOperacao,
                tipoArbitragem: this.suggestion.tipoArbitragem
            }

            http.post("/Arbitragem/arbitrage", dados).then(response => {
                this.$router.go();
                this.$snotify.success(this.$i18n.t("financeiro.arbitragem_sucesso"));
            }, error => {
                if (error.response && error.response.data && error.response.data.message){
                    this.getQuotes();
                    this.$snotify.error(error.response.data.message);
                }
            }).finally(() => {
                this.$loading(false);

                //Insere novamente o auto update do BTC Quotes
                this.startBackgroundTask();
            });
        },
        checkFormManual() {
            if (!this.manual.buy ||
                !this.manual.sell ||
                this.manual.valorOperacao <= 0
            ) {

                this.$snotify.error(this.$t("erros.preencha_os_campos"));
                return false;
            }

            if (this.manual.valorOperacao > this.saldoDisponivel) {
                this.$snotify.error(this.$t("erros.saldo_insuficiente"));
                return false;
            }

            return true;
        },
        checkFormSuggestion() {
            if (this.suggestion.valorOperacao <= 0) {
                this.$snotify.error(this.$t("erros.preencha_os_campos"));
                return false;
            }

            if (this.suggestion.valorOperacao > this.saldoDisponivel) {
                this.$snotify.error(this.$t("erros.saldo_insuficiente"));
                return false;
            }

            return true;
        },
        abrirModalManual() {
            if (this.checkFormManual()) {
                this.isModalManual = true;
                this.$bvModal.show('modalConfirmation');
            }
        },
        abrirModalSugestao() {
            if (this.checkFormSuggestion()) {
                this.isModalManual = false;
                this.$bvModal.show('modalConfirmation');
            }
        },
        fecharModal() {
            this.$bvModal.hide('modalConfirmation');
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            }
        },
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
      },
}