import "./ListagemProdutosFisicos.scss";
import BoxProdutoFisico from "@/components/BoxProdutoFisico/BoxProdutoFisico.vue";
import http from "@/_helper/api-services.js";
import carousel from "vue-owl-carousel";
import chrevon from "@/assets/icons/chrevon-carousel.svg";
import { decimal } from "vuelidate/lib/validators";
import DatePicker from 'vue2-datepicker'

export default {
    name: "ListagemProdutosFisicos",
    components: {
        BoxProdutoFisico,
        carousel,
        chrevon,
        DatePicker
    },
    data() {
        return {
            categorys: [],
            paymentFormsTraducao: [],
            paymentForms: Object(),
            krptprice: decimal,
            configCarousel: {
                autoHeight: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: false,
                        margin: 5,
                        loop: false
                    },
                    768: {
                        items: 2,
                        nav: false,
                        margin: 10,
                        loop: false
                    },
                    992: {
                        items: 3,
                        nav: true,
                        margin: 15,
                        loop: false
                    },
                    1200: {
                        items: 4,
                        nav: true,
                        margin: 25,
                        loop: false
                    }
                }
            },
            teste: 0,
            fields: [
                { key: 'produto', label: this.$t('minhas_compras.produto'), sortable: false, class: 'text-left' },
                { key: 'data', label: this.$t('minhas_compras.data'), sortable: true, class: 'text-center' },
                {
                    key: 'valor', label: this.$i18n.t("minhas_compras.valor"), sortable: true, class: 'text-center',
                    formatter: value => {
                        return this.formatPrice(value);
                    }
                },
                {
                    key: 'meioPagamento',
                    label: this.$i18n.t("minhas_compras.tipo_pagamento"),
                    sortable: true,
                    class: 'text-center',
                    formatter: value => {
                        return this.paymentFormsTraducao.find(p => p.tipo === value) ? this.$t("minhas_compras." + this.paymentFormsTraducao.find(p => p.tipo === value).nome) : "";
                    }
                },
                {
                    key: 'status', label: this.$t('minhas_compras.status'), sortable: true, class: 'text-center',
                    formatter: value => {
                        return this.$t("status." + value);
                    }
                },
                { key: 'dataPagamento', label: this.$t('minhas_compras.data_pagamento'), sortable: true, class: 'text-center' },
                { key: 'visualizar', label: this.$i18n.t("minhas_compras.visualizar"), sortable: true, class: 'text-center' }
            ],
            items: [],
            listTipoFiltro: [{ value: 0, text: this.$i18n.t("status.todos") }],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            filtroBusca: {
                dataInicio: this.dataMenos30(),
                dataFim: new Date(),
                idStatus: 0
            },
            lang: {},  
            pedidoSelecionado: {
                produto: String(),
                data: String(),
                valor: String(),
                cotacao: Number(),
                valorTaxa: Number(),
                status: String(),
                codigoPedido: String(),
                valorBTC: Number(),
                moeda: String(),
                upgrade: false,
                renovacao: false,
                taxaRepasse: String(),
                visualizar: {
                    status: String(),
                    codigo: String(),
                    enderecoDeposito: String(),
                    urlPagamento: String()
                }
            },
            taxaRepasse: String(),
        };
    },

    computed: {
        categorysWithProducts() {
            return this.categorys.filter(c => c.produtos && c.produtos.length > 0);
        },
        categoryListRearranged() {
            let selected = this.categorysWithProducts.filter(c => c.selected == true)[0];
            if (!selected) {
                this.categorys[this.categorys.findIndex(c => c.idCategoria == this.categorysWithProducts[0].idCategoria)].selected = true;
                return this.categorysWithProducts;
            } else {
                let newArray = [selected];
                this.categorysWithProducts.forEach(c => {
                    if (selected.idCategoria != c.idCategoria)
                        newArray.push(c);
                });
                return newArray;
            }
        }
    },
    beforeMount() {
        this.getPaymentsForms();
        this.getPaymentsFormsForTranslation();
        this.getCategorysWithProducts();
        this.buscarPedidos();
        this.buscarFiltroTipo();
    },
    methods: {
        getPaymentsFormsForTranslation() {
            http.get("/purchase/getPaymentMethodsTranslation").then(success => {
                this.paymentFormsTraducao = success.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            });
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        dataMais1() {
            var result = new Date();
            result.setDate(result.getDate() + 1);
            return result;
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            }
        },
        buscarPedidos() {
            this.$loading(true);
            this.items = [];
            http.post('/requests/listOrdersProduct', this.filtroBusca).then((response) => {
                this.taxaRepasse = response.data.taxaRepasse;
                response.data.lstPedidos.forEach(item => {
                    this.items.push({
                        produto: item.nomeProduto,
                        codigoPedido: item.codigoPedido,
                        data: item.dataPedido,
                        dataPagamento: item.dataPagamento,
                        valor: item.valorPedido,
                        valorTaxa: item.valorTaxa,
                        cotacao: item.cotacao,
                        status: item.statusTransacao,
                        pago: item.pago,
                        meioPagamento: item.meioPagamento,
                        upgrade: item.upgrade,
                        renovacao: item.renovacao,
                        // boleto: item.urlBoleto
                        valorBTC: (((item.valorPedido + item.valorTaxa) * 1.002) / item.cotacao.toFixed(2)).toFixed(8),
                        visualizar: { status: item.statusTransacao, codigo: item.codigoPedido, enderecoDeposito: item.enderecoDeposito, urlPagamento: item.urlPagamento },
                    });
                });
            }, error => {
                if (error.response && typeof error.response.data == "object")
                    this.$snotify.error(error.response.data.message);
            }).finally(() => { this.$loading(false); });
        },
        rearrangeCategoryList(idCategoria) {
            this.teste = this.teste + 1;
            this.categorys.forEach(c => {
                if (c.idCategoria == idCategoria)
                    c.selected = true;
                else
                    c.selected = false;
            });
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        getCategorysWithProducts() {
            http.get("/product/searchProductGroupByCategory")
                .then(success => {
                    if (success.data) {
                        success.data.forEach(d => d.selected = false);
                        this.categorys = success.data;
                    }
                }, error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);
                });
        },
        getPaymentsForms() {
            http.get("/purchase/getPurchaseMethods").then(success => {
                this.paymentForms = success.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            });
        },
        visualizar(codigo) {
            window.open("#/store/purchasing-data/" + codigo);

        },
        openModal(codigo) {
            this.$loading(true);
            let object = this.items.filter(f => f.codigoPedido == codigo);
            if (object) {
                var tax = 1.002;
                if (object[0].meioPagamento == 12) {
                    tax = 1;
                }
                this.pedidoSelecionado.produto = object[0].produto;
                this.pedidoSelecionado.upgrade = object[0].upgrade;
                this.pedidoSelecionado.renovacao = object[0].renovacao;
                this.pedidoSelecionado.data = object[0].data;
                this.pedidoSelecionado.valor = object[0].valor;
                this.pedidoSelecionado.moeda = this.paymentFormsTraducao.find(p => p.tipo === object[0].meioPagamento) ? this.paymentFormsTraducao.find(p => p.tipo === object[0].meioPagamento).nome : "";
                this.pedidoSelecionado.valorTaxa = object[0].valorTaxa;
                this.pedidoSelecionado.cotacao = object[0].cotacao;
                this.pedidoSelecionado.status = object[0].status;
                this.pedidoSelecionado.informacaoAdicional = object[0].informacaoAdicional;
                this.pedidoSelecionado.codigoPedido = object[0].codidoPedido;
                this.pedidoSelecionado.taxaRepasse = this.taxaRepasse;
                this.pedidoSelecionado.visualizar.status = object[0].visualizar.statusTransacao;
                this.pedidoSelecionado.visualizar.codigo = object[0].visualizar.codidoPedido;
                this.pedidoSelecionado.visualizar.idPedido = object[0].visualizar.idPedido;
                this.pedidoSelecionado.visualizar.urlPagamento = object[0].visualizar.urlPagamento;
                this.pedidoSelecionado.visualizar.enderecoDeposito = object[0].visualizar.enderecoDeposito;
                this.pedidoSelecionado.valorBTC = ((this.pedidoSelecionado.valor + this.pedidoSelecionado.valorTaxa * tax) / this.pedidoSelecionado.cotacao).toFixed(8);
                if (object[0].meioPagamento == 12) {
                    this.pedidoSelecionado.valorBTC = ((this.pedidoSelecionado.valor + this.pedidoSelecionado.valorTaxa * tax) / this.pedidoSelecionado.cotacao).toFixed(2);
                }
            }

            this.$bvModal.show('modal-pedido');


            setInterval(() => {
                this.$loading(false);
            }, 500);
        },
        verificaFormaPagamento(object) {
            if (!object.urlPagamento)
                this.openModal(object.codigo);
            else
                window.open(object.urlPagamento, '_blank');
        },
        buscarFiltroTipo() {
            http.get('/general/status').then((response) => {
                response.data.forEach(item => {
                    if (item.nome != 'Processando pagamento') {
                        this.listTipoFiltro.push({
                            value: item.idStatus,
                            text: this.$i18n.t("status." + item.nome)
                        });
                    }
                });

                this.$loading(false);
            });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        closeDetails() {
            this.$bvModal.hide('modal-pedido');
        },
    }
}