import { render, staticRenderFns } from "./BoxArvoreBinaria.vue?vue&type=template&id=6e97d3c1&"
import script from "./BoxArvoreBinaria.js?vue&type=script&lang=js&"
export * from "./BoxArvoreBinaria.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports