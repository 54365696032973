import "./itemIngresso.scss";
import http from "@/_helper/api-services.js";
import { Money } from 'v-money'

export default {
    name: "itemIngresso",
    components: { Money },
    data() {
        return {
            id: String(),
            paymentForms: [{ text: this.$i18n.t("produtos.selecione_pagamento"), value: null }],
            paymentForm: null,
            password: String(),
            confirmPassword: String(),
            passwordMatch: true,
            usarSaldoCompraCurso: false,
            valorHaUtilizar: 0,
            allowedNumbers: [],
            quantidade: 1,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '$ ',
                precision: 2,
                masked: false
            },
            renovar: Boolean()
        };
    },
    watch: {
        confirmPassword() {
            if (this.$data.password === this.$data.confirmPassword) this.$data.passwordMatch = true;
            else this.$data.passwordMatch = false;
        }
    },
    props: {
        product: Object(),
        paymentFormsAlreadyLoaded: Object()
    },
    computed: {
        nameState() {
            return this.valorHaUtilizar.length > 2 ? true : false
        },
        listPaymentForms() {
            if (this.$props.paymentFormsAlreadyLoaded) {
                let retorno = [];
                retorno.push(this.paymentForms[0]);
                Object.keys(this.$props.paymentFormsAlreadyLoaded).forEach(nameIndex => {
                    retorno.push({
                        text: nameIndex,
                        value: this.$props.paymentFormsAlreadyLoaded[nameIndex]
                    });
                });
                return retorno;
            } else
                return this.$data.paymentForms;
        }
    },
    beforeMount() {
        if (!this.$props.paymentFormsAlreadyLoaded)
            this.getPaymentsForms();

        for (var i = 1; i < 11; i++) {
            this.allowedNumbers.push(
                {
                    text: i,
                    value: i
                }
            );
        }
    },
    mounted() {
        this.id = this._uid.toString();
    },
    methods: {
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        openBuyConfirmation(renovar) {
            this.renovar = renovar;
            this.$bvModal.show(this.id);
        },
        getPaymentsForms() {
            http.get("/purchase/getPurchaseMethods").then(success => {
                let data = success.data;
                Object.keys(data).forEach(nameIndex => {
                    this.paymentForms.push({
                        text: nameIndex,
                        value: data[nameIndex]
                    });
                });
            }, error => {
                if (error.response && error.response.data && error.response.data.message)
                    this.$snotify.error(error.response.data.message);
            });
        },
        handleReset() {
            this.paymentForm = null;
            this.password = String();
            this.confirmPassword = String();
        },
        handleSubmit() {
            if (!this.passwordMatch)
                return false;

            

            this.$loading(true);

            let filtro = {
                idProduto: this.$props.product.idProduto,
                senhaEletronica: this.password,
                metodoDePagamento: this.paymentForm,
                usarSaldoCompraCurso: this.usarSaldoCompraCurso,
                valorHaUtilizar: this.valorHaUtilizar,
                quantidade: this.quantidade,
                renovacao: this.renovar
            }

            http.post("/purchase/buy-ticket", filtro)
                .then(success => {
                    this.$snotify.success(this.$i18n.t("financeiro.compra_sucesso"));
                    this.$bvModal.hide(this.id);
                    this.$loading(false);
                    this.$emit('atualizarTabela');
                }, error => {
                    if (error.response && error.response.data && error.response.data.message)
                        this.$snotify.error(error.response.data.message);

                    this.$loading(false);
                });
        },
        closeModal(id) {
            this.$bvModal.hide(id);
        }
    }
}