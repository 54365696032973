import './CryptoWatch.scss'
import Socket from './socket'
import http from '@/_helper/api-services'

//import VueEllipseProgress from 'vue-ellipse-progress';

export default {
  name: 'CryptoWatch',
  props: [],
  data() {
    return {
      messages: [],
      diario: false,
      bgButton: true,
      randomNumbers: false,
      botaoAtivar: true,
      exchanges: []
    }
  },
  
  computed: {
    component() {
      return this.test ? "vue-ellipse-progress-test" : "vue-ellipse-progress";
    },
  },
  beforeMount(){
    this.setRandomExchanges();
  },
  methods: {
    handleMessage(msg) {
      this.messages.push(JSON.parse(msg))
    },
    setRandomExchanges(){
      this.exchanges = [
        'Cryptsy',
        'Bit-Z',
        'BitVC',
        'Poloniex',
        'DEX (aggregated)',
        'Uniswap V2',
        'Coinbase Pro',
        'CEX.IO',
        'OKCoin',
        'Bisq',
        'Bithumb',
        'FTX',
        'FTX.US',
        'Bitfinex',
        'Quoine',
        '796',
        'Vault of Satoshi',
        'Luno',
        'Binance',
        'Coinone',
        'Binance.US',
        'Deribit',
        'Bitstamp',
        'bitFlyer',
        'BTC China',
        'HitBTC',
        'CBOT',
        'COMEX',
        'WEX',
        'Liquid',
        'Mt. Gox',
        'Gate.io',
        'NYMEX',
        'Kraken',
        'BitMEX',
        'meXBT',
        'Huobi',
        'QuadrigaCX',
        'BitBay',
        'Gemini',
        'Bittrex',
        'Okex',
        'Kraken Futures',
        'CME (Beta)',
      ]
    },
    randonExchange() {
      if(this.exchanges.length > 1){
        var exchange = this.exchanges[Math.floor(Math.random() * this.exchanges.length)]
       
        return exchange.toUpperCase()
      } 

      return "";
    },
    rodaSimulacao(){
      http.post("/RendimentoDinamico/simularRend", this.formSearch).then(
        (response) => {
            this.progress = response.data
            this.botaoAtivar = false;
            this.setRandomExchanges();

            setTimeout(() => 
          {
            this.exchanges = [];  
            this.randomNumbers = true;
          }, 3000);
        },
        error => {
            if (error.response && error.response.data)
            //error.response.data.errors.forEach(e => {
                    this.$snotify.error(error.response.data.message);
            //    });
            // else
            //     this.$snotify.error("An unexpected error has occurred");
        });
    },
    rodaExecucao(){
      http.post("/RendimentoDinamico/modificarRend", this.formSearch).then(
        (response) => {
            this.progress = response.data
            this.formSearch.simulacao = false;
            this.botaoAtivar = false;
            this.setRandomExchanges();

            setTimeout(() => 
          {
            this.exchanges = [];  
            this.randomNumbers = true;
          }, 3000);
        },
        error => {
            if (error.response && error.response.data)
            error.response.data.errors.forEach(e => {
                    this.$snotify.error(error.response.data.message);
                });
            // else
            //     this.$snotify.error("An unexpected error has occurred");
        });
    }
  },
  created() {
    Socket.$on('message', this.handleMessage)
  },
  beforeDestroy() {
    this.messages = []
    Socket.$off('message', this.handleMessage)
  }
  
  
}
