import { render, staticRenderFns } from "./Gerais.vue?vue&type=template&id=4b2766da&"
import script from "./Gerais.js?vue&type=script&lang=js&"
export * from "./Gerais.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports