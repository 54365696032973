import "./ListaIdioma.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "ListaIdioma",
    data() {
        return {
            languages: [
                { value: 'alemao', text: 'alemao'},
                { value: 'chines', text: 'chines' },
                { value: 'coreano', text: 'coreano' },
                { value: 'espanhol', text: 'espanhol' },
                { value: 'filipino', text: 'filipino' },
                { value: 'frances', text: 'frances' },
                { value: 'georgian', text: 'georgian' },
                { value: 'grego', text: 'grego' },
                { value: 'hebrew', text: 'hebrew' },
                { value: 'ingles', text: 'ingles' },
                { value: 'india', text: 'india' },
                { value: 'italy', text: 'italy' },
                { value: 'japones', text: 'japones' },
                { value: 'polish', text: 'polish' },
                { value: 'portugues', text: 'portugues' },
                { value: 'romania', text: 'romania' },
                { value: 'russo', text: 'russo' },
                { value: 'tailandes', text: 'tailandes' },
                { value: 'vietnam', text: 'vietnam' }
            ]
        }
    },
    beforeMount() {
        this.obterResumoValores();
        if (!localStorage.getItem("language")) {
            localStorage.setItem('language', this.$i18n.locale);
        }
        this.$i18n.locale = localStorage.getItem("language");
        this.loggedIn = localStorage.getItem('user');
        this.dadosUser = this.$store.getters['autenticacao/getLogin'].user;
        //if (this.loggedIn) {
        //    this.getNotifications();
        //    if (localStorage.getItem("showComunicado") == "true")
        //        this.getAnnouncements();
        //}
    },
    methods: {
        obterResumoValores() {
            const loggedIn = localStorage.getItem("user");
        },
        changeLanguage(value) {
            this.$loading(true);
            localStorage.setItem('language', value);

            //Salva preferencia na API
            if (this.loggedIn) {
                this.changeUserLanguage(value);
            } else {
                window.location.reload();
                this.$loading(false);
            }
        },
        changeUserLanguage(idioma) {
            var dados = { cultura: idioma };
            http.post("/user/mudarIdioma", dados).then(response => {
                if (response.data.status) {
                    this.$snotify.success(response.data.message);

                }
            }, error => {
                // this.$snotify.error(error.response.data);
            }).finally(() => {
                window.location.reload();
            });;
        }
    }
}