import "./usuariosAdministrativos.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "usuariosAdministrativos",
    data() {
        return {
            nomeLogin: '',
            locale: 'pt',
            search: '',
            lista: [],
            fields: [{ key: 'nome', label: 'Nome', sortable: true, sortDirection: 'desc' },
            { key: 'login', label: 'Login', sortable: true, class: 'text-center' },
            { key: 'email', label: 'Email', sortable: true, class: 'text-center' },
            { key: 'celular', label: 'Celular', sortable: true, class: 'text-center' },
            { key: 'grupo', label: 'Grupo', sortable: true, class: 'text-center' },
            { key: 'ativo', label: 'Ativo', sortable: true, class: 'text-center' }],
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            search: {
                login: '',
                email: '',
                ativo: true
            },
            lang: {
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            },
            cadastro: {
                nome: '',
                login: '',
                senha: '',
                idGrupo: 0,
                email: '',
                celular: ''
            },
            listaGrupo: []
        };
    },
    components: {
    },
    beforeMount() {

    },
    mounted() {
        //this.obterNomeLogado();
        this.$loading(true);
        this.listarDados();
    },
    watch: {
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        activateDeactivate(idUsuario) {
            this.$loading(true);
            http.get(`/Admin/inactivateUserAdmin/` + idUsuario).then(
                success => {
                    this.lista.forEach(c => {
                        if (c.idUsuario == idUsuario)
                            c.ativo = !c.ativo;
                    });
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => { this.$loading(false); })
        },
        cadastrarUsuario() {
            this.$loading(true);
            http.post('/userAdmin/register', this.cadastro).then((response) => {
                this.$snotify.success('Usuário cadastrado!');
                this.listarDados();
                this.$bvModal.hide("modal-usuario");
            }, error => {
                if (error.response.data.errors)
                    this.$snotify.error(error.response.data.errors);
            }).finally(() => { this.$loading(false); });
        },
        adicionar() {
            this.listarGrupos();
            this.cadastro = {
                nome: '',
                login: '',
                senha: '',
                idGrupo: 0,
                email: '',
                celular: ''
            };
            this.$bvModal.show("modal-usuario");
        },
        listarGrupos() {
            this.$loading(true);
            this.listaGrupo = [];
            http.get('/group/listUsableGroups', this.search).then((response) => {
                response.data.filter(g => g.descricao !== 'Usuario').forEach(item => {
                    this.listaGrupo.push({
                        value: item.idGrupo,
                        text: item.descricao
                    })
                });
                this.$loading(false);
            });
        },
        listarDados() {
            this.$loading(true);
            this.lista = [];
            http.post('/UserAdmin/list', this.search).then((response) => {
                this.totalRows = response.data.length;
                response.data.forEach(item => {
                    this.lista.push({
                        idUsuario: item.idUsuario,
                        nome: item.nome,
                        login: item.login,
                        email: item.email,
                        celular: item.celular,
                        ativo: item.ativo,
                        grupo: item.grupo.descricao
                    })
                });
                this.$loading(false);
            });
        }

    }
}