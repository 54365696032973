import moment from 'moment';
import http from '@/_helper/api-services';

export default {
  name: 'ContagemRegressiva',
  props: ['dataOperacao','dataSistema'],
  data() {
    return {
      timeServer: {},
      horas: 0,
      minutos: 0,
      segundos: 0,
      counter: 0
    };
  },
  created() {
        function addHours(date, hours) {
          const dateCopy = new Date(date);
        
          dateCopy.setHours(dateCopy.getHours() + hours);
        
          return dateCopy;
        }
        const dtOp = new Date(this.$props.dataOperacao);
        const dtFinal = addHours(dtOp, 8);
        const dataSist = new Date(this.$props.dataSistema);

        if(dataSist > dtFinal)
          return
        else
        {
          const dataFinal = moment(this.$props.dataOperacao).add(8, 'hours');
          this.counter = setInterval(() => {
              http.get("/dashboard/serverTime").then((response) => {
                this.timeServer = response.data.serverTime;
              });
              const agora = moment(this.timeServer);
              const diferenca = moment.duration(dataFinal.diff(agora));
              this.horas = diferenca.hours();
              this.minutos = diferenca.minutes();
              this.segundos = diferenca.seconds();
          }, 1000);
        };
  },
  beforeDestroy(){
    clearInterval( this.counter );
  }
};