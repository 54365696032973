import http from "@/_helper/api-services.js";
import util from "@/_helper/util.js";
import boxAdcionarCategoria from '@/components/AdcionarCategoria/boxAdcionarCategoria.vue';
import DatePicker from 'vue2-datepicker'

export default {
    name: "GerenciarGanhadores",
    components: {
        boxAdcionarCategoria,
        DatePicker
    },
    data() {
        return {
            search: {
                idCategoria: 0,
                Ativo: 0
            },
            fields: [{ key: "nome", label: "Nome", sortable: true, sortDirection: "desc" },
            { key: "login", label: "Login", sortable: true },
            { key: "vip", label: "VIP", sortable: true, formatter: val => { return val == true ? "Sim" : "Não" } },
            { key: "nomeGraduacao", label: "Graduação Atingida", sortable: true },
            { key: "dataGraduacao", label: "Data Graduação", sortable: true },
            { key: "nivel", label: "Nível", sortable: true },
            { key: "premio", label: "Prêmio", sortable: true },
            { key: "opcoes", label: "Opções" }],
            totalPages: 0,
            totalRows: 1,
            perPage: 15,
            currentPage : 1,
            pageOptions: [15, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: String(),
            filterOn: [],
            list: [],
            todosSelecionados: false,
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez",],
            },
            filtroBusca:{
                dataInicial: this.dataMenos30(),
                dataFinal: new Date(),
                vip: null
            },
            selectVip: [
                { value: null, text: "Todos"},
                { value: true, text: "Vip"},
                { value: false, text: "Não Vip"}
            ],
        }
    },
    beforeMount() {
        this.$loading(false);
        this.obterGanhadores();
    },
    methods: {
        aprovarPagamento(idPagamento){
            this.$loading(true);

            http.post("/Admin/approvePaymentAward", { idGanhadoresPremio : idPagamento}).then(response => {
                this.$snotify.success(response.data.message);
                this.obterGanhadores();
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });
        },
        // obterGanhadores() {
        //     this.$loading(true);

        //     http.get("/Admin/getUsersWinners").then(response => {
        //         this.list = [];

        //         response.data.forEach((item) => {
        //             this.list.push({
        //                 idGanhadoresPremio: item.idGanhadoresPremio,
        //                 idUsuario: item.idUsuario,
        //                 nome: item.nome,
        //                 login: item.login,
        //                 graduacao: item.nomeGraduacao,
        //                 nivel: item.nivelGraduacao,
        //                 premio: item.premio,
        //                 dataCadastro: item.dataCadastro
        //             });
        //         });

        //         this.totalRows = this.list.length;

        //     }, error => {
        //         if (error.response && error.response.data)
        //             this.$snotify.error(error.response.data.message);
        //     }).finally(() => {
        //         this.$loading(false);
        //     });
        // },
        obterGanhadores(){
            this.$loading(true);

            http.post("/Admin/searchUsersWinners", this.filtroBusca).then(response => {
                this.list = [];

                response.data.forEach((d) => {
                    this.list.push({
                        idGanhadoresPremio: d.idGanhadoresPremio,
                        login: d.login,
                        nome: d.nome,
                        nomeGraduacao: d.nomeGraduacao,
                        pagamentoEfetuado: d.pagamentoEfetuado,
                        dataGraduacao: d.dataGraduacao,
                        vip: d.vip,
                        nivel: d.nivel,
                        premio: d.premio
                    });
                });
                
                this.totalRows = this.list.length;
            }, error => {
                if (error.response && error.response.data)
                    this.$snotify.error(error.response.data.message);
            }).finally(() => {
                this.$loading(false);
            });
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            }
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        }
    }
}