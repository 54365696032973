import "./UserInformation.scss";
import http from "@/_helper/api-services.js";
import ListaIdioma from '@/components/ListaIdioma/ListaIdioma.vue';
// import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";

export default {
    name: "UserInformation",
    data() {
        return {
            dadosUser: {},
            nomeLogin: '',
            resumoValores: {},
            notifications: Array(),
            announcements: Array(),
            showingNotifications: false,
            showingAnnouncements: false,
            notificationOpened: Object(),
            showAllNotificaton: false,
            loggedIn: false,
            shortCut: '',
            usuarioLogado: '',
            idUsuario: null,
            acessoRemoto: false,
        }
    },
    components: {
        ListaIdioma
    },
    // props: ["emAcessoRemoto"],
    computed: {
        notificationsNotRead() {
            return this.notifications.filter(n => !n.lidaFront);
        },
        notificationsFiltered() {
            return this.notifications.filter(n => n.lidaFront && this.showAllNotificaton || !n.lidaFront && !this.showAllNotificaton)
        }
    },
    beforeMount() {
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
        this.obterResumoValores();
        //this.obterNomeLogado();
        if (!localStorage.getItem("language")) {
            localStorage.setItem('language', this.$i18n.locale);
        }
        this.$i18n.locale = localStorage.getItem("language");
        this.loggedIn = localStorage.getItem('user');
        this.dadosUser = this.$store.getters['autenticacao/getLogin'].user;
        if (this.loggedIn) {
            this.getNotifications();
            if (localStorage.getItem("showComunicado") == "true")
                this.getAnnouncements();
        }
    },
    methods: {
        obterResumoValores() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/Dashboard/summaryBalance').then((responde) => {
                    this.resumoValores = responde.data;
                    this.shortCut = this.resumoValores.userName[0][0].toUpperCase();
                    this.usuarioLogado = this.resumoValores.userName.split(' ')[0]
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    });
                this.$loading(false);
            }
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            if(logado){
                this.dadoLogado = JSON.parse(logado);
                this.nomeLogin = this.dadoLogado.username.split(' ')[0];
            }
        },
        formatPrice(valor) {
            if(!valor)
                return '$0,00';
            
            return valor.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
          });
        },
        changeLanguage(value) {
            localStorage.setItem('language', value);

            //Salva preferencia na API
            if (this.loggedIn) {
                this.changeUserLanguage(value);
            }
        },
        changeUserLanguage(idioma) {
            var dados = { cultura: idioma };

            http.post("/user/changeLanguage", dados).then(response => {
                if (response.data.status) {
                    this.$snotify.success(response.data.message);
                    
                }
                
            }, error => {
                this.$snotify.error(error.response.data);
            }).finally(() => {
                this.$loading(false);
                window.location.reload();
            });;
        },
        getNotifications() {
            http.get("/announcement/getUnreadNotifications").then(
                success => {
                    if (success.data) {
                        success.data.forEach(n => n.lidaFront = n.lida);
                        this.notifications = success.data
                    }
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                }
            );
        },
        getAnnouncements() {
            http.get("/announcement/getAnnouncementByGraduation").then(
                success => {
                    if (success.data) this.announcements = success.data;
                    if (this.announcements.length > 0) {
                        this.announcements[0].visible = true;
                        this.notificationOpened = this.announcements[0];
                        this.showingAnnouncements = true;
                        this.$bvModal.show("modal-notifications");
                    }
                },
                error => {
                    if (error && error.response && error.response.data) this.$snotify.error(error.response.data.message);
                }
            );
        },
        logoutSessaoRemota() {
            localStorage.setItem("user", localStorage.getItem("userAdmin"));
            localStorage.removeItem("userAdmin");
            localStorage.removeItem("menu");
            this.$router.push("/admin/users");
            window.location.reload();
        },
        formatDate(date, showTime) {
            if (!showTime)
                return new Date(date).toLocaleDateString();
            else return `${new Date(date).toLocaleDateString()} - ${new Date(date).toLocaleTimeString()}`
        },
        changeNotification(index) {
            if (this.showingAnnouncements) {
                this.announcements.forEach(a => a.visible = false);
                if (this.notificationOpened == this.announcements[index])
                    this.notificationOpened = null
                else {
                    this.announcements[index].visible = true;
                    this.notificationOpened = this.announcements[index];
                }
            } else {
                this.notifications.forEach(a => a.visible = false);
                if (this.notificationOpened == this.notificationsFiltered[index])
                    this.notificationOpened = null
                else {
                    this.notificationsFiltered[index].visible = true;
                    this.notificationOpened = this.notificationsFiltered[index];
                }
                if (!this.notificationsFiltered[index].lida)
                    this.markNotificationAsRead(this.notifications[index].idMensagem);
            }
        },
        openModalNotification(index) {
            this.showAllNotificaton = false;
            this.showingAnnouncements = false;
            this.showingNotifications = true;
            this.notificationOpened = this.notificationsNotRead[index];
            this.$bvModal.show("modal-notifications");
            if (!this.notificationsNotRead[index].lida)
                this.markNotificationAsRead(this.notificationOpened.idMensagem);
        },
        handleModalClose() {
            if (this.showingAnnouncements)
                localStorage.removeItem("showComunicado");
            else
                this.notifications.forEach(notification => {
                    if (notification.lida) notification.lidaFront = true;
                });
        },
        markNotificationAsRead(idMensagem) {
            http.update("/announcement/markNotificationAsRead", { idMensagem })
                .then(
                    success => {
                        this.notifications.forEach(notification => {
                            if (notification.idMensagem == idMensagem) notification.lida = true;
                        });
                    }
                );
        },
        openNotificationsWithAll() {
            this.showAllNotificaton = true;
            this.notificationOpened = null;
            this.$bvModal.show("modal-notifications");
        }
    }
}