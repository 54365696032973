import http from '@/_helper/api-services';
import './DadosBancarios.scss';

export default {
    name: 'DadosBancarios',
    data() {
        return {
            fields: [{ key: 'banco', label: 'Banco', sortable: true, sortDirection: 'desc', class: 'title-color ' },
            { key: 'agencia', label: 'Agência', sortable: true, class: 'text-center title-color ' },
            { key: 'conta', label: 'Conta', sortable: true, class: 'text-center title-color ' },
            { key: 'tipo', label: 'Tipo', sortable: true, class: 'text-center title-color ' }],
            listDados: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            tipoConta: [],
            opcoesDeBanco: [
                { value: null, text: 'Banco' },
            ],
            usuarioBanco: {
                IdUsuarioBanco: 0,
                IdBanco: null,
                Agencia: null,
                DigitoAgencia: null,
                Conta: null,
                DigitoConta: null,
                CpfCnpj: '',
                IdTipo: null
            },
            erroForm: false
        }
    },
    components: {

    },
    beforeMount() {
        this.obterBancos();
        this.obterTipoConta();
        this.obterDadosBancarios();
    },
    mounted() {
        this.$loading(false);
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        obterBancos() {
            http.get('/bank/listbanks/').then((response) => {
                response.data.forEach(item => {
                    this.opcoesDeBanco.push({
                        value: item.idBanco,
                        text: item.febraban + "-" + item.nome
                    });
                });
            });
        },
        obterTipoConta() {
            http.get('/general/type/TPC').then((response) => {
                this.usuarioBanco.IdTipo = response.data[0].idTipo;

                response.data.forEach(item => {
                    this.tipoConta.push({
                        value: item.idTipo,
                        text: item.descricao
                    });
                });
            });
        },
        obterDadosBancarios() {
            this.$loading(true);
            http.get('/userBank/getBankDetails/').then((response) => {
                response.data.forEach(item => {
                    this.listDados.push({
                        banco: item.banco.nome,
                        agencia: item.agencia + '-' + item.digitoAgencia,
                        conta: item.conta + '-' + item.digitoConta,
                        tipo: item.tipo.descricao
                    });
                });

                this.$loading(false);
            });
        },
        salvarUsuarioBanco() {
            this.$loading(true);
            if (this.checkForm()) {
                http.post('/userBank/registerUserBank', this.usuarioBanco).then((response) => {
                    if (response.data == true) {
                        this.$snotify.success("Banco cadastrado com sucesso.");
                        this.$bvModal.hide('modal-banco');
                    } else {
                        this.$snotify.error("Erro ao cadastrar banco.");
                    }
                });
            }

            this.$loading(false);
        },
        checkForm() {
            if (!this.usuarioBanco.IdBanco ||
                (!this.usuarioBanco.Agencia || this.usuarioBanco.Agencia.length < 4) ||
                !this.usuarioBanco.DigitoAgencia ||
                !this.usuarioBanco.Conta ||
                !this.usuarioBanco.DigitoConta ||
                (!this.usuarioBanco.CpfCnpj || (!this.usuarioBanco.CpfCnpj.length == 14 && !this.usuarioBanco.CpfCnpj.length == 18)) ||
                !this.usuarioBanco.IdTipo) {

                this.erroForm = true;
                return false;
            }

            return true;
        }
    }
}