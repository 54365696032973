import http from '@/_helper/api-services';
import './Home.scss'
import SideBar from "@/components/SideBar/SideBar.vue";
import Graduacao from "@/components/Graduacao/Graduacao.vue";
import BarraProgresso from '@/components/BarraProgresso/BarraProgresso.vue';
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
import BarCarousel from '@/components/BarCarousel/BarCarousel.vue';
import SelecionarPais from '@/components/SelecionarPais/SelecionarPais.vue';
import historicoSaque from "@/components/HistoricoSaque/historicoSaque.vue";
import CryptoWatch from '@/components/CryptoWatch/CryptoWatch.vue';
import Socket from './socket';
// import ContagemRegressivaAtivo from "../../components/ContagemRegressivaAtivo/ContagemRegressivaAtivo.vue";

export default {
    name: 'Home',
    data() {
        return {
            dadosBarraStatus: {},
            pontosUsuario: {},
            chart: {},
            dadosUsuario: {},
            lucro: {},
            timeArbitration: {},
            itemsArbitragem: [],
            nomeLogin: '',
            totalUsers: 0,
            socketConnected: false,
            activeClass: false,
            resumoValores: {},
            pontosUsuario: {},
            dadosBarraProgresso: {},
            fields: [
                { key: 'tipo', label: this.$i18n.t('financeiro.tipo'), sortable: true, sortDirection: 'desc' },
                { key: 'data', label: this.$i18n.t('financeiro.data'), sortable: true, class: 'text-center' },
                { key: 'descricao', label: this.$i18n.t('financeiro.descricao'), sortable: true, class: 'text-center' },
                { key: 'valor', label: this.$i18n.t('financeiro.valor'), sortable: true, class: 'text-center' }],
            fieldsArbitragem: [
                { key: 'dataOperacao', label: this.$i18n.t('financeiro.data'), sortable: true, class: 'text-center' },
                { key: 'jogo', label: this.$t('financeiro.jogo'), sortable: true, class: 'text-center' },
                { key: 'valorOperacao', label: "Stake", sortable: true, class: 'text-center' },
                { key: 'lucro', label: this.$t('financeiro.lucro'), sortable: true, class: 'text-center' }],
            fieldsOperacoesGerais: [
                { key: 'login', label: this.$i18n.t('contato.usuario'), sortable: true, class: 'text-center' },
                { key: 'valorOperacao', label: "Stake", sortable: true, class: 'text-center' },
                { key: 'lucro', label: this.$t('financeiro.lucro'), sortable: true, class: 'text-center' },
                { key: 'dataOperacao', label: this.$i18n.t('financeiro.data'), sortable: true, class: 'text-center' }],
            items: [],
            listDados: [],
            listOperacoes: [],
            listOperacoesGerais: [],
            recibo: {
                valor: 0,
                valorBTC: 0
            },
            acessoRemoto: false,
            formSearch: {
                dataInicio: this.dataMenos15(),
                dataFim: new Date(),
                tipoFilter: null,
                itensPorPagina: 25,
                paginaAtual: 1,
                chave: null,
                texto: ''
            },
            ganhos: {},
            topIndicacao: {},
            graduations: [],
            resumoValores: {},
            chartOptions2: {
                chart: {
                    width: 800,
                    type: 'pie',
                },
                labels: ['Indications', 'Operations', 'Residual'],
                fill: {
                    colors: ['#FCB414', '#D99A11', '#FCE321'],
                    opacity: 0.9,
                    type: 'solid',
                    pattern: {
                        width: 8,
                        height: 15,
                        strokeWidth: 0,
                    },
                },
                stroke: {
                    dashArray: 0,
                },
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    fontSize: '14px',
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: '#7a7a7a',
                        useSeriesColors: false
                    },
                    markers: {
                        width: 15,
                        height: 8,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: -5,
                        offsetY: 0
                    },

                }
                // responsive: [{
                //     breakpoint: 480,
                //     options: {
                //         chart: {
                //             width: 200
                //         },
                //         legend: {
                //             position: 'bottom'
                //         }
                //     }
                // }]
            },
            diaSelecionado: 15,
            ganhosDias: {},
            keyUsuarios: 0,
            seriesUsuarios: [],
            chartUsuariosOptions: {
                chart: {
                    type: 'line',
                    shadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 1
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ["#AD2E33", "#ddb365"],
                stroke: {
                    curve: 'smooth',
                    width: 2,
                    dashArray: 0,
                },
                grid: {
                    borderColor: '#666',
                },
                markers: {
                    size: 1
                },
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    fontSize: '12px',
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: '#7a7a7a',
                        useSeriesColors: false
                    },
                    markers: {
                        width: 15,
                        height: 8,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: -5,
                        offsetY: 0
                    },

                },
                yaxis: {
                    title: {
                        text: 'Quantidade'
                    },
                    labels: {
                        show: true,
                        style: {
                            fontSize: '10px',
                        },
                    },
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'Dias',
                        offsetY: -30,
                    },
                    labels: {
                        show: true,
                        style: {
                            fontSize: '10px',
                        },
                    },
                }
            },
            listGraduation: [],
            graduacaoUsuario: {},
            jogos: [],
            jogosDestaque: [],
            listContrats: [],
            jogoSelecionado: {},
            slideBanner: {
                "arrows": true,
                "dots": true,
                "infinite": true,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "autoplay": true,
                "autoplaySpeed": 8000,
                "speed": 300,
                "cssEase": "linear",
                "pauseOnDotsHover": true,
                "pauseOnFocus": true,
                "pauseOnHover": true,
                "responsive": [{
                    "breakpoint": 992,
                    "settings": {
                        "slidesToShow": 1,
                    }
                },
                ]
            },
            campeonatos: [
                { text: 'all', value: null }
            ],
            jogoBkp: [],
            countJogos: 0,
            usuarioMeioPagamento: {},
            atualizaMeioPagamento: String()
        }
    },

    components: {
        SideBar,
        Graduacao,
        BarraProgresso,
        PremiosDashboard,
        BarCarousel,
        SelecionarPais,
        historicoSaque,
        CryptoWatch
    },
    beforeMount() {
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
        this.getGraficoGanhos();
        this.obterDadosUsuario();
        this.nextGraduation();
        this.userGraduation();
        this.listarUltimasOperacoes();
        this.listarUltimasOperacoesGerais();
        this.getGames();

        var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/664e5133981b6c5647736177/1huguqchc';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();

    },
    mounted() {
        this.$loading(true);
        this.obterBarraStatus();
        this.listarDados();
        this.atualizaJogos();
        this.verificaMeioPagamentoUser();

       

    },
    computed: {
       
    },
    methods: {
        confirmPaymentType() {
            if (this.atualizaMeioPagamento == '') {
                this.$snotify.error(this.$i18n.t("erros.meio_pagamento"));
            }
            else {
                http.post("/Dashboard/updatePaymentType/" + this.atualizaMeioPagamento).then((response) => {
                    this.$snotify.success(this.$i18n.t('generico.enviado_sucesso'));
                    this.$bvModal.hide('modal-aviso');
                },
                    error => {
                        this.$loading(false);
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    })
            }
        },
        verificaMeioPagamentoUser() {
            http.get('/Dashboard/getPaymentTypeUser').then((response) => {
                this.usuarioMeioPagamento = response.data;
                if (this.usuarioMeioPagamento.temPagamento == false)
                    this.$bvModal.show('modal-aviso');
                //alert("Defina um meio de pagamento");
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        mudar(value) {

            if (value != null) {
                this.jogos = this.jogoBkp;
                this.jogos = this.jogos.filter(c => c.sportTitle == value);
            }
            else {
                this.jogos = this.jogoBkp;
            }
        },
        apostar(obj) {
            this.jogoSelecionado = obj;
        },
        atualizaJogos() {
            this.countJogos = setInterval(() => {
                this.getGames();
            }, 60000)
        },
        getGames() {
            http.get('/Dashboard/getGames/').then((response) => {
                this.campeonatos = [
                    { text: 'all', value: null }
                ];
                this.jogos = response.data.result.filter(c => c.ativo == true);
                this.jogoBkp = response.data.result.filter(c => c.ativo == true);
                this.jogosDestaque = response.data.result.filter(c => c.destaque == true);
                response.data.campeonatos.forEach(a => {
                    this.campeonatos.push({
                        text: a.name,
                        value: a.name
                    });
                });
            },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                    this.$loading(false);
                });
        },
        userGraduation() {
            this.$loading(true);
            http.get('/Dashboard/getAwards/').then((responde) => {
                this.graduacaoUsuario = responde.data;
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        nextGraduation() {
            http.get('/Dashboard/nextGraduation/').then((responde) => {
                this.listGraduation = responde.data;
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        obterBarraStatus() {
            this.$loading(true);
            http.get('/Dashboard/getBarStatus/').then((responde) => {
                this.dadosBarraStatus = responde.data;
                this.$loading(false);
            },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                    this.$loading(false);
                });
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        handleMessage(total) {
            this.totalUsers = total;
            this.changeClass();
        },
        changeClass() {
            this.activeClass = true;
            setTimeout(() => { this.activeClass = false }, 1000);
        },
        clearInterval() {
            clearInterval(this.interval)
        },
        obterDadosUsuario() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/Dashboard/getBarStatus/').then((responde) => {
                    this.dadosUsuario = responde.data;
                    if (this.dadosUsuario.limiteAtingido == true) {
                        this.$bvModal.show('modal-limit');
                    }

                    this.totalUsers = this.dadosUsuario.totalPessoas;
                    this.changeClass();

                    if (!this.socketConnected) {
                        Socket.$on('message', this.handleMessage);
                        this.socketConnected = true;
                    }
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }

            const forceCountry = localStorage.getItem("force-country");
            if (forceCountry) {
                this.$bvModal.show('modal-pais');
            }
        },
        connectHandler() {
            var uri = "ws://" + window.location.host + "/ws-";
            socket = new WebSocket(uri);
            socket.onopen = function (event) {
            };
            socket.onclose = function (event) {
            };
            socket.onmessage = function (event) {
                appendItem(list, event.data);
            };
            socket.onerror = function (event) {
            };
        },
        obterPontosUsuario() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/user/getUserScore/').then((responde) => {
                    this.pontosUsuario = responde.data;
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }
        },
        // obterDadosBarraProgresso() {

        //     http.get('/Dashboard/earningLimits/').then((responde) => {
        //         this.dadosBarraProgresso = responde.data;
        //         this.series.push(this.dadosBarraProgresso.percentualTotalRede, this.dadosBarraProgresso.percentualValorOperacoes, this.dadosBarraProgresso.percentualTotalResidual);
        //         this.series1.push(this.dadosBarraProgresso.percentualAtualRede) ;
        //     });
        // },
        getWithdrawalHistory() {

            http.get("/withdraw/lastWithdrawals").then(success => {
                this.items = [];
                if (success.data) {
                    success.data.forEach(d => {
                        this.items.push({
                            idSaque: d.idSaque,
                            dataSolicitacao: d.dataSolicitacao,
                            status: d.status,
                            tipo: d.tipo,
                            valor: d.valor,
                            dataProcessado: d.dataProcessado,
                            valorBTC: d.valorBTC,
                            cotacao: d.cotacao,
                            urlTransacao: d.urlTransacao,
                            enderecoBTC: d.enderecoBTC
                        });
                    });
                }
                this.$loading(false);
            }, error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                this.$loading(false);
            });
        },
        listarDados() {
            this.$loading(true);
            http.post('/extract/fetchExtract', this.formSearch).then((response) => {
                this.listDados = [];
                //this.totalPaginas = response.data.totalPages;
                response.data.lancamentos.slice(0, 5).forEach(item => {
                    this.listDados.push({
                        tipo: item.tipo,
                        data: item.dataLancamento,
                        descricao: item.descricao,
                        valor: item.valor.toFixed(2),
                        valorFormated: '$ ' + item.valor.toFixed(4)
                    })
                });
                this.$loading(false);
            },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    this.$loading(false);
                });
        },
        listarUltimasOperacoes() {
            this.$loading(true);
            http.get("/arbitragem/lastOperations").then((response) => {
                this.listOperacoes = [];
                //this.totalPaginas = response.data.totalPages;
                response.data.slice(0, 5).forEach(item => {
                    this.listOperacoes.push({
                        dataOperacao: item.dataOperacao,
                        valorOperacao: item.valorOperacao,
                        lucro: item.lucro,
                        valorVenda: item.valorVenda,
                        jogo: item.homeTeam + ' ' + item.homeTeamScore + ' x ' + item.awayTeamScore + ' ' + item.awayTeam
                    })
                });
                this.$loading(false);
            },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                    this.$loading(false);
                });
        },
        listarUltimasOperacoesGerais() {
            this.$loading(true);
            http.get("/arbitragem/lastFiveOperations").then((response) => {
                this.listOperacoesGerais = [];
                response.data.forEach(item => {
                    this.listOperacoesGerais.push({
                        dataOperacao: item.dataOperacao,
                        valorOperacao: item.valorOperacao.toFixed(2),
                        lucro: item.lucro,
                        login: item.login.substr(0, 3).toLocaleUpperCase() + '***'
                    })
                });
                this.$loading(false);
            },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                    this.$loading(false);
                });
        },
        obterResumoValores() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/Dashboard/summariesValues/').then((responde) => {
                    this.resumoValores = responde.data;
                },error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }
        },
        obterGanhos() {
            http.get("/dashboard/getGains").then((response) => {
                this.ganhos = response.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        obterTop10() {
            http.get("/dashboard/getTop10").then((response) => {
                this.topIndicacao = response.data;
            }, error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        getLucro() {
            http.get("/dashboard/getLucro").then((response) => {
                this.lucro = response.data[0];
            }, error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        // listGraduation() {
        //     http.get("/dashboard/listGraduations").then((response) => {
        //         this.graduations = response.data;
        //     }, error => {
        //         if (error.response && error.response.data && error.response.data.errors)
        //             error.response.data.errors.forEach(e => {
        //                 this.$snotify.error(e);
        //             });
        //         else
        //             this.$snotify.error("An unexpected error has occurred");
        //     })
        // },
        dataMenos15() {
            var result = new Date();
            result.setDate(result.getDate() + -15);
            return result;
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
            } else {
                return "";
            }
        },
        formatPrice(valor) {
            if (!valor)
                return '$0,00';

            return this.casasDecimais(valor,2).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
        casasDecimais(num, precisao) {
            var casas = Math.pow(10, precisao);
            return Math.floor(num * casas) / casas;
        },
        verRecibo(object) {
            this.recibo = {};
            if (object) {
                this.recibo = object;
            }
            this.$bvModal.show('modal-recibo');
        },
        openModalBanner() {
            this.$bvModal.show('modal-banner');
        },
        closeLimit() {
            this.$bvModal.hide('modal-limit');
        },
        closePais() {
            this.$bvModal.hide('modal-pais');
        },
        closeAlert() {
            this.$bvModal.hide('modal-aviso');
        },
        close() {
            this.$bvModal.hide('modal-banner');
        },
        async getGraficoGanhos() {
            await http.get("/dashboard/reportGainsOperations").then(
                success => {
                    if (success && success.data) {
                        this.seriesUsuarios.push({
                            name: "Plataforma",
                            data: success.data.map(d => d.plataforma)
                        })
                        this.seriesUsuarios.push({
                            name: "MinhasOperacoes",
                            data: success.data.map(d => d.minhasOperacoes)
                        });
                        this.chartUsuariosOptions.xaxis.categories = success.data.map(d => {
                            return new Date(d.data).toLocaleDateString();
                        });
                        this.keyUsuarios++;
                    }
                }, error => {
                    if (error && error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            );
        }
    },
    beforeDestroy() {
        clearInterval(this.countJogos);
    }
}