import "./LinkPatrocinador.scss";
import http from '@/_helper/api-services';

export default {
    name: 'LinkPatrocinador',
    props: ['propBarraStatus'],
    data() {
        return {
            dadosUsuario: {},
            LinkPatrocinador: 'http://asdfasfloetaemasdfas@.com.br',
            value: 45,
            max: 100,
            cartao: Boolean()
        }
    },
    watch: {
        propBarraStatus(el) {
            if (el.idUsuario == null || el.idUsuario == undefined)
                this.obterDadosUsuario();
            else
                this.dadosUsuario = el;
        }
    },
    beforeMount() {
        this.verificarCartao();
    },
    mounted() {
        if (!this.$props.propBarraStatus)
            this.obterDadosUsuario();
    },
    methods: {
        obterDadosUsuario() {
            http.get('/Dashboard/getBarStatus/').then((responde) => {
                this.dadosUsuario = responde.data;
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        },
        onCopy(e) {
            this.$snotify.success(this.$i18n.t("generico.copiar_link"));
        },
        onError(e) {
            //this.$snotify.error(this.$i18n.t("erros.erro_desconhecido"));
        },
        alterarBinario(posicao) {
            let model = {
                posicaoBinario: posicao
            };

            if (this.dadosUsuario.posicaoBinario != posicao) {
                this.$loading(true);
                http.update('/network/alterBinaryPosition/', model).then((responde) => {
                    this.dadosUsuario.posicaoBinario = posicao;
                    this.$loading(false);
                },
                error => {
                    this.$loading(false);
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                });
            }

        },

        solicitarCartao() {
            this.$loading(true);
            http.post("/user/requestCard/").then(
                success => {
                    this.$snotify.success(this.$i18n.t("meus_dados.parabens_cartao"));
                },
                error => {
                    if (error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },

        verificarCartao() {
            http.get("/user/checkUserCard/").then(
                success => {
                    this.cartao = success.data;
                },
                error => {
                    // if (error.response && error.response.data)
                    //     this.$snotify.error(error.response.data.message);
                }
            );
        }
    }
}