import "./Graduacao.scss";
import http from '@/_helper/api-services';

export default {
    name: 'Graduacao',
    props: ['propBarraStatus', 'propPontosUsuario'],
    data() {
        return {
            dadosBarraStatus: {},
            pontosUsuario: {},
        }
    },
    watch: {
        propBarraStatus(el) {
            if (el.idUsuario == null || el.idUsuario == undefined)
                this.obterBarraStatus();
            else
                this.dadosBarraStatus = el;
        },
        propPontosUsuario(el) {
            this.pontosUsuario = el;
        }
    },
    mounted() {
        if(!this.$props.propBarraStatus)
            this.obterBarraStatus();
    },
    methods: {
        obterBarraStatus() {
            this.$loading(true);
            http.get('/Dashboard/getBarStatus/').then((responde) => {
                this.dadosBarraStatus = responde.data;
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                // else
                //     this.$snotify.error("An unexpected error has occurred");
            });
        }
    }
}