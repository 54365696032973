import "./solicitarSaque.scss";
import http from "@/_helper/api-services.js";
import { Money } from 'v-money'

export default {
    name: "solicitarSaque",
    data() {
        return {
            bloq: false,
            errorMessage: '',
            tiposSaque: [{ text: this.$i18n.t("tipos.Tipo de Saldo"), value: 0 }],
            listaCarteiras: [{ text: this.$i18n.t("saque.escolha_carteira"), value: null, dataCadastro: null, dataAprovacao: null, totalSaques: null }],
            form: {
                enderecoBTC: null,
                valor: null,
                idTipo: 1,
                senhaEletronica: String(),
                confirmarSenhaEletronica: String(),
                valor: 0,
                moeda: String(),
                tipoSaque: "rede",
                tipoChavePix: null
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '$ ',
                precision: 2,
                masked: false
            },
            tipoSaqueSelecionado: String(),
            resumoSaldo: [
                { saldo: String() },
                { saldo: String() }
            ],
            saquesSolicitados: [],
            selectedWallet: {},
            bloqueiaSaqueCashback: false,
            otpAtivo: false,
            acessoRemoto: false,
            listaSaldo: [
                { text: this.$i18n.t("saque.saldo_rede"), value: "rede" },
                { text: this.$i18n.t("saque.saldo_operacao"), value: "rendimento" }
                // { text: this.$i18n.t("saque.saldo_moeda"), value: "moeda" }
            ],
            //saldoSelecionado: 1,
            infoSaque:{}
        };
    },
    components: { Money },
    computed: {
        nameState() {
            return this.form.valor.length > 2 ? true : false
        },
    },
    props: ['showHeader', 'taxaSaque', 'valorMinimo'],
    beforeMount() {
        //this.getWithdrawalTypes();
        this.obterSaldos();
        this.obterCarteirasUsuario();
        this.verificarOTP();
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
    },
    methods: {
        getWithdrawalTypes() {
            http.get("/withdraw/listWithdrawalTypes").then(success => {
                if (success.data) {
                    success.data.forEach(item => {
                        this.tiposSaque.push({
                            text: this.$i18n.t("tipos." + item.descricao),
                            value: item.idTipo
                        });
                    });
                    if (this.tiposSaque.length < 3)
                        this.bloqueiaSaqueCashback = true;
                }
            }, error => {
                
            });
        },
        obterCarteirasUsuario() {
            http.get("/walletUser/getActiveWallets").then(response => {
                if (response.data.length > 0) {
                    response.data.forEach(item => {
                        this.listaCarteiras.push({
                            text: item.descricao + ' [' + item.endereco + ']',
                            value: item.endereco,
                            dataCadastro: item.dataCadastro,
                            dataAprovacao: item.dataAprovacao,
                            totalSaques: item.totalSaques,
                            tipoChavePix: item.tipoChavePix,
                            moeda: item.moeda
                        });
                    });
                }

            }, error => {
                
            });
        },
        obterSaquesSolicitados() {
            http.get("/withdraw/withdrawalsRequested").then(response => {
                this.saquesSolicitados = response.data;
            }, error => {
                //this.$snotify.error(this.$i18n.t("erros.erro_desconhecido"));
            });
        },
        // async verifyWallet(WalletAddress) {
        //     this.address = WalletAddress
        //     await http.get(`/withdraw/validateBTC/${this.address}`).then((response) => {
        //         this.resultWallet = response.data.result.code;
        //     });
        //     return this.resultWallet;
        // },
        // async validate() {
        //     let test = await this.verifyWallet(this.form.enderecoBTC);
        //     if (test == 1000) {
        //         this.$snotify.error(this.$i18n.t("erros.carteira_invalida"));
        //         return
        //     }
        // },
        verificarOTP() {
            http.get("/User/verifyOtp").then(response => {
                this.otpAtivo = response.data;
            }, error => {
            });
        },
        formatValor(valor) {
            if(!valor)
                return '$0,00';

            return valor.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
        solicitarSaque() {
            this.errorMessage = '';
            this.$loading(true);
            if (this.bloq == true) {
                this.$snotify.error(this.$i18n.t("status.Em processamento"));
                this.$loading(false);
                return;
            }

            this.bloq = true;


            if (this.acessoRemoto) {
                this.$snotify.error(this.$i18n.t("generico.aviso_acesso_remoto"));
                this.$loading(false);
                this.bloq = false;
                return;
            }
            this.form.valor = parseFloat(this.form.valor);

            http.post("/withdraw/requestWithdrawal", this.form).then(response => {
                if (response.data.status) {
                    this.$snotify.success(response.data.message);
                    this.fecharModal();
                    this.obterSaldos();
                    this.$emit('atualizaHistorico');

                    this.form = {
                        enderecoBTC: null,
                        valor: null,
                        idTipo: 1,
                        senhaEletronica: String(),
                        confirmarSenhaEletronica: String(),
                        valor: 0
                    }
                }
            }, error => {
                this.errorMessage = error.response.data;
                this.$bvModal.show('modalError');
            }).finally(() => {
                this.fecharModal();
                this.$loading(false);
                this.bloq = false;
            });;
        },
        obterSaldos() {
            http.get("/extract/summaryBalance").then(response => {
                this.resumoSaldo = response.data;
            }, error => {
            });
        },
        buscarTipoSelecionado(idTipo) {
            this.tipoSaqueSelecionado = this.tiposSaque.filter(f => f.value == idTipo)[0].text;;
        },
        fecharModal() {
            this.form.confirmarSenhaEletronica = '';
            this.form.senhaEletronica = '';

            this.$bvModal.hide('modalSenhaFinanceira');
        },
        fecharModalErro() {
            this.$bvModal.hide('modalError');
        },
        abrirModal() {
           if (this.form.valor < this.$props.valorMinimo) {
                this.$snotify.error(this.$i18n.t("saque.valor_min") + this.formatValor(this.$props.valorMinimo));
            }
            else if (this.form.tipoSaque == '') {
                this.$snotify.error(this.$i18n.t("erros.obrigatorio"));
            }
            else {
                this.selectedWallet = this.listaCarteiras.filter(item => item.value === this.form.enderecoBTC)[0];
                this.form.tipoChavePix = this.selectedWallet.tipoChavePix;
                this.form.moeda = this.selectedWallet.moeda;
                this.calculaTaxa(this.form.valor);
                this.$bvModal.show('modalSenhaFinanceira');
            }
        },
        formatDate(date) {
            return `${new Date(date).toLocaleDateString()} ${new Date(
                date
            ).toLocaleTimeString()}`;
        },
        calculaTaxa(valorSaque){
            this.$loading(true);
            http.get(`/withdraw/verifyFee/${valorSaque}`).then((response) => {
                this.infoSaque = response.data;
            },error => {
                this.errorMessage = error.response.data;
                this.$snotify.error(this.errorMessage);
            }).finally(() => {
                this.$loading(false);
            });;
        },
        casasDecimais(num, precisao) {
            var casas = Math.pow(10, precisao);
            return Math.floor(num * casas) / casas;
        }
    }
}