<template>
    <div class="not-found">
        <div class="div-error" v-if="tipoPage == 'error'">
            <h5>Ops!</h5>
            <p>Page not found</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'notfound',
    data() {
        return {
            tipoPage: 'error'
        }
    },
    mounted(){
        let tipoPagina = this.$route.params.strId;        
        if (tipoPagina != '' && typeof tipoPagina != 'undefined') {
           this.tipoPage = 'logout';
            this.$store.dispatch('autenticacao/logout');
        }
        
        this.$loading(false);
    }
};
</script>