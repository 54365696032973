import Vue from 'vue';
import Vuex from 'vuex';

import { autenticacao } from './autenticacao';
import { transporteDados } from './transporteDados';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        autenticacao,
        transporteDados
    }
});