import "./Store.scss";
import TopPage from "@/components/TopPage/TopPage.vue";
import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
import ListagemProdutosFisicos from "@/components/ListagemProdutosFisicos/ListagemProdutosFisicos.vue";

export default {
    name: "Store",
    components: {
        TopPage,
        LinkPatrocinador,
        PremiosDashboard,
        ListagemProdutosFisicos,
    },
    data() {
        return {
        };
    },
    beforeMount(){
    },
    mounted() {
        //this.$bvModal.show('modal-info');
    },
    methods: {
        //closeLimit() {
        //    this.$bvModal.hide('modal-info');
        //},
    }
}