import http from "@/_helper/api-services.js";
import util from "@/_helper/util.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from 'vue-multiselect'
import "./GerenciarGraduacao.scss";

export default {
    name: "GerenciarGraduacao",
    data() {
        return {
            nomeLogin: '',
            availableBalance: 0,
            valorBTCSelecionado: 0,
            pendingReceivedBalance: 0,
            listaPaises: [{ text: "Selecione o país", value: 0 }],
            listaGraduacoes: [{ text: "Selecione a graduação", value: 0 }],
            paisSelecionado: {},
            search: {
                login: String(),
                idGraduacao: 0,
                pagamentoEfetuado: null,
                quantidade: 50,
                page: 1
            },
            totalRows: 1,
            currentPage: 1,
            listStatus: [],
            statusPagamento: null,
            tipoPagamento: [
                { value: null, text: "Status pagamento"},
                { value: false, text: "Não pago"},
                { value: true, text: "Pago"}
            ],
            fields: [
                { key: "selecionado", label: "", sortable: false },
                { key: "login", label: "Login", sortable: true },
                { key: "nome", label: "Nome", sortable: true },
                { key: "vip", label: "VIP", sortable: true },
                { key: "nomeGraduacao", label: "Nome Graduação" },
                { key: "dataGraduacao", label:"Data Graduação", sortable: true},
                { key: "pagamentoEfetuado", label: "Pagamento Efetuado" , sortable: true }
            ],
            list: [],
            valorSelecionado: 0,
            selected: [],
            perPage: 50,
            pageOptions: [50, 100, 150, {
                value: 99999,
                text: "show all"
            }],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            action: "",
            filterOn: [],
            todosSelecionados: false,
            usuarioConfiguracao: {
                idUsuarioConfiguracao: 0,
                nomeUsuario: String(),
                idUsuario: String(),
                taxaSaque: Number(),
            },
            lang: {
                days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                months: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez",],
            },
            pagamentoManual: {
                idSaque: 0,
                urlTransacao: String(),
            },
            cotacaoBlockChain: Number(),
            twoFactorCode: String()
        };
    },
    components: {
        DatePicker,
        Multiselect
    },

    beforeMount() {
        this.buscarGraduacao();
        //this.buscarStatusSaque();
        //this.buscarResumoSaque();
        this.listarGraduacoes();
    },
    mounted() {
        //this.obterNomeLogado();
    },
    watch: {
        perPage() {
            this.search.page = 1;
            this.buscarGraduacao();
        },
        "search.page"() {
            this.buscarGraduacao();
        },
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        listarGraduacoes(){
            http.get("/Admin/listGraduations").then((response) => {
                response.data.forEach(item => {
                    this.listaGraduacoes.push({
                        text: item.nome,
                        value: item.idGraduacao
                    });
                })
            })
        },
        atualizarPagamento(){
            this.$loading(true);
            http.post("").then((response) => {
            },
            (error) => {
                this.$snotify.error("Sem permissão para consultar saldo");
                this.$loading(false);
            }).finally(() => {
                this.$loading(false);
            });
        },
        aprovarSelecionados() {
            if (this.list.filter((c) => c.selecionado === true).length == 0) {
                this.$snotify.error("Nenhum item selecionado");
            } else {
                this.editModal();
            }
        },
        pagarSelecionados() {
            this.$loading(true);
            this.valorSelecionado = 0;
            this.valorBTCSelecionado = 0;
            if (this.list.filter((c) => c.selecionado === true).length == 0) {
                this.$snotify.error("Nenhum item selecionado");
                this.$loading(false);
            } else {

                http.get("/withdraw/valueInWallet").then(
                    (success) => {
                        var selected = this.list.filter((c) => c.selecionado === true);

                        for (let index = 0; index < selected.length; index++) {
                            var element = selected[index];
                            this.valorSelecionado += element.valorReal;
                        }
                        this.valorBTCSelecionado = (1 / this.cotacaoBlockChain) * this.valorSelecionado;
                        this.availableBalance = success.data.availableBalance;
                        this.pendingReceivedBalance = success.data.pendingReceivedBalance;
                        this.$bvModal.show("modalSaquesSelecionados");
                        this.$loading(false);
                    },
                    (error) => {
                        this.$snotify.error("Sem permissão para consultar saldo");
                        this.$loading(false);
                    }
                );
            }
        },
        cancelarSelecionados() {
            if (this.list.filter((c) => c.selecionado === true).length == 0) {
                this.$snotify.error("Nenhum saque selecionado");
            } else {
                this.openModal("cancelar");
            }
        },
        selecionarTodos() {
            this.buscarGraduacao();
        },
        selecionarTodosSplit(lista, de, ate) {
            if (ate > this.list.length)
                ate = this.list.length;

            for (let index = de; index < ate; index++) {
                lista[index].selecionado = this.todosSelecionados;
            }
            return lista;
        },
        confirmarAcao() {
            this.$loading(true);
            var selecionados = [];
            this.list
            .filter((c) => c.selecionado === true)
            .forEach((l) => {
                selecionados.push({
                    idGanhadoresPremio: l.idGanhadoresPremio,
                });
            });
            
            http.post("/admin/modifyPaymentStatus", selecionados).then(
                (success) => {
                    this.$snotify.success("Alteração feita com sucesso!");
                    this.$bvModal.hide("modalEditarPagamento");
                    this.selected = [];
                    this.buscarGraduacao();
                    this.$loading(false);
                },
                (error) => {
                    this.$loading(false);
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    )
                        this.$snotify.error(error.response.data.message);
                }
            );
        },
        selecionados(item) {
            this.selected = this.list.filter((c) => c.selecionado === true);
        },
        totalSelecionado() {
            return this.list.filter((c) => c.selecionado === true).length;
        },
        buscarGraduacao(event) {
            if (event) event.preventDefault();
            this.$loading(true);
            this.selected = [];
            this.search.quantidade = this.perPage;
            //this.statusPagamento = this.search.pago;
            
            http.post("/admin/manageGraduates", this.search).then(
                (success) => {
                    this.list = [];
                    if (success && success.data) {
                        success.data.forEach((d) => {
                            this.list.push({
                                selecionado: this.todosSelecionados,
                                idGanhadoresPremio: d.idGanhadoresPremio,
                                login: d.login,
                                nome: d.nome,
                                nomeGraduacao: d.nomeGraduacao,
                                pagamentoEfetuado: d.pagamentoEfetuado,
                                dataGraduacao: d.dataGraduacao,
                                vip: d.vip
                            });
                        });
                        this.totalRows = this.list.length;
                    }
                    this.selected = [];
                    this.$loading(false);
                },
                (error) => {
                    this.$loading(false);
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    )
                        this.$snotify.error(error.response.data.message);
                }
            );
        },
        fecharModal() {
            this.$bvModal.hide("modal-confirm");
        },
        openModal(tipo) {
            this.action = tipo;
            this.$bvModal.show("modal-confirm");
        },
        editModal(){
            this.$bvModal.show("modalEditarPagamento");
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
        },
        formatDate(data) {
            let dateTime = new Date(data).toLocaleDateString();
            return dateTime;
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        handleSubmit(event) {
            event.preventDefault();
            this.$loading(true);
            this.pagamentoManual.idSaque = +this.pagamentoManual.idSaque;
            http
                .post("/withdraw/paymentManual", this.pagamentoManual)
                .then(
                    (success) => {
                        if (success.data && success.data.message) {
                            this.$snotify.success(success.data.message);
                            this.closeModalPagamentoManual();
                            this.buscarGraduacao();
                        }
                    },
                    (error) => {
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.errors
                        )
                            error.response.data.errors.forEach((message) =>
                                this.$snotify.error(message)
                            );
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        
        openModalPagamentoManual(idSaque) {
            this.$root.$emit("bv::hide::popover");
            this.pagamentoManual.idSaque = +idSaque;
            this.$bvModal.show("modalPagarManual");
        },
        closeModalPagamentoManual() {
            this.$bvModal.hide("modalPagarManual");
            this.pagamentoManual.idSaque = 0;
        },
        closeModalPagarNovamente() {
            this.$bvModal.hide("modalPagarNovamente");
            this.pagamentoManual.idSaque = 0;
        },
        openModalPagamentoManual() {
            this.$bvModal.show("modalSaquesAprovado");
        },
        openPagamentoManual(idSaque) {
            this.pagamentoManual.idSaque = +idSaque;
            this.$bvModal.show("modalPagarManual");
        },
        openProcessarNovamente(idSaque) {
            this.pagamentoManual.idSaque = +idSaque;
            this.$bvModal.show("modalPagarNovamente");
        },
        async pagarSaquesAprovados(event) {
            if (event) event.preventDefault();
            this.$loading(true);
            await http.post("/withdraw/payApprovedWithdrawals", { cotacao: +this.cotacaoBlockChain, codigo: this.twoFactorCode })
                .then(
                    (success) => {
                        if ((success.status = 200)) {
                            this.$snotify.success("Pagamentos efetuados com sucesso");
                            this.$bvModal.hide("modalSaquesAprovado");
                            this.buscarGraduacao();
                        } else {
                            this.$snotify.error("Erro ao efetuar pagamentos");
                        }
                    },
                    (error) => {
                        this.$snotify.error(error.response.data);
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },

        async pagarSaquesSelecionados(event) {
            if (event) event.preventDefault();
            this.$loading(true);
            var selecionados = [];
            this.list
                .filter((c) => c.selecionado === true)
                .forEach((l) => {
                    selecionados.push({
                        idSaque: l.idSaque,
                    });
                });

            if (selecionados.length == 0) {
                this.$snotify.error("Nenhum saque selecionado.");
                this.$loading(false);
                return false;
            }

            await http.post("/withdraw/paySelectedWithdrawals", { lista: selecionados, cotacao: +this.cotacaoBlockChain, codigo: this.twoFactorCode })
                .then(
                    (success) => {
                        if ((success.status = 200)) {
                            this.$snotify.success("Pagamentos enviados para a fila de processamento e logo estarão finalizados.");
                            this.$bvModal.hide("modalSaquesSelecionados");
                            this.buscarGraduacao();
                        } else {
                            this.$snotify.error("Erro ao efetuar pagamentos");
                        }
                        this.$loading(false);
                    },
                    (error) => {
                        this.$snotify.error(error.response.data);
                        this.$loading(false);
                    }
                );
        },
    },
};