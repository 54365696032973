import "./MenuMobile.scss";
import http from "@/_helper/api-services.js";
// import apiService from "@/_helper/api-services.js";
import Drawer from "vue-simple-drawer";
import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";
import ListaIdioma from '@/components/ListaIdioma/ListaIdioma.vue';

export default {
    name: "MenuMobile",
    components: {
        Drawer,
        LinkPatrocinador,
        ListaIdioma
    },
    data() {
        return {
            dadosUser: {},
            resumoValores: {},
            dadosUsuario: {},
            languages: [
                { value: 'alemao', text: 'alemao'},
                { value: 'chines', text: 'chines' },
                { value: 'coreano', text: 'coreano' },
                { value: 'espanhol', text: 'espanhol' },
                { value: 'filipino', text: 'filipino' },
                { value: 'frances', text: 'frances' },
                { value: 'georgian', text: 'georgian' },
                { value: 'grego', text: 'grego' },
                { value: 'hebrew', text: 'hebrew' },
                { value: 'ingles', text: 'ingles' },
                { value: 'india', text: 'india' },
                { value: 'italy', text: 'italy' },
                { value: 'japones', text: 'japones' },
                { value: 'polish', text: 'polish' },
                { value: 'portugues', text: 'portugues' },
                { value: 'romania', text: 'romania' },
                { value: 'russo', text: 'russo' },
                { value: 'tailandes', text: 'tailandes' },
                { value: 'vietnam', text: 'vietnam' }
            ],
            shortCut: '',
            usuarioLogado: '',
            open: false,
            align: "right",
            innerOpen: false,
            mask: false,
            maskClosable: false,
            close: true,
        };
    },
    watch: {
        idUsuario(el) {
            if (el) {
                var menu = localStorage.getItem("menu");
                if (menu) {
                    this.menus = JSON.parse(menu).filter(u => !u.rotaPublica);
                }
            }
        }
    },
    props: ["idUsuario", "exibir", "emAcessoRemoto"],

    beforeMount() {
        this.obterResumoValores();
        if (!localStorage.getItem("language")) {
            localStorage.setItem('language', this.$i18n.locale);
        }
        this.$i18n.locale = localStorage.getItem("language");
        this.loggedIn = localStorage.getItem('user');
        this.dadosUser = this.$store.getters['autenticacao/getLogin'].user;
        if (this.loggedIn) {
            this.getNotifications();
            // if (localStorage.getItem("showComunicado") == "true")
            //     this.getAnnouncements();
        }
    },
    mounted() {
        var menu = localStorage.getItem("menu");
        if (menu) {
            this.menus = JSON.parse(menu).filter(u => !u.rotaPublica);
        }
        this.dadosUser = this.$store.getters['autenticacao/getLogin'].user;
        this.obterResumoValores();
        this.obterDadosUsuario();
    },
    methods: {
        toggle() {
            this.open = !this.open
            if (!this.menus) {
                var menu = localStorage.getItem("menu");
                this.menus = JSON.parse(menu).filter(u => !u.rotaPublica);
                this.dadosUser = this.$store.getters['autenticacao/getLogin'].user;
                
                this.obterResumoValores();
            }
        },
        closeDrop() {
            this.open = false;
        },
        obterResumoValores() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                http.get('/Dashboard/balance/').then((responde) => {
                    this.resumoValores = responde.data;
                    this.shortCut = this.resumoValores.userName[0][0].toUpperCase();
                    this.$loading(false);
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }
        },
        obterDadosUsuario() {
            const loggedIn = localStorage.getItem("user");
            if (loggedIn) {
                this.$loading(true);
                http.get('/Dashboard/getBarStatus/').then((responde) => {
                    this.dadosUsuario = responde.data;
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                    // else
                    //     this.$snotify.error("An unexpected error has occurred");
                });
            }
        },
        formatPrice(valor) {
            if (!valor)
                return '$0,00';

            return this.casasDecimais(valor,2).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        },
        casasDecimais(num, precisao) {
            var casas = Math.pow(10, precisao);
            return Math.floor(num * casas) / casas;
        },
        changeLanguage(value) {
            localStorage.setItem('language', value);

            //Salva preferencia na API
            if (this.loggedIn) {
                this.changeUserLanguage(value);
            }
        },
        changeUserLanguage(idioma) {
            var dados = { cultura: idioma };

            http.post("/user/changeLanguage", dados).then(response => {
                if (response.data.status) {
                    this.$snotify.success(response.data.message);
                    
                }
                
            }, error => {
                this.$snotify.error(error.response.data);
            }).finally(() => {
                this.$loading(false);
                window.location.reload();
            });;
        },
        getNotifications() {
            http.get("/announcement/getUnreadNotifications").then(
                success => {
                    if (success.data) {
                        success.data.forEach(n => n.lidaFront = n.lida);
                        this.notifications = success.data
                    }
                },
                error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                }
            );
        }
    },
}