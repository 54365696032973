import { FormWizard, TabContent } from "vue-form-wizard";

import ThemifyIcon from "vue-themify-icons";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "./Formulario.css";

import http from "@/_helper/api-services.js";

export default {
  data() {
    return {
      msg: [],
      formValid: true,
      idCurso: 0,
      curso: {
        idCurso: 0,
        titulo: null,
        descricao: null,
        ativo: Boolean(false),
        modulos: [],
        videos: [],
      },
      modulo: {
        idModulo: 0,
        idCurso: 0,
        titulo: null,
        descricao: null,
        ativo: false,
      },
      video: {
        idVideo: 0,
        idCurso: 0,
        idModulo: null,
        titulo: null,
        descricao: null,
        url: null,
        ativo: false,
      },
    };
  },
  components: {
    FormWizard,
    TabContent,
    ThemifyIcon,
  },
  beforeMount() {
    this.$loading(true);
    this.idCurso = this.$route.params.idCurso;
    this.buscarCurso(this.idCurso);
  },
  methods: {
    onComplete: function () {
      if (this.curso.titulo == null || this.curso.titulo == "") {
        this.$snotify.error("Preencha o campo: Título");
        return false;
      }
      if (this.curso.descricao == null || this.curso.descricao == "") {
        this.$snotify.error("Preencha o campo: Descrição");
        return false;
      }
      if (this.curso.urlVideo == null || this.curso.urlVideo == "") {
        this.$snotify.error("Preencha o campo: URL");
        return false;
      }

      if (this.curso.idCurso == 0) {
        http
            .post("/courses/", this.curso)
          .then((response) => {
            if (response.status == 200)
              this.$router.push("/admin/gerenciar-cursos");
          })
          .catch((error) => {
            this.$snotify.error(error.response.data);
          })
          .finally(() => {
            this.$loading(false);
          });
      } else {
        this.$loading(true);
        http
            .update("/courses/" + this.idCurso, this.curso)
          .then((response) => {
            if (response.status == 200)
              this.$router.push("/admin/gerenciar-cursos");
          })
          .catch((error) => {
            this.$snotify.error(error.response.data);
          })
          .finally(() => {
            this.$loading(false);
          });
      }

      return true;
    },
    beforeTabCurso: function () {
      if (this.curso.titulo == null || this.curso.titulo == "") {
        this.$snotify.error("Preencha o campo: Título");
        return false;
      }
      if (this.curso.descricao == null || this.curso.descricao == "") {
        this.$snotify.error("Preencha o campo: Descrição");
        return false;
      }
      return true;
    },
    adicionarModulo: function () {
      if (this.modulo.titulo == null || this.modulo.titulo == "") {
        this.$snotify.error("Preencha o campo: Título");
        return false;
      }
      var modulo = this.modulo;
      //modulo.curso = this.curso;

      this.curso.modulos.push(modulo);

      this.modulo = {};
      return true;
    },
    removerModulo: function (index) {
      this.curso.modulos.splice(index, 1);
    },
    adicionarVideo: function () {
      if (this.video.titulo == null || this.video.titulo == "") {
        this.$snotify.error("Preencha o campo: Título");
        return false;
      }
      if (this.video.descricao == null || this.video.descricao == "") {
        this.$snotify.error("Preencha o campo: Descrição");
        return false;
      }
      if (this.video.url == null || this.video.url == "") {
        this.$snotify.error("Preencha o campo: URL");
        return false;
      }
      if (this.curso.modulos.length > 0) {
        if (this.video.idModulo == null) {
          this.$snotify.error("Selecione um módulo");
          return false;
        }
      }
      var video = this.video;
      //video.curso = this.curso;

      this.curso.videos.push(video);

      this.video = {};
      return true;
    },
    removerVideo: function (index) {
      this.curso.videos.splice(index, 1);
    },
    buscarCurso(id) {
      if (id == "") return false;

      this.$loading(true);
        http.get("/courses/" + id).then((response) => {
        if (response.status == 200) {
          this.curso.idCurso = response.data.idCurso;
          this.curso.titulo = response.data.titulo;
          this.curso.descricao = response.data.descricao;
          this.curso.urlVideo = response.data.urlVideo;
          this.curso.ativo = response.data.ativo;
        }

        if (!this.curso.modulos.length)
          this.curso.modulos.push({ titulo: "Vídeos" });

        this.curso.videos.push({
          titulo: this.curso.titulo,
          url: this.curso.url,
        });

        this.$loading(false);
      });
    }
  }
}
