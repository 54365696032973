import "./formRecuperarSenha.scss";
import usuarioService from "@/_helper/usuario-service.js";
import formLogin from "../FormLogin/formLogin";

export default {
    name: "formRecuperarSenha",
    data() {
        return {
            email: String(),
            emailSent: false,
            emailSentMessage: String()
        };
    },
    methods: {
        resetPassword() {
            if (!this.email || this.email == '') {
                this.$snotify.error(this.$i18n.t("erros.usuario_nao_informado"));
            }
            else {
                
                usuarioService.resetPassword(this.email).then(
                    success => {
                        this.emailSent = true;
                        this.emailSentMessage = this.$i18n.t("cadastro.recuperar_senha");
                        this.$loading(true);
                    }, error => {
                        if (error.response.data && error.response.data.message)
                            this.$snotify.error(error.response.data.message);
                    }).finally(() => { this.$loading(false); });
            }
        },
        switchTo(action) {
            this.$emit("switchPanel", action);
        }
    }
}