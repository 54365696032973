import alemao from './german.json'
import chines from './chinese.json'
import coreano from './korean.json'
import espanhol from './spanish.json'
import filipino from './filipino.json'
import frances from './french.json'
import georgian from './georgian.json'
import grego from './grego.json'
import hebrew from './hebrew.json'
import ingles from './english.json'
import india from './hindi.json'
import italy from './italiano.json'
import japones from './japones.json'
import polish from './polish.json'
import portugues from './portuguese.json'
import romania from './romania.json'
import russo from './russian.json'
import tailandes from './thailandese.json'
import vietnam from './vietnamese.json'


export const defaultLocale = 'ingles'

export const languages = {
  alemao: alemao,
  chines: chines,
  coreano: coreano,
  espanhol: espanhol,
  filipino: filipino,
  frances: frances,
  georgian: georgian,
  grego: grego,
  hebrew: hebrew,
  ingles: ingles,
  india: india,
  italy: italy,
  japones: japones,
  polish: polish,
  portugues: portugues,
  romania: romania,
  russo: russo,
  tailandes: tailandes,
  vietnam: vietnam
}