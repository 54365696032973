import "./semPermissao.scss";

export default {
    name: "semPermissao",
    data() {
        return {};
    },
    mounted(){
        this.$loading(false);
    }
}