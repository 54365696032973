import "./confirmSaque.js";
import usuarioService from "@/_helper/usuario-service.js";

export default {
    name: "ConfirmSaque",
    data() {
        return {};
    },
    mounted() {
        if (this.$route.params.token) {
            usuarioService.confirmSaque(this.$route.params.token).then(
                success => {
                    this.$snotify.success(this.$i18n.t("saque.saque_validado"), { position: "rightTop" });
                    this.$router.push("/#/index");
                },
                error => {
                    this.$snotify.error(this.$i18n.t("erros.saque_nao_validado"), { position: "rightTop" });
                    this.$router.push("/#/index");
                });
        }
    }
}