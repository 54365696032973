import http from '@/_helper/api-services'
import './AtivarContas.scss'

export default {
    name: 'AtivarContas',
    data() {
        return {
            nomeLogin: '',
            dadosUser: this.$store.getters['autenticacao/getLogin'].user,
            listProdutos: [],
            listTipos: [],
            usuarioPedido: {
                IdProduto: 0,
                Login: null,
                TipoAtivacao: 0
            },
            erroForm: false
        }
    },
    components: {
    },
    mounted() {
        //this.obterNomeLogado();
        this.$loading(true);
        this.listarProdutos();
        this.carregarTiposPagamento();
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        listarProdutos() {
            this.$loading(true);
            http.get('/product/searchActive').then((responde) => {
                responde.data.forEach(item => {
                    this.listProdutos.push({
                        value: item.idProduto,
                        text: item.nome + ' - U$ ' + item.valor
                    })
                });
                this.$loading(false);
            });
        },
        carregarTiposPagamento() {
            this.listTipos.push({ value: 3, text: this.$i18n.t("admin.ativar_contas.txt_ativacao_manual") })
            this.listTipos.push({ value: 4, text: this.$i18n.t("admin.ativar_contas.txt_voucher_01") })
            this.listTipos.push({ value: 5, text: this.$i18n.t("admin.ativar_contas.txt_voucher_02") })
            this.listTipos.push({ value: 11, text: this.$i18n.t("admin.ativar_contas.txt_voucher_03") })
            this.listTipos.push({ value: 10, text: this.$i18n.t("admin.ativar_contas.txt_voucher_04") })
            this.$loading(false);
        },
        ativarPacote() {
            this.$loading(true);
            if (this.checkForm()) {
                http.post('/requests/activatePackage', this.usuarioPedido).then((response) => {
                    if (response.data && response.data.idPedido) {
                        this.$snotify.success("Pacote ativo para o usuário [" + this.usuarioPedido.Login + "].");
                        this.$bvModal.hide('modal-banco');
                    } else {
                        this.$snotify.error(this.$i18n.t("erros.erro_efetivar_compra"));
                    }
                }, error => {
                    if (error.response && typeof error.response.data == "object")
                        this.$snotify.error(error.response.data.message);
                }).finally(() => { this.$loading(false); });
            }else
                this.$loading(false);
        },
        checkForm() {
            if (!this.usuarioPedido.IdProduto || !this.usuarioPedido.Login) {

                this.erroForm = true;
                return false;
            }

            return true;
        }
    }
}