import http from '@/_helper/api-services'
import TopPage from "@/components/TopPage/TopPage.vue";
import LinkPatrocinador from "@/components/LinkPatrocinador/LinkPatrocinador.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
import './EAcademy.scss'

export default {
    name: 'EAcademy',
    data() {
        return {
        
        }
    },
    components: {
        TopPage,
        LinkPatrocinador,
        PremiosDashboard
    },
}