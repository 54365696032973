import "./LancamentoPontos.scss"
import http from '@/_helper/api-services'

export default {
    name: 'LancamentoPontos',
    data() {
        return {
            nomeLogin: '',
            search: {
                login: String(),
                email: String(),
                ativo: true,
                quantidade: 20,
                page: 1
            },
            currentPage: 1,
            totalPages: 0,
            totalRows:0,
            perPage: 25,
            pageOptions: [25, 50, 100],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            users: [],
            user: {},

            lancamento: {
                valor: Number(),
                login: String(),
                lado: 0
            },
            fields: [
                { key: "nome", label: "Nome"},
                { key: "login", label: "Login" },
                { key: "patrocinador", label: "Patrocinador"},
                { key: "acoes", label: "#", style: "widht: 100px" },
            ],

            lados: [
                {
                    text: "Esquerdo",
                    value: 0,
                },
                {
                    text: "Direito",
                    value: 1,
                }
            ],
            showIconsToChangePage: false

        }
    },
    components: {
    },
    computed: {
        
    },
    mounted() {
        //this.obterNomeLogado();
        this.$loading(false);
    },
    async beforeMount() {
        // await this.buscarTipos();
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        async searchUsers() {
            if (!this.search.login && !this.search.email) {
                this.$snotify.error("Enter login to search.");

                return;
            }
            this.$loading(true);
            this.search.quantidade = this.perPage;
            await http.post("/admin/filterUsers", this.search)
                .then(
                    success => {
                        this.users = [];
                        if (success && success.data) {

                            success.data.forEach(d => {
                                this.users.push({
                                    login: d.login,
                                    nome: d.nome,
                                    patrocinador: d.patrocinador,
                                });
                            });
                            this.totalRows = success.data.length;
                            this.$loading(false);
                        }
                    },
                    error => {
                        this.$loading(false);
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                );
        },
        gerarLancamento() {
            this.$loading(true);
            this.lancamento.valor = +this.lancamento.valor;
            this.lancamento.login = this.user.login;

            http.post('/launch/generateLaunchPoints', this.lancamento).then((response) => {
                this.$snotify.success(response.data);
                this.limparCampos();
            }, error => {
                if (error.response.data)
                    this.$snotify.error(error.response.data);
            }).finally(() => { this.$loading(false); });
        },
        limparCampos() {
            this.lancamento.valor = "";
            this.lancamento.login = "";
            this.lancamento.descricao = "";
        },
        abrirModal(user){
            this.user = user;
            this.$bvModal.show('modal-pontos');
        },
        cancel()
        {
            this.user = {};
            this.$bvModal.hide('modal-pontos');
        },
        alterarPagina(pagina) {
            this.search.page = pagina;
            this.searchUsers(event);
        },
    }
}