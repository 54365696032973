import "./GerenciarCursos.css";
import http from "@/_helper/api-services.js";

export default {
  data() {
    return {
      cursos: [],
      nameState: null,
      submittedNames: [],
      show: true,
      modalShow: false,
      cursoAtivar: false,
      status: "not_accepted",
      curso: {
        idCurso: Number(),
      },
    };
  },
  beforeMount() {
    this.$loading(true);
    this.buscarCurso();
  },
  mounted() {
    this.$loading(false);
  },
  methods: {
    buscarCurso() {
      this.$loading(true);
      http.get("/courses/").then((response) => {
        if (response.status == 200) {
          this.cursos = response.data;
        }
        this.$loading(false);
      });
    },
  },
};
