import http from '@/_helper/api-services';
import './FotoPerfil.scss'

export default {
    name: 'FotoPerfil',
    props: ['fotoPerfil'],
    data() {
        return {
            dadosUsuario: {},
            file: null,
        }
    },
    components: {
    },
    mounted() {
        this.$loading(false);
    },
    methods: {
       
    }
}