import "./comunicados.scss";
import http from "@/_helper/api-services.js";

export default {
    name: "comunicados",
    data() {
        return {
            nomeLogin: '',
            fields: [
                { key: "icone", label: "Ícone", sortable: false },
                { key: "titulo", label: "Titulo", sortable: true },
                {
                    key: "dataInicio", label: "Data inicio", sortable: true, formatter: value => {
                        if (!value) return "";
                        return `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString()}`;
                    }
                },
                {
                    key: "dataFim", label: "Data fim", sortable: true, formatter: value => {
                        if (!value) return "";
                        return `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString()}`;
                    }
                },
                { key: "actions", label: "", sortable: false }
            ],
            items: Array(),
            totalRows:0,
            perPage: 5,
            perPageOptions: [5, 10, 15],
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: "asc",
        };
    },
    components: {
    },
    beforeMount() {
        this.getComunicados();
    },
    mounted() {
        //this.obterNomeLogado();
        this.$loading(false);
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            if(logado){
                this.dadoLogado = JSON.parse(logado);
                this.nomeLogin = this.dadoLogado.username.split(' ')[0];
            }
        },
        getComunicados() {
            http.get("/announcement/announcementList").then(
                success => {
                    if (success && success.data) {
                        success.data.forEach(d => {
                            this.items.push({
                                idMensagem: d.idMensagem,
                                titulo: d.titulo,
                                dataInicio: d.dataInicio,
                                dataFim: d.dataFim,
                                imagem: d.urlArquivo,
                                ativo: d.ativo
                            });
                        });
                        this.totalRows = success.data.length;
                    }
                }, error => {
                    if (error && error.response && error.response.data)
                        this.$snotify.error(error.response.data.message);
                   s
                }
            );
        },
        ativaDesativaComunicado(idMensagem) {
            this.$loading(true);
            http.update("/announcement/activateDeactivateMessage", { idMensagem }).then(
                success => {
                    this.$snotify.success(success.data.message);
                },
                error => {
                    if (error && error.response && error.response.data)
                        this.$snotify.success(error.response.data.message);
                }
            ).finally(() => {
                this.$loading(false);
            });
        },
        comunicadoSelecionado(record) {
            this.$router.push(`/admin/communicated/cadastrar/${record.idMensagem}`);
        }
    }
}