import http from '@/_helper/api-services'
import SideBar from "@/components/SideBar/SideBar.vue";
import treeviewItem from "@/components/TreeviewItem/TreeviewItem.vue";
import PremiosDashboard from '@/components/PremiosDashboard/PremiosDashboard.vue';
import './MinhaRede.scss'

export default {
    name: 'MinhaRede',
    data() {
        return {
            listDados: [],
            //sdadosUsuario: {},
            nomeLogin: '',
            login: null,
            networkData: {} 
        }
    },
    components: {
        SideBar,
        PremiosDashboard,
        treeviewItem
    },
    filters: {
        dataFormatada: function (valor) {
            return moment(String(valor)).format('DD/MM/YYYY');
        }
    },
    beforeMount() {
        this.$loading(true);
        //this.obterDadosUsuario();
    },
    mounted() {
        this.obterNomeLogado();
        this.listarDados();
        this.dadosRede();
    },
    
    methods: {
        dadosRede(){
            http.get('/network/getNetworkData').then((response) => {
                this.networkData = response.data;
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
            })
        },
        filtrar(){
            this.$loading(true); 
            if(this.login == '' || !this.login){
                this.listDados = [];
                this.listarDados();
            } 
            else {
                let dado = {
                    login : this.login
                }    
                http.post('/network/searchUserNetwork/', dado).then((responde) => {
                    this.listDados = [];
                    
                    responde.data.forEach(item => {
                        this.listDados.push({
                            idUsuario: item.idUsuario,
                            nome: item.nome,
                            login: item.login,
                            graduacao: item.graduacao,
                            email: item.email,
                            temFilhos: item.temFilhos,
                            investimento: item.investimento,
                            pontos: item.pontos,
                            celular: item.celular,
                            nivel: item.nivel
                        });
                    }); 
                    this.$loading(false);
                }, error => {
                    this.$loading(false);
                });
            } 
            // if(this.filter != ''){
            //     this.listDados = this.listDados.filter(item => !item.nome.toLowerCase().indexOf(this.filter.toLowerCase() ))
            // }
            // else{
            //     this.listDados = [];
            //     this.listarDados();
            // }
        },
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        // obterDadosUsuario() {
        //     http.get('/Dashboard/getBarStatus/').then((responde) => {
        //         this.dadosUsuario = responde.data;
        //     });
        // },
        listarDados(){ 
            this.$loading(true);           
            http.get('/user/directUserLogin').then((responde) => {
                this.listDados = [];

                responde.data.forEach(item => {
                    this.listDados.push({
                        idUsuario: item.idUsuario,
                        nome: item.nome,
                        login: item.login,
                        graduacao: item.graduacao,
                        email: item.email,
                        temFilhos: item.temFilhos,
                        investimento: item.investimento,
                        pontos: item.pontos,
                        celular: item.celular,
                        nivel: item.nivel,
                        valorContratosDiretos: item.valorContratosDiretos
                    });
                }); 
                this.$loading(false);
            },error => {
                if (error.response && error.response.data && error.response.data.errors)
                    error.response.data.errors.forEach(e => {
                        this.$snotify.error(e);
                    });
                
            });
        }
    }
}