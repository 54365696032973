import Formulario from "../Components/Formulario.vue";

export default {
  data() {
    return {
      curso: {
        titulo: "",
        descricao: "",
        ativo: Boolean(false),
      },
    };
  },
  beforeMount() {
    this.$loading(true);
  },
  mounted() {
    this.$loading(false);
  },
  components: {
    Formulario,
  },
  methods: {},
};
