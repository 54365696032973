<template>
    <form-wizard title="" subtitle="" @on-complete="onComplete">
        <tab-content>
            <img class="img-step" src="https://blobinsight.blob.core.windows.net/gobets/images/1.jpeg" alt="" />
        </tab-content>
        <tab-content>
            <img class="img-step" src="https://blobinsight.blob.core.windows.net/gobets/images/2.jpeg" alt="" />
        </tab-content>
        <tab-content>
            <img class="img-step" src="https://blobinsight.blob.core.windows.net/gobets/images/3.jpeg" alt="" />
        </tab-content>
        <tab-content>
            <img class="img-step" src="https://blobinsight.blob.core.windows.net/gobets/images/4.jpeg" alt="" />
        </tab-content>
        <tab-content>
            <img class="img-step" src="https://blobinsight.blob.core.windows.net/gobets/images/5.jpeg" alt="" />

            <b-form-checkbox id="checkbox-1"
                             v-model="status"
                             name="checkbox-dont-show-again"
                             style="margin-top: 10px"
                             value="false"
                             unchecked-value="true">
                Do not show this message again
            </b-form-checkbox>
        </tab-content>
    </form-wizard>
</template>

<script>
    import { FormWizard, TabContent } from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'

    export default {
        name: 'bar-carousel',
        components: {
            FormWizard,
            TabContent
        },
        data() {
            return {
                status: true
            }
        },
        methods: {
            onComplete: function () {
                localStorage.showKriptoCoinWizard = this.status;
                this.$bvModal.hide('modal-banner')
            }
        },
        created() {

        }
    }
</script>

<style>
    .wizard-nav-pills, .wizard-progress-with-circle {
        display: none !important;
    }

    .img-step {
       width: 100%;
    }

    .wizard-btn {
        border-color: #4C2F6E !important;
        background-color: #4C2F6E !important;
        min-width: 100px !important;
    }

        .wizard-btn:hover {
            border-color: #8e4c8c !important;
            background-color: #8e4c8c !important;
            color: #FFF;
        }

    .wizard-tab-container {
        text-align: center !important;
    }
</style>