import "./MenuBar.scss";
import apiService from "@/_helper/api-services.js";

export default {
    name: "MenuBar",
    data() {
        return {
            menus: Array(),
            acessoRemoto:false,
        };
    },
    components: {
    },
    props: {
        searchMenu: Boolean(),
        idUsuario: null
    },
    beforeMount() {
        this.acessoRemoto = this.$store.getters["autenticacao/isAcessoRemoto"];
        if (this.$props.searchMenu) {
            if (localStorage.getItem("menu"))
                this.menus = JSON.parse(localStorage.getItem("menu")).filter(u => !u.rotaPublica);
            else
                apiService.get("/general/getMenu").then(success => {
                    if (success.data) {
                        this.menus = success.data.filter(u => !u.rotaPublica);
                        localStorage.setItem("menu", JSON.stringify(success.data));
                    }
                    // else
                        // this.$snotify.error("An unexpected error has occurred");
                }, error => {
                    if (error.response && error.response.data && error.response.data.errors)
                        error.response.data.errors.forEach(e => {
                            this.$snotify.error(e);
                        });
                });
        }

    }
}