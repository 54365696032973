import "./meusCursos.scss";
import http from "@/_helper/api-services.js";

export default {
  name: "meusCursos",
  data() {
    return {
      categoriaSelecionada: String(),
      items: [],
      fields: [
        { key: "nomeArquivo", label: this.$i18n.t("cursos.nome") },
        { key: "tipo", label: this.$i18n.t("cursos.tipo") },
        { key: "actions", label: "" },
      ],
      urlVideo: String(),
      nomeVideo: String(),
      tipo: String(),
      cursos: [],
    };
  },
  beforeMount() {
    this.$loading(true);
    this.buscarCurso();
  },
  methods: {
    buscarCurso() {
      this.$loading(true);
      http
        .get("/courses/")
        .then((response) => {
          if (response.status == 200) {
            this.cursos = response.data.filter(function(o) {
              return o.ativo;
            });
          }
          this.$loading(false);
        })
        .catch((error) => {
          this.$snotify.error(error.response.data);
        });

      this.$loading(false);
    },
    removeSpecialCharacters(text) {
      return text.replace(/ /g, "").replace(/[^\w\s]/gi, "");
    },
    getCursos() {
      this.$loading(true);
      http
          .get("/product/listFilesProductActive")
        .then(
          (success) => {
            if (success.data) {
              this.items = success.data.reduce((r, a) => {
                r[a.nomeCategoria] = [...(r[a.nomeCategoria] || []), a];
                return r;
              }, {});
            }
          },
          (error) => {
            if (error.response && error.response.data)
              this.$snotify.error(error.response.data.message);
          }
        )
        .finally(() => {
          this.$loading(false);
        });
    },
    openModal(nomeVideo, video, tipo) {
      this.nomeVideo = nomeVideo;
      this.urlVideo = video;
      this.tipo = tipo;
      this.$bvModal.show("modal-videos");
    },
  },
};
