import "./BotDinamico.scss";
import http from "@/_helper/api-services"
import VueTradingView from 'vue-trading-view';
import CryptoWatch from '@/components/CryptoWatch/CryptoWatch.vue';

export default {
    name: "BotDinamico",
    components: {
        VueTradingView,
        CryptoWatch
    },
    data() {
        return {
            formSearch:{
                mercado: 1,
                stake: 0,
                maximoPerda: 0,
                maximoGanho: 0,
                minimoPerda: 0,
                minimoGanho: 0,
                simulacao: true
            },
            listaMercado: [
                {text: 'Trade esportivo', value: 1},
                {text: 'Forex', value: 2},
                {text: 'Crypto Arbitragem', value: 2}
            ],
            resultado: ''
        };
    },
    beforeMount(){
    },
    mounted() {
        
    },
    methods: {
        // simulaBot(){
        //     http.post("/RendimentoDinamico/simularRend", this.formSearch).then(
        //     (response) => {
        //         this.resultado = response.data
        //     },
        //     error => {
        //         if (error.response && error.response.data)
        //         error.response.data.errors.forEach(e => {
        //                 this.$snotify.error(error.response.data.message);
        //             });
        //         else
        //             this.$snotify.error("An unexpected error has occurred");
        //         this.$loading(false);
        //     });
        // },
        // executaBot(){
        //     this.formSearch.simulacao = false;

        //     http.post("/RendimentoDinamico/modificarRend", this.formSearch).then((response) => {
        //         this.resultado = response.data
        //     },
        //     (error) => {
        //         if (error.response && error.response.data && error.response.data.errors)
        //             error.response.data.errors.forEach(e => {
        //                 this.$snotify.error(e);
        //             });
        //         else
        //             this.$snotify.error("An unexpected error has occurred");
        //         this.$loading(false);
        //     });
        // },
        openModalSimulacao(){
            this.formSearch.simulacao = true;
            this.$bvModal.show('modal-simulacao');
        },
        openModalExecucao(){
            this.formSearch.simulacao = false;
            this.$bvModal.show('modal-execucao');
        }
    }
}