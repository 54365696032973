import http from "@/_helper/api-services.js";
import DatePicker from 'vue2-datepicker';
import "./TicketsAdmin.scss";

export default {

    name: "TicketsAdmin",
    data() {
        return {
            nomeLogin: '',
            fields: [
                { key: "status", label: this.$t('contato.tipo'), sortable: true },
                { key: "tipo", label: this.$t('contato.motivo'), sortable: true },
                { key: "usuario", label: this.$t('contato.usuario'), sortable: true },
                { key: "login", label: this.$t('contato.login'), sortable: true },
                { key: "dataSolicitacao", label: this.$t('contato.dataSolicitacao'), sortable: true, class: 'tb-data-quebra' },
                { key: "dataAtualizacao", label: this.$t('contato.dataAtualizacao'), sortable: true, class: 'tb-data-quebra' },
                { key: "opcoes", label: this.$t('contato.opcoes'), class: 'tb-option' }
            ],
            history: [],
            totalPaginas: 1,
            pageOptions: [15, 25, 50],
            opcoesCategoria: [],
            showIconsToChangePage: false,
            opcoesStatus: [],
            listArquivos: [],
            sortBy: 'status',
            sortDesc: false,
            request: {
                login: null,
                categoria: null,
                status: null,
                dataFim: new Date(),
                dataInicio: this.dataMenos15(),
                itensPorPagina: 15,
                paginaAtual: 1,
            },
            options: [{ value: null, text: "Todos" }],
            imagemComprovante: String(),
            observacao: String(),
            acao: String(),

        };
    },
    components: {
        DatePicker
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPaginas <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPaginas;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPaginas - this.request.paginaAtual;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPaginas - 2; i <= this.totalPaginas; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPaginas - 1) return [1, 2, 3];
                else return [this.request.paginaAtual - 1, this.request.paginaAtual, this.request.paginaAtual + 1];
            }
        }
    },
    beforeMount() {
        this.buscarSuportes();
        this.buscarTiposContato();
    },
    mounted() {
        //this.obterNomeLogado();
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        alterarPagina(pagina) {
            this.request.paginaAtual = pagina;
            this.buscarSuportes();
        },
        async buscarSuportes() {
            this.$loading(true);
            await http.post("/support/searchSupportsAdmin", this.request)
                .then(
                    success => {
                        this.history = [];
                        if (success && success.data) {
                            this.totalPaginas = success.data.totalPages;
                            this.history = success.data.suportes;
                        }
                        this.$loading(false);
                    },
                    error => {
                        this.$loading(false);
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                );
        },
        buscarTiposContato() {
            this.listArquivos = [];
            this.opcoesStatus.push({
                text: this.$i18n.t("StatusSuporte.Todos"),
                value: 0,
            })
            this.opcoesCategoria.push({
                text: this.$i18n.t("StatusSuporte.Todos"),
                value: 0,
            })
            this.request.status = 0;
            this.request.categoria = 0;
            http.get('/support/getOptions').then((response) => {
                response.data.tipoList.forEach(item => {
                    this.opcoesCategoria.push({
                        text: this.$i18n.t("MotivoContato." + item.nome),
                        value: item.id,
                    })
                });

                response.data.statusList.forEach(item => {
                    this.opcoesStatus.push({
                        text: this.$i18n.t("StatusSuporte." + item.nome),
                        value: item.id,
                    })
                });
            });
        },
        async cancelarTicket(idSuporte) {
            this.$loading(true);
            await http.post(`/support/cancelSupport/` + idSuporte)
                .then(
                    success => {
                        this.$snotify.success("This ticket has been canceled");
                        this.buscarSuportes();
                    },
                    error => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                );
        },
        finalizarTicket(idSuporte) {
            this.$loading(true);
            http.post(`/support/FinalizeSupport/` + idSuporte)
                .then(
                    success => {
                        this.$snotify.success("This ticket has been closed");
                        this.buscarSuportes();
                    },
                    error => {
                        if (error.response && error.response.data)
                            this.$snotify.error(error.response.data.message);
                    }
                );
        },
        detalhesSuporte(code) {
            this.$router.push('/admin/support/contact?code=' + code);
        },
        dataMenos15() {
            var result = new Date();
            result.setDate(result.getDate() + -15);
            return result;
        },
        dataAtual() {
            var result = new Date();
            result.setDate(result.getDate());
            return result;
        },
        // abrirModal(url, observacao) {
        //     this.$bvModal.show("modal-comprovante");
        //     this.imagemComprovante = url;
        //     this.observacao = observacao;
        // },
        formatDate(date, showTime) {
            if (date) {
                // if (!showTime) return new Date(date).toLocaleDateString();
                
                    return `${new Date(date).toLocaleDateString()} ${new Date(
                    date
                ).toLocaleTimeString()}`;
            }

        },
    },


};