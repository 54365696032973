import "./formAlterarSenha.scss";
import usuarioService from "@/_helper/usuario-service.js";

export default {
    name: "formAlterarSenha",
    data() {
        return {
            password: String(),
            passwordRepeat: String(),
            passwordsNotMatch: false,
            tokenInvalid: true
        };
    },
    props: ['token'],
    mounted() {
        usuarioService.validateToken(this.token)
            .then(success => {
                this.tokenInvalid = false;
            },
                error => {
                    if (error.response && typeof error.response.data == "object")
                        this.$snotify.error(error.response.data.message);
                });
    },
    watch: {
        passwordRepeat() {
            if (this.password != this.passwordRepeat)
                this.passwordsNotMatch = true;
            else
                this.passwordsNotMatch = false;
        }
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault();
            if (this.passwordsNotMatch)
                return false;

            this.$loading(true);
            usuarioService.updatePassword({ token: this.token, senha: this.password, senhaConfirmada: this.passwordRepeat })
                .then(success => {
                    this.$snotify.success(success.data.message);
                    this.$router.push("/login");
                }, error => {
                    if (error.response && typeof error.response.data == "object")
                        this.$snotify.error(error.response.data.message);
                }).finally(() => { this.$loading(false); });
        }
    }
}